/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/04/24 18:12
 * @update 2019/04/24 18:12
 */

import * as CryptoJS from "crypto-js";
import { DataSource } from "devextreme/data/data_source/data_source";
import { loadMessages, locale } from 'devextreme/localization';
import _ from "lodash";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { browserHistory } from "react-router";
import * as Helpers from '../components/common/utils/helpers';
const env = require('./environment');
const crypto = require('crypto');

class Config {

    //////////////////////////
    //Use localStorage..
    static getKeyEnv = (key = "") => {
        const prefixLang = Config?.env?.prefixLang || "";
        let keyEnv = Config.env.keyEnv && Config.env.keyEnv !== "NO" ? Config.env.keyEnv : "";
        keyEnv = keyEnv.trim() + prefixLang.trim();
        return keyEnv.trim() + key;
    };
    static setLocalStorage = (key, value) => {
        if (!key) return false;
        key = Config?.getKeyEnv(key);
        try {
            localStorage.setItem(key, value);
        } catch (e) {
            return false;
        }
    };
    static getLocalStorage = (key, isJSONParse = false, isNewKey = false) => {
        if (!key) return null;
        const newKey = Config?.getKeyEnv(key);
        try {
            let item = localStorage.getItem(newKey);
            if (!isNewKey && !item) {
                item = localStorage.getItem(key);
            }
            if (this.isJson(item) && isJSONParse) item = JSON.parse(item);
            return item;
        } catch (e) {
            return null;
        }
    };
    static removeLocalStorage = (keys) => {
        if (!keys || keys.length <= 0) return false;
        const keyEnv = Config?.getKeyEnv();
        try {
            if (Array.isArray(keys)) {
                for (let key of keys) {
                    const k = keyEnv ? keyEnv.trim() + key : key;
                    localStorage.removeItem(k);
                }
            } else {
                keys = keyEnv ? keyEnv.trim() + keys : keys;
                localStorage.removeItem(keys);
            }
            return true;
        } catch (e) {
            return false;
        }
    };
    //Use localStorage..
    ////////////////////////////////////////
    static env = env;
    static token = {};
    static tokenCDN = {};
    static isMobile = isMobile;
    static color = {};
    static themes = [];
    static profile = {};
    static setting = [];
    static divisions = [];
    static cdn = {
        URL: env.cdn,
        secret: env.secretCDN,
        token: {}
    };
    static products = [];
    static getToken = false;
    static filters = null;
    static formInfo = null;
    static formID = "";
    static popup = null;
    static debugger = null;
    static notify = null;
    static notifyError = null;
    static viewType = 0;
    static language = null;
    static perPage = 20;
    static database = '';
    static helpers = Helpers;
    static controller = null;
    static popupTransition = Helpers.popupTransitions.Slide;
    static permission = {
        'USER': 1,
        'ADMIN': 2,
        'SUPPORTER': 3,
        'BOT': 4
    };
    static menuType = Number(Config.getLocalStorage("MENUTYPEBEM")); //default MSS...
    static localization = null;
    static listUsers = [];
    static URlDef = require('../assets/images/icon-user-default.png');
    static encryptKey = "BEM@012345678#keyEncrypt";
    static coreTheme = null;
    static deployer = process?.env?.REACT_APP_PASSWORD_DEPLOYER || '@12345@';

    static getHREmployeeID = () => {
        let result = '';
        if (this.profile.HREmployeeID) {
            result = this.profile.HREmployeeID;
        }
        return result
    };

    static getDivisionID = () => {
        let result = '';
        if (this.profile.DivisionID) {
            result = this.profile.DivisionID;
        }
        return result;
    };

    static getHRTransMonth = () => {
        let result = '';
        if (this.profile.TranMonth) {
            result = this.profile.TranMonth;
        }
        return result;
    };

    static getHRTransYear = () => {
        let result = '';
        if (this.profile.TranYear) {
            result = this.profile.TranYear;
        }
        return result;
    };

    static getCreatorHR = () => {
        let result = '';
        if (this.profile.CreatorHR) {
            result = this.profile.CreatorHR;
        }
        return result
    };

    static getDatabaseName = () => {
        return Config.getSetting('DB_BEM_NAME') ? Config.getSetting('DB_BEM_NAME') : "";
    };

    /**
     * Get height of grid with 100% - [heigth of header] - [height of toolbar] - [height of paging]
     * @param parentHeight: number
     * @returns {number}
     */
    static getHeightGrid = (parentHeight) => {
        const _toolbar = document.getElementById("action-toolbar");
        const _header = document.querySelector(".header-container");
        const _pHeight = parentHeight || window.innerHeight;
        const headerHeight = _header ? _header.offsetHeight : 50;
        const toolbarHeight = _toolbar ? _toolbar.offsetHeight : 50;
        return _pHeight - (headerHeight + toolbarHeight + 15); //15: padding bottom of content-container
    };

    static getLocale = () => {
        let result = "vi";
        if (Config.getLocalStorage('langBEM')) {
            result = Config.getLocalStorage('langBEM');
        }
        return result;
    };

    /**
     * Get system setting
     * Example:  getSetting('DHR_NAME') => 'DRD02V41'
     * @param name
     * @param dataSetting
     * @param isEncrypt
     * @param returnFull
     * @returns {any|Array|{rows}|null|*|number}
     */
    static getSetting = (name, dataSetting, isEncrypt = false, returnFull = false) => {
        dataSetting = dataSetting ? dataSetting : Config.setting;
        dataSetting = isEncrypt ? JSON.parse(this.decryptCrypto(dataSetting)) : dataSetting;
        if (dataSetting) {
            const settings = dataSetting && dataSetting.rows ? dataSetting.rows : dataSetting;
            if (settings) {
                if (!name) {
                    if (returnFull) {
                        return dataSetting;
                    } else {
                        return settings;
                    }
                } else {
                    let setting = settings.find(s => s.name === name);

                    if (setting && setting.name) {
                        switch (setting.type) {
                            case "NUMBER":
                                return parseFloat(setting.value);
                            case "JSON":
                                return JSON.parse(setting.value);
                            default:
                                return setting.value;
                        }
                    }
                }
            } else {
                console.log("decrypt setting error");
            }
        }

        return null;
    };

    static logout = () => {
        Config.controller.deleteDevice(() => {
            Config.removeLocalStorage('TOKENBEM');
            Config.removeLocalStorage('PROFILEBEM');
            Config.removeLocalStorage('SETTINGBEM');
            Config.removeLocalStorage('FORMINFODHR');
            Config.removeLocalStorage('REPORTS_VIEW');
            // Config.removeLocalStorage('langBEM');
            // Config.removeLocalStorage('breadcrumbHR');
            Config.removeLocalStorage('tokenCDN');

            window.location.href = Config.getRootPath();
        });
    };

    /**
     * Replace special symbol,using for control as textbox Code ,ID .v.v.
     * Ex: replaceSymbol('*Khoái') => 'Khoai'
     * @param value
     * @returns {string}
     */
    static replaceSymbol = (value) => {
        // let str = str.replace(/[|?~=",{}[\];^%']/gi, '');
        let str = value.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        str = str.replace(/\s/g, '');
        str = str.replace(/[^0-9a-z_\-#*/(\\)]/gi, '');

        return str.toUpperCase();
    };

    /**
     * Convert date by special format
     * @param value
     * @param defaultValue
     * @param format
     * @param isUTC
     * @param inputFormat
     * @returns {string}
     */

    static convertDate = (value, defaultValue, format, isUTC = true, inputFormat) => {
        if(!value){
            return defaultValue ?? null;
        }
        else if (!moment(value, inputFormat).isValid()) {
            Config.popup.show("ERROR", `Invalid date is Format: ${value}`)
            return defaultValue ?? null;
        }
        if (isUTC) {
            if (format) return moment.utc(value, inputFormat).format(format);
            else return moment(value, inputFormat).toISOString();
        } else {
            return moment(value, inputFormat).format(format);
        }
    };

    /**
     * Using translate resource of project (caption,title,description,label,text.v.v.)
     * Example : lang(Dang_nhap) => vi:Đăng nhập  || en :Log In
     * @param text
     * @param mode
     * @returns {*}
     */
    static getKeyLocalize = (text) => {
        if (!Config.localization || !text) return "";
        const prefix = this.env.prefixLang || "BEM_";
        if (text && text.includes(prefix)) text = text.replace(prefix, '');
        return Object.keys(Config.localization).find(l => {
            if (l && l.includes(prefix)) l = l.replace(prefix, '');
            return l.toLowerCase() === text.toLowerCase();
        });
    };
    static lang = (text, mode) => {
        let str;
        if (Config.localization !== null) {
            let keyTmp = text;
            if (text && text.match(/%(.*?)%/g)) {
                keyTmp = text.replace(/%(.*?)%/g, "%p"); //define key param, require is %p%.
            }
            let key = this.getKeyLocalize(keyTmp);
            if(mode){
                const lc =Config.getLocalStorage('LOCALIZE');
                const lang = JSON.parse(lc);
                str = lang[mode][key];

            } else {
                // str = Config.localization[text] ? Config.localization[text] : Config.localization[text1];
                str = Config.localization[key];
            }
            if (typeof str !== "undefined") {
                const match = text.match(/%(.*?)%/i);
                const match2 = text.match(/%(.*?)%/g);
                if (match) {
                    match2.forEach(m => {
                        m = m.slice(1, -1);
                        str = str.replace("%p", m);
                    });
                }
                return str;
            }
        }
        return text;
    };

    /**
     * Set language devextreme component
     * @returns {void}
     */
    static setLangDevextreme() {
        const lang = Config.getLocalStorage('langBEM');
        let dataLang = Config.getLocalStorage('LOADDEVEXTREME');
        if (dataLang && this.isJson(dataLang)) {
            dataLang = JSON.parse(dataLang);
            loadMessages(dataLang);
            locale(lang);
        }
        if (lang === 'vi' && window.dxMessage) {
            Config.setLocalStorage('LOADDEVEXTREME', JSON.stringify(window.dxMessage));
            loadMessages(window.dxMessage);
            locale('vi');
        }
        else if (lang === 'en') {
            locale('en');
        }
    }

    static randomSRC(width, height) {
        const widthImage = width ? width : 320;
        const heightImage = height ? height : 320;
        return "https://loremflickr.com/" + widthImage + "/" + heightImage;
    };

    static getRootPath() {
        return (process && process.env && process.env.REACT_APP_ROOT) ? process.env.REACT_APP_ROOT : '/';
    }

    /**
     * Return url with CDN.
     * @param args (accept boolean and string - boolean or url path)
     * @returns {string}
     */
    static getCDNPath(args) {
        //case 1: get CDN path with slash
        if (typeof args === "boolean") return this.cdn.URL + (args ? "/" : "");

        //case 2: get URL with CDN
        if (args && typeof args === "string") {
            if (args.includes("://")) return args;
            if (args.startsWith("/")) args = args.substring(1);
            return `${this.cdn.URL}/${args}`;
        }
        return `${this.cdn.URL}/`;
    }

    static isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    static storeDataSoureDevExtreme(dataSource, limit) {
        if (dataSource && dataSource.rows) dataSource = dataSource.rows;
        if (!dataSource || dataSource.length <= 0) return [];
        return new DataSource({
            store: dataSource,
            paginate: true,
            pageSize: limit ? limit : 10
        });
    }

    /**
     * Save state to location with browserHistory.
     * @param component
     * @param param = {}
     */
    static setStoreState(component, param) {
        const route = component.props.route.path;

        browserHistory.push({
            pathname: Config.getRootPath() + route,
            state: param
        });
    }

    /**
     * Get state from location and set state of component with browserHistory.
     * @param component
     * @param cb callback
     * @returns null
     */
    static getStoreState(component, cb) {
        const route = component.props.route.path;
        const data = component.props.location && component.props.location.state ? component.props.location.state : null;
        const state = { ...data, storeFilter: true };

        if (data) {
            component.setState(state, () => {
                cb && cb();
            });
            browserHistory.push({
                pathname: Config.getRootPath() + route,
            });
        }
        return data;
    }

    static isEmpty(value, escapeZero = false) {
        switch (typeof value) {
            case "object": {
                if (Array.isArray(value))
                    return value.length <= 0;
                else
                    return (value && Object.keys(value).length <= 0) || !value;
            }
            case "string": {
                return !value;
            }
            case "number": {
                if (!escapeZero)
                    return !value;
                else
                    return value === 0 ? false : !value;
            }
            default: {
                return !value;
            }
        }
    }

    /**
     * Get user picture url.
     * @param UserPictureUrl
     */

    static getUserPicture(UserPictureUrl, defaultValue) {
        if (!UserPictureUrl) return defaultValue ? defaultValue : null;

        if (UserPictureUrl.indexOf('http') < 0) {
            return this.getCDNPath() + UserPictureUrl;
        }

        return UserPictureUrl;
    }

    static getUser = (params) => {
        // paramsDef = {
        // EmployeeID: "#1234",
        // EmployeeName: "Cristiano Ronaldo",
        // UserPictureURL: null,
        // };
        let item = null;
        let list =  this.listUsers || [];
        if (list && list.length > 0) {
            list.forEach(i => {
                let flag = true;
                Object.keys(params).forEach((key) => {
                    if (i[key] !== params[key]) flag = false;
                });
                if (flag) {
                    item = flag ? i : item;
                    return true;
                }

            });
        }
        item = item || {};
        if(!_.get(item,"UserPictureURL", false)) return item;
        item.UserPictureURL = this.getUserPicture(item.UserPictureURL);
        return item;
    };

    static getListUser = (arrayID) => {
        let arr = [];
        let list =  this.listUsers || [];
        if (list && list.length > 0) {
            list.forEach(i => {
                if (arrayID.includes(i.EmployeeID)) {
                    i.UserPictureURL = this.getUserPicture(i.UserPictureURL);
                    arr.push(i);
                }
            });
        }
        return arr;

    };

    static decryptCrypto = (text) => {
        try {
            const key = crypto.createDecipher('aes-256-cbc', this.env.secret + '@^1#');
            const str = key.update(text, 'hex', 'utf8');

            return str + key.final('utf8');
        } catch (e) {
            console.log("decrypt error", e);
            return false;
        }
    };

    static encryptCrypto = (text) => {
        try {
            const key = crypto.createCipher('aes-256-cbc', this.env.secret + '@^1#');
            const str = key.update(text, 'utf8', 'hex');

            return str + key.final('hex');
        } catch (e) {
            console.log("encrypt error");
            return false;
        }
    };

    //Encryptdata...
    static encryptData = (data, encryptKey) => {
        if (!data || typeof data !== "string") return false;
        try {
            encryptKey = encryptKey ? encryptKey : this.encryptKey;
            return CryptoJS.AES.encrypt(data, encryptKey).toString();
        } catch (e) {
            console.log("encrypt error", e);
            return false;
        }
    };

    //Decrypt data...
    static decryptData = (data, encryptKey, stringDecode = CryptoJS.enc.Utf8) => {
        if (!data) return null;
        try {
            encryptKey = encryptKey ? encryptKey : this.encryptKey;
            return CryptoJS.AES.decrypt(data, encryptKey).toString(stringDecode);
        } catch (e) {
            console.log("decrypt error", e);
            return null;
        }
    };

    static sub_text = (str, count_char, str_more = "...") => {
        if (!str) return str;
        if (!Number.isInteger(count_char) || count_char < 1) return str;
        return str.substring(0, count_char) + (str.length > count_char ? str_more : "");
    }

    static getMenuInfo = (isReload = true, cb) => {
        //Get all menus
        // this.controller.getListMenu({ Language: Config.language }, (errListMenu, dataListMenu) => {
        //     if (errListMenu) {
        //         Config.popup.show('INFO', errListMenu.message);
        //         return false;
        //     }
        //
            this.controller.getVerticalMenu({ Language: Config.language }, (errVerticalMenu, dataVerticalMenu) => {
                if (errVerticalMenu) {
                    Config.popup.show('INFO', errVerticalMenu.message);
                    return false;
                }

                if (dataVerticalMenu) {
                    const menus = {
                        listMenu: [],
                        verticalMenu: dataVerticalMenu
                    };

                    Config.setLocalStorage('MENUBEM', JSON.stringify(menus));
                    cb && cb();
                    if (isReload) window.location.reload();
                }
            });
        //
        // });
        //End get all menus
    };

    static checkRedirect = (pathname) => {
        this.unlisten = browserHistory.listenBefore((nextLocation) => {
            const currentLocation = browserHistory.getCurrentLocation();
            if (pathname === currentLocation.pathname) {
                let rs = window.confirm(Config.lang("Du_lieu_chua_duoc_luu_ban_co_muon_tiep_tuc_khong"));
                if (!rs) {
                    return false;
                }
            }
        });
    }
    static unCheckRedirect = () => {
        window.onbeforeunload = null;
        if(this.unlisten){
            this.unlisten();
        }
    }

    static onEnterKeyDownNavigation = (selectedRange, dataGrid, allColumn, cb) => {
        const { startColumnIndex, startRowIndex } = selectedRange;
        const currentColumn = (allColumn)[startColumnIndex];
        if (currentColumn.allowEditing) {
            setTimeout(() => dataGrid.instance.editCell(startRowIndex + 1, startColumnIndex), 100);
        }
        let rowIndex = startRowIndex + 1;
        if (cb) cb(rowIndex)
    }

    static onTabKeyDownNavigation = async (selectedRange, dataGrid, allColumn, cb) => {
        const { startColumnIndex } = selectedRange;
        const currentColumn = (allColumn)[startColumnIndex + 1];
        if (currentColumn.dataField === (allColumn)[startColumnIndex + 1]?.dataField) {
            let columnPosition = 0;
            if (!currentColumn.allowEditing) { //allowEdit = False chạy vòng lập kiểm tra các column tiếp theo nếu next col = true thì dừng vòng lập
                for (let i = startColumnIndex; i < allColumn.length; i++) {
                    const nextColumn = (allColumn)[i + 1];
                    if (!_.isUndefined(nextColumn) && !nextColumn.allowEditing) { //false
                        columnPosition++;
                    } else {
                        columnPosition += 1;
                        break;
                    }
                }
            } else { //allowEdit = True
                columnPosition += 1;
            }
            if (cb) cb(columnPosition)
        }
    }

    static onEnterAndTabKeyNavigation = (e, selectedRange = {}, dataGrid, cbEnterKey, cbTabKey) => {
        const allColumn = e.component.getVisibleColumns();
        if (e && e.event && _.isEmpty(selectedRange) && _.isEmpty(allColumn)) return;
        if (e.event.keyCode === 13 || e.event.keyCode === 9) {
            //Kiểm tra điều kiện phím Enter = 13 phím Tab = 9 và chặn phím shift
            const { keyCode, shiftKey } = e.event;
            const allDataFieldColumn = allColumn.filter(item => item.dataField);
            const firstEditingIndex = allDataFieldColumn.findIndex(item => item.allowEditing === true);
            const { startColumnIndex, startRowIndex } = selectedRange;
            const dataSource = dataGrid.instance.option("dataSource");
            let currentColumn = (allColumn)[startColumnIndex];
            const backToFirstRow = startRowIndex + 1 === dataSource.length ? 0 : startRowIndex + 1;
            const backToLastRow = startRowIndex === 0 ? dataSource.length - 1 : startRowIndex - 1;
            if (!_.isUndefined(currentColumn)) {
                if (keyCode === 13) { //Enter key
                    const rowIndex = !shiftKey ? backToFirstRow : backToLastRow; // Kiểm tra Shift + Enter
                    if (currentColumn.allowEditing) setTimeout(() => dataGrid.instance.editCell(rowIndex, startColumnIndex), 100);
                    if (cbEnterKey) cbEnterKey(rowIndex)
                } else if (keyCode === 9) { //Tab key
                    const nextColumnIndex = startColumnIndex + 1
                    const prevColumnIndex = startColumnIndex - 1;
                    let startColumnNowIndex = !shiftKey ? nextColumnIndex : prevColumnIndex;
                    currentColumn = (allColumn)[startColumnNowIndex];
                    let rowIndexTab = startRowIndex;
                    if (!_.isUndefined(currentColumn)) {
                        let columnPosition = 0;
                        if (!currentColumn.allowEditing && !_.isUndefined(currentColumn.dataField)) {
                            // Trường hợp Next cột tiếp theo nếu như allowEditing là false sẽ chạy vòng lập
                            if (!shiftKey) { //Tab only
                                for (let i = startColumnIndex; i < allColumn.length; i++) {
                                    const nextColumn = (allColumn)[i + 1];
                                    if (!_.isUndefined(nextColumn) && !nextColumn.allowEditing) { //false
                                        columnPosition++;
                                    } else { //allowEditing = true dừng vòng lập
                                        columnPosition += 1;
                                        break;
                                    }
                                }
                            } else { // Shift + Tab
                                if (firstEditingIndex !== startColumnIndex) {
                                    //Trường hợp Tab (trở lại) Back nếu như allowEditing là false sẽ chạy vòng lập
                                    for (let i = startColumnIndex; i >= 0; i--) {
                                        const previousColumn = (allColumn)[i - 1];
                                        if (!_.isUndefined(previousColumn) && !previousColumn.allowEditing) { //false
                                            columnPosition--;
                                        } else { //allowEditing = true dừng vòng lập
                                            columnPosition -= 1;
                                            break;
                                        }
                                    }
                                }
                            }
                        } else { //allowEdit = True Cả 2 trường hợp Back Hoặc Next
                            columnPosition = !shiftKey ? columnPosition + 1 : columnPosition - 1;
                        }
                        const columnPositionCal = (shiftKey && startColumnIndex === firstEditingIndex) ? Math.abs(columnPosition) : columnPosition;
                        columnPosition = startColumnIndex + columnPositionCal;
                        if (allDataFieldColumn.length === columnPosition) {
                            // Trường hợp Next cột cuối cùng sẽ trả lại cột đầu tiên (0) của dòng tiếp theo
                            //nếu như vị trí đó allowEditing là false sẽ chạy vòng lập
                            columnPosition = 0;
                            currentColumn = (allColumn)[columnPosition];
                            if (!currentColumn.allowEditing) { //allowEdit = False chạy vòng lập kiểm tra các column tiếp theo nếu next col = true thì dừng vòng lập
                                for (let i = columnPosition; i < allColumn.length; i++) {
                                    const nextColumn = (allColumn)[i + 1];
                                    if (!_.isUndefined(nextColumn) && !nextColumn.allowEditing) { //false
                                        columnPosition++;
                                    } else { //allowEditing = true dừng vòng lập
                                        columnPosition += 1;
                                        break;
                                    }
                                }
                            }
                            rowIndexTab = backToFirstRow;
                            if (cbEnterKey) cbEnterKey(rowIndexTab)
                        }
                        if (shiftKey && columnPosition === startColumnIndex) {
                            //Trường hợp Tab hết cột back sẽ chạy vòng lập tính từ cột cuối cùng của dòng trước
                            columnPosition = 0;
                            for (let i = allDataFieldColumn.length; i >= 0; i--) {
                                const previousColumn = (allColumn)[i - 1];
                                if (!_.isUndefined(previousColumn) && !previousColumn.allowEditing) { //false
                                    columnPosition--;
                                } else { //allowEditing = true dừng vòng lập
                                    columnPosition -= 1;
                                    break;
                                }
                            }
                            columnPosition = allDataFieldColumn.length + columnPosition;
                            rowIndexTab = backToLastRow;
                            if (cbEnterKey) cbEnterKey(rowIndexTab)
                        }
                        setTimeout(() => dataGrid.instance.editCell(rowIndexTab, columnPosition), 100);
                        if (cbTabKey) cbTabKey(columnPosition)
                    }
                }
            }
        }
    }

    static isValidDateTime = (dateValue) => {
        let result = false;
        if (_.isString(dateValue) && !_.isEmpty(dateValue)) {
            let regexDate = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
            //regexDate Bao gồm các ngày như sau https://regexr.com/39tr1
            let utcRegex = /^[0-9]{4}-((0[1-9]|1[0-2])){1}-(0[1-9]|1[0-9])T(23:59:60|(([01]([0-9])|(2[0-3])){1}:?([0-5][0-9])){1}(:?([0-5][0-9])(.([0-9]+))?))?(Z|(\+|-)([01]([0-9])|(2[0-4])){1}((:)?([0-5][0-9]){1})?){1}$/;
            //utcRegex Bao gồm các ngày như sau https://www.regextester.com/95191
            if (regexDate.test(dateValue)) {
                result = regexDate.test(dateValue.trim());
            }
            if (utcRegex.test(dateValue)) {
                result = utcRegex.test(dateValue.trim());
            }
            if (!result && (dateValue.split("/").length === 3 || dateValue.split("-").length === 3)) {
                //Các trường hợp còn lại
                const value = dateValue.length > 10 ? dateValue.slice(0, 10).trim() : dateValue;
                switch (true) {
                    case moment(value, 'YYYY-MM-DD', true).isValid():
                    case moment(value, 'YYYY-DD-MM', true).isValid():
                    case moment(value, 'YYYY/DD/MM', true).isValid():
                    case moment(value, 'YYYY/MM/DD', true).isValid():
                    case moment(value, 'MM/DD/YYYY', true).isValid():
                    case moment(value, 'MM-DD-YYYY', true).isValid():
                    case moment(value, moment.ISO_8601, true).isValid():

                        {
                            result = true;
                            break;
                        }
                    default:
                        break;
                }
            }
        }
        return result;
    }

    static parseAccentedTextToNormal = (text = "") => {
        if (!_.isString(text) || _.isEmpty(text)) return
        const result =  _.deburr(text.normalize("NFD").replace(/\p{Diacritic}/gu, ""));
        return result;
    };

    static setItemPerPage = (e, itemPerPage = 10, cb)  => {
        if (!e || !e.component || !itemPerPage) return false;
        const dataSource = e.component.getDataSource();
        const _itemPerPage = e.gridRef ? e.gridRef.itemPerPage() : itemPerPage;
        if (_itemPerPage && itemPerPage !== _itemPerPage) {
            dataSource.pageSize(_itemPerPage);
        }
        const items = dataSource.items();
        const totalCount = dataSource.totalCount();
        const pageIndex = dataSource.pageIndex();
        const pageSize = dataSource.pageSize();
        let countItems = 0; //counter

        if (items && items.length > 0) {
            items.forEach((val) => {
                countItems += val.items !== null ? val.items.length : val.collapsedItems.length;
            });
        }
        const _currentTotal = (itemPerPage * pageIndex) + countItems;
        let _pageSize = _itemPerPage;
        if (countItems < itemPerPage && _currentTotal < totalCount) {
            _pageSize = pageSize + (pageSize - countItems + 1);
            dataSource.pageSize(_pageSize);
            if (e.gridRef) e.gridRef.itemPerPage(_pageSize);
            dataSource.reload(); //reload dataSource
        }
        cb && cb({pageIndex: pageIndex, pageSize: _pageSize, totalCount: totalCount, itemPerPage: countItems});
        return _pageSize;
    };

    static uniqueKeyObjOfArray = (arrayObj = [], key = "") => {
        if (_.isArray(arrayObj) && !_.isEmpty(arrayObj) && key) {
            arrayObj = Object.values(arrayObj.reduce((acc, cur) => Object.assign(acc, { [cur[key]]: cur }), {}));
            return arrayObj;
        }
    };

    static getInfoAvatar = (data, keys = []) => {
        let obj = {};
        const captions = {
            DepartmentName: Config.lang("Phong_ban"),
            ProjectName: Config.lang("Du_an"),
            DutyName: Config.lang("Chuc_vu"),
            DateJoined: Config.lang("Ngay_vao_lam")
        };
        const isAll = keys.length <= 0;
        obj[Config.lang("Nhan_vien")] = data.EmployeeID + " - " + data.EmployeeName;
        for (let cap of Object.keys(captions)) {
            if ((isAll || keys.includes(cap)) && data[cap]) {
                const vl = cap !== "DateJoined" ? data[cap] : Config.convertDate(data[cap], "", "DD/MM/YYYY");
                obj[captions[cap]] = vl;
            }
        }
        return obj;
    };

    /**
     * Convert from #ffffff to rgba()
     * @param hex color - #ffffff
     * @param {number} alpha - 0.5
     * @returns {string} color - rgba()
     */
    static hexToRGBA = (hex, alpha) => {
        const reg = /^#([0-9a-f]{3}){1,2}$/i;
        if (!reg.test(hex)) return "#000000";

        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, (m, r, g, b) => {
            return r + r + g + g + b + b;
        });

        const r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        return `rgba(${r},${g},${b},${alpha ?? 0})`;
    };
}

export default Config;
