/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/09 10:37
 * @update 2017/08/12 10:37
 */

import React from 'react';

import PropTypes        from "prop-types";
import {browserHistory} from "react-router";
import Config           from "../../config";
import {Animated}       from "react-animated-css";
import InlineSVG        from "react-inlinesvg";
import {Tooltip}        from "@material-ui/core";
import IconMenu         from "diginet-core-ui/icons/menu/v2";

const heightMenu = 42;
class SideNav extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            showMore: false,
            showMoreChild: false,
            expanded: false,
            renderSubMenu: {},
            statusSubMenu: true,

            showSubMenu: false,
            subMenu: {}
        };
        this.itemParent = null;
    }

    componentDidMount = () => {
        document.addEventListener('mousedown', this.clickHiddenSubSideBar);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.expand !== this.props.expand) {
            this.toggleNav(this.props.expand);
        }
    }

    componentWillUnmount = () => {
        document.removeEventListener('mousedown', this.clickHiddenSubSideBar);
    };

    clickHiddenSubSideBar = (e) => {
        const containerSubMenu = document.querySelector('.nav-sub-menu:not(.minimum)');
        if (containerSubMenu && !containerSubMenu.contains(e.target)) {
            this.setState({subMenu: {}});
        }
    };

    getNestedChildren = (arr, parent) => {
        let out = [];
        arr.forEach((key)=>{
            if(key.ParentMenuID === parent) {
                let children = this.getNestedChildren(arr, key.MenuID);
                if(children.length) {
                    key.children = children;
                }
                out.push(key);
            }
        });
        return out;
    };

    // getClassNameHome = () => {
    //     const pth = window.location.pathname.split('/');
    //     const mdl = pth && pth[1] ? pth[1] : '';
    //     let cls = 'menu-item-child-home';
    //     if (!mdl) {
    //         cls = 'menu-item-child-home active';
    //     }
    //     return cls;
    // };

    hideSubMenu = () => {
        if (this.itemParent) {
            this.setState({
                subMenu: {
                    [this.itemParent.MenuID]: false
                }
            });
        }
    };

    getClassName = (m, idx, child) => {
        // const pth = window.location.href.split('/');
        // const mdl = (pth && pth.length > 0) ? pth[pth.length - 1] : '';

        let cls = 'menu-item-child';
        if (child) cls += '-child';

        if(m.Disabled === true) {
            cls += ' disabled-icon';
        }

        if (m.actived) {
            cls += ' active';
        };

        return cls;
    };

    onMenuClick = (evt, item) => {
        const url = item.FormID;
        const {expanded} = this.state;
        this.itemParent = item;
        if ((!url || url === '' || url === '#')) {
            if (item.children && item.children.length > 0) {
                this.setState({
                    subMenu: {
                        [item.MenuID]: true
                    },
                });
                if (!expanded) {
                    this.toggleNav(true);
                }
            } else {
                this.setState({subMenu: {}});
            }
        } else {

            if (url === '/') {
                browserHistory.push(Config.getRootPath() + '/');
            } else {
                const parameter = item.Parameters ? JSON.parse(item.Parameters) : null;
                const params = {
                    menu: {MenuID: item.MenuID, FormID: item.FormID, FormIDPermission: item.FormIDPermission},
                    ...(parameter ? {...parameter} : {})
                };
                browserHistory.push({
                    pathname: Config.getRootPath() + url,
                    state: params
                });
                if (evt.target && evt.target.closest('.menu-item-child')) {
                    evt.target.closest('.menu-item-child').classList.add('active');
                }
            }

            if(document.body.offsetWidth <= 720){
                this.toggleNav();
            }
        }

    };

    toggleNav = (flag) => {
        const {onToogle} = this.props;
        const btnToogle = document.querySelector('#toogle-nav').children[0];
        if (typeof flag === "undefined") {
            if (this.refs['side-container'].clientWidth < 180) {
                // this.checkShowMore(this.dataMenuParse);
                this.refs['side-container'].classList.remove('minimum');
                if (btnToogle) {
                    btnToogle.classList.add('active');
                }
                if (onToogle) onToogle(true);
            } else {
                this.refs['side-container'].classList.add('minimum');
                if (btnToogle) {
                    btnToogle.classList.remove('active');
                }
                if (onToogle) onToogle(false);
            }
        } else {
            if (flag) {
                if (btnToogle) {
                    btnToogle.classList.add('active');
                }
                this.setState({expanded: true});
                if (onToogle) onToogle(true);
                this.refs['side-container'].classList.remove('minimum');
            } else {
                if (btnToogle) {
                    btnToogle.classList.remove('active');
                }
                this.setState({subMenu: {}, expanded: false});
                if (onToogle) onToogle(false);
                this.refs['side-container'].classList.add('minimum');
            }
        }
    };

    onLogout = () => {
        if(this.props.onLogout) this.props.onLogout();
    };

    _getResourceImage = (menu) => {
        if (!menu) return null;
        if (menu?.MenuIcon) return <InlineSVG src={Config.getCDNPath() + menu?.MenuIcon}/>;
        return <IconMenu type={'bem'} name={menu?.MenuID} width={22} height={22}/>;
    };

    getMenuRecursive = (data, parentMenu = null) => {
        const dataMenu = data ? data : Config.menu && Config.menu.verticalMenu ? Config.menu.verticalMenu : [];
        if (!parentMenu) {
            const listParents = dataMenu.filter(menu => Number(menu.MenuParentID) === 0);
            listParents.forEach((parent, idx) => {
                const {children, actived} = this.getMenuRecursive(dataMenu, parent);
                parent.actived = actived;
                parent.children = children;
            });
            return listParents;
        } else if (parentMenu && parentMenu.MenuID ) {
            const pth = window.location.href.split('/');
            const mdl = (pth && pth.length > 0) ? pth[pth.length - 1] : '';
            let _actived = false;

            let _children = dataMenu.filter((item) => {
                return item.MenuParentID === parentMenu.MenuID;
            });
            if (_children.length > 0) {
                _children.forEach((child, idx1) => {
                    let _act = false;
                    let {children, actived} = this.getMenuRecursive(dataMenu, child);
                    child.children = children;
                    if (children && children.length) {
                        _act = actived;
                    }
                    if ((mdl && mdl === child.FormID) || actived) {
                        _act = true;
                    }
                    child.actived = _act;
                    if (_act) {
                        _actived = true;
                    }
                });
            }
            return {children: _children, actived: _actived};
        }
        return [];

    };

    render() {
        const {className} = this.props;
        const {expanded, subMenu} = this.state;

        let dataMenu = Config.menu && Config.menu.verticalMenu ? Config.menu.verticalMenu : [];
        dataMenu = this.getMenuRecursive(dataMenu);

        return(
            <div className={"side-container minimum " + className} ref={'side-container'}>
                <div className="nav-scroll">
                    {dataMenu && dataMenu.map((o, idx) => {
                        const menuIcon = this._getResourceImage(o);
                        const cls = this.getClassName(o, idx);
                        let height = heightMenu;
                        return (
                            <React.Fragment key={idx}>
                                <ul className={'nav-item item-' + o.MenuID}
                                    style={{height: height, minHeight: height}}
                                    key={'ul-' + idx}
                                >
                                    <div className="display_row align-between align-center">
                                        <Animated style={{width: '100%'}}
                                            // key={o.FormID + idx}
                                            // ref={o.FormID}
                                                  animationIn="flipInX"
                                                  animationInDuration={1000}
                                                  isVisible={true}>
                                            <Tooltip title={o.MenuName || o.name}
                                                     classes={{tooltip: "no-margin"}}
                                                     placement={expanded ? "bottom" : "right"}
                                                     aria-label={o.MenuName || o.name}>
                                                <div className={cls}
                                                     key={o.MenuGroupID + idx}
                                                     // ref={o.FormID}
                                                     onClick={(e) => {
                                                         this.onMenuClick(e, o)
                                                     }}>
                                                    <div className={"nav-item-icon"} style={{width: 22, height: 22}}>
                                                        {menuIcon}
                                                    </div>
                                                    <div className={"nav-item-name"}>
                                                        {o.MenuName || o.name}
                                                    </div>
                                                    {o.children && o.children.length > 0 &&
                                                        <i className="fa fa-chevron-right" aria-hidden="true" />}
                                                </div>
                                            </Tooltip>
                                        </Animated>
                                    </div>
                                    <hr className="sidebar-divider"/>
                                </ul>
                                {o.children && o.children.length > 0 &&
                                <SubMenu component={this} parent={o} visible={subMenu[o.MenuID]} onHidden={this.hideSubMenu} />}
                            </React.Fragment>
                        )
                    })}
                </div>
                {/*<div className={'icon-nav'}>*/}
                {/*    <Image src={this.state.iconExpand} onClick={this.toggleNav}/>*/}
                {/*</div>*/}
            </div>
        )
    }
}

class SubMenu extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            hideMenu: {}
        };
        this.itemParent = null;
    }

    _getResourceImage = (fileName) => {
        let icon = require('../../assets/images/menu/default.svg');
        try {
            icon = require('../../assets/images/menu/' + fileName);
        } catch (e) {

        }
        return icon;
    };

    getClassName = (m, idx, child) => {

        let cls = 'menu-item-child';
        if (child) cls += '-child';

        if(m.Disabled === true) {
            cls += ' disabled-icon';
        }

        if (m.actived) {
            cls += ' active';
        }

        return cls;
    };

    hideSubMenu = () => {
        const {onHidden} = this.props;
        if (onHidden) onHidden();
    };

    _hideSubMenu = () => {
        if (this.itemParent) {
            this.setState({
                hideMenu: {
                    [this.itemParent.MenuID]: false
                }
            });
        }
    };

    onMenuClick = (evt, item) => {
        const url = item.FormID;
        this.itemParent = item;
        if (!url || url === '' || url === '#') {
            this.setState({
                hideMenu: {
                    [item.MenuID]: true
                }
            });
            return false;
        } else {

            if (url === '/') {
                browserHistory.push(Config.getRootPath() + '/');
            } else {
                const parameter = item.Parameters ? JSON.parse(item.Parameters) : null;
                const params = {
                    menu: {MenuID: item.MenuID, FormID: item.FormID, FormIDPermission: item.FormIDPermission},
                    ...(parameter ? {...parameter} : {})
                };
                browserHistory.push({
                    pathname: Config.getRootPath() + url,
                    state: params
                });
                if (evt.target) {
                    let currentEl = evt.target.closest('.nav-item');
                    while (currentEl) {
                        const item_child = currentEl.getElementsByClassName('menu-item-child');
                        if (item_child.length > 0) {
                            item_child[0].classList.add('active');
                        }
                        currentEl = currentEl.parentElement.closest('.nav-item');
                    }
                }
            }

            if(document.body.offsetWidth <= 720) {
                const {component} = this.props;
                if (component) {
                    setTimeout(() => {
                        component.toggleNav();
                    }, 300);
                }
            }
        }

    };

    render(){
        const {parent, visible, sub} = this.props;
        const {hideMenu} = this.state;
        let dataMenu = Config.menu && Config.menu.verticalMenu ? Config.menu.verticalMenu : [];
        dataMenu = dataMenu.filter((item) => {
            return item.MenuParentID === parent.MenuID;
        });
        if (dataMenu.length < 1) return null;

        let height = heightMenu;
        const parentFormID = parent.FormID ? parent.FormID : parent.MenuID;
        const menuIcon = parent.MenuIcon ? Config.getCDNPath() + parent.MenuIcon : this._getResourceImage(parentFormID + '.svg')
        return (
            /* eslint-disable */
            <div className={"nav-sub-menu sub-menu-" + parent.MenuID + " " + (visible ? "" : "minimum")}>
                <Animated style={{width: '100%'}}
                          className={"nav-sub-menu-item"}
                          animationIn="slideInRight"
                          animationOut="slideInLeft"
                          animationInDuration={300}
                          isVisible={true}>
                    <div className={"sub-menu-title"}
                         style={{height: height, minHeight: height}}>
                        {!sub && <div className={"nav-item-icon"} style={{width: 22, height: 22}}>
                            <InlineSVG src={menuIcon}/>
                        </div>}
                        <div className={"nav-item-name"}>
                            {parent.Refix || parent.MenuName || ""}
                        </div>
                        <a onClick={this.hideSubMenu}><InlineSVG src={require('../../assets/images/double_left.svg')}/></a>
                    </div>
                    {parent.children && parent.children.map((o, idx) => {
                        const cls = this.getClassName(o, idx);
                        return (
                            <React.Fragment key={idx}>
                                <ul className={'nav-item item-' + o.MenuID}
                                    style={{height: height, minHeight: height}}
                                    key={'ul-' + idx}
                                >
                                    <div className="display_row align-between align-center">
                                        <Animated style={{width: '100%'}}
                                            // key={o.FormID + idx}
                                            // ref={o.FormID}
                                                  animationIn="flipInX"
                                                  animationInDuration={1000}
                                                  isVisible={true}>
                                            <Tooltip title={o.MenuName || o.name}
                                                     classes={{tooltip: "no-margin"}}
                                                     placement={"bottom"}
                                                     aria-label={o.MenuName || o.name}>
                                                <div className={cls}
                                                     key={o.MenuID + idx}
                                                    // ref={o.FormID}
                                                     onClick={(e) => {
                                                         this.onMenuClick(e, o)
                                                     }}>
                                                    <div className={"nav-item-name"}>
                                                        {o.MenuName || o.name}
                                                    </div>
                                                    {o.children && o.children.length > 0 &&
                                                        <i className="fa fa-chevron-right" aria-hidden="true" />}
                                                </div>
                                            </Tooltip>
                                        </Animated>
                                    </div>
                                    <hr className="sidebar-divider"/>
                                </ul>
                                {o.children && o.children.length > 0 &&
                                <SubMenu parent={o} sub={true} visible={hideMenu[o.MenuID] && visible} onHidden={this._hideSubMenu}/>}
                            </React.Fragment>
                        );
                    })}
                </Animated>
            </div>
            /* eslint-enable */
        );
    }
}

SubMenu.propTypes = {
    parent: PropTypes.any,
    visible: PropTypes.bool,
    sub: PropTypes.bool,

    onHidden: PropTypes.func
};


export default SideNav;
