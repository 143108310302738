/**
 * @copyright 2022 @ DigiNet
 * @author ANHTAI
 * @create 24/06/2022
 */
import { LoadPanel } from 'devextreme-react/load-panel';
import {
    Button, DatePicker, Dropdown, Modal, ModalBody, ModalFooter, ModalHeader, TextInput, Col, Row
} from 'diginet-core-ui/components';
import _ from "lodash";
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Config from '../../../../config/index';
import * as generalActions from "../../../../redux/general/general_actions";
import * as WA3F2106Actions from "../../../../redux/modules/WA3/WA3F2106/WA3F2106_actions";
import MForm from "../../../common/forms/form-material/form";
import History from "../../../common/libs/history/history";
import TimePickerDropDown from "../../../custom/TimePickerDropDown";

const FormID = "WA3F2106";
const initialState = {
    BookingID: "",
    BookingItemID: "",
    BookingNo: "",
    ObjectID: "",
    ObjectName: "",
    BanquetCode: "",
    BanquetName: "",
    Location: "",
    HourFrom: "",
    HourTo: "",
    EventName: "",
    EventDate: null,
    NotesChange: "",
};

const WA3F2106 = (props) => {
    const dispatch = useDispatch();
    const { openModal, onOpenModalAction, dataInfo: dataInfoProp = {} } = props;
    const dataCboBanquetCode = useSelector(state => state?.general?.dataCboBanquetCode ?? []);

    //==========STATE==========
    const [mode, setMode] = useState(_.get(dataInfoProp, "mode", "add"));
    const [loading, _setLoading] = useState({
        formLoading: false,
        cboBanquetCodeLoading: false
    });
    const [error, setError] = useState({});
    const [dataMaster, _setDataMaster] = useState(initialState);

    const { formLoading, cboBanquetCodeLoading } = loading;

    //=======REF==========
    const saveSuccess = useRef(false);
    const refLoading = useRef({});
    const oldDataMaster = useRef({});
    const datamasterRef = useRef({});
    const dataInfo = useRef({
        BookingID: dataInfoProp?.BookingID || "",
        BookingItemID: dataInfoProp?.BookingItemID || ""
    });
    //=======Variable==========
    const disabledLoading = useMemo(() => {
        return !!Object.keys(loading).find(l => loading[l])
    }, [loading]);

    const disabled = useMemo(() => {
        return formLoading || mode === "view";
    }, [formLoading, mode]);

    useEffect(() => {
        loadForm();
        loadCboBanquetCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setLoading = (obj) => {
        refLoading.current = { ...refLoading.current, ...obj };
        _setLoading(refLoading.current);
    };

    const setDataMaster = (obj = {}) => {
        datamasterRef.current = { ...datamasterRef.current, ...obj };
        _setDataMaster({ ...dataMaster, ...datamasterRef.current });
    };

    const loadForm = () => {
        const { BookingID, BookingItemID } = dataInfo.current;
        const params = {
            BookingID,
            BookingItemID
        };
        setLoading({ formLoading: true });
        dispatch(WA3F2106Actions.loadForm(params, (error, data) => {
            setLoading({ formLoading: false });
            if (error) {
                oldDataMaster.current = { ...initialState };
                setDataMaster(initialState);
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                oldDataMaster.current = { ...data };
                setDataMaster(data);
            }
        }));
    };

    const loadCboBanquetCode = () => {
        setLoading({ cboBanquetCodeLoading: true });
        dispatch(generalActions.loadCboBanquetCode((error) => {
            setLoading({ cboBanquetCodeLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        }));
    };

    const onChange = (fieldName, e, isParseToNum = false) => {
        if (!e || !fieldName) return;
        let value = e?.value ?? e?.target?.value ?? "";
        if (fieldName === "HourFrom" || fieldName === "HourTo") value = e;
        if (isParseToNum) value = _.toNumber(value);
        if (_.isBoolean(value)) value = value ? 1 : 0;
        let objValue = { [fieldName]: value };
        switch (fieldName) {
            case "BanquetCode":
                objValue = { ...objValue, ...e?.data };
                break;
            default:
                break;
        }
        setDataMaster({
            ...dataMaster,
            ...objValue
        });
        if (error[fieldName] && value) setError({ ...error, [fieldName]: "" });
    };

    const setStateErrorText = (objValue) => {
        setError({ ...error, ...objValue });
        return Object.keys(objValue).length !== 0;
    };

    const checkValidateMaster = (arrName) => {
        if (_.isEmpty(arrName)) return [];
        return arrName.map(item => ({
            name: item,
            rules: ["isRequired"],
            value: dataMaster[item]
        }));
    };

    const onSaveHistory = async (BookingID = dataMaster?.BookingID || "") => {
        let action = 0;
        if (mode === "edit") action = 1;
        const captions = {
            BanquetCode: "Ma_sanh",
            BanquetName: "Ten_sanh",
            ContactTelNo: "So_dien_thoai",
            EventDate: "Ngay_su_kien",
            HourFrom: "Gio_su_kien", // Từ đến
            EventName: "Ten_su_kien"
        };
        const newData = dataMaster;
        const oldData = oldDataMaster.current;
        const options = {
            data: newData,
            dataCompare: oldData,
            captions,
            action,
            ModuleID: "DA3",
            TransactionID: FormID,
            TransID: BookingID,
            TransactionName: Config.lang("Cap_nhat_thong_tin_Booking"),
            itemRender: (e, type) => {
                const { data, oldData, key } = e;
                if (type === "Content") {
                    if (key === "HourFrom") {
                        return `${data[key] || ""} - ${data?.HourTo || ""}`;
                    } else {
                        return `${oldData[key] || ""} - ${oldData?.HourTo || ""}`;
                    }
                }
            }
        };
        const history = new History(options);
        // console.log("===========HIS=========>", history.get());
        return history.get()?.length > 0 ? await history.save() : true;
    };

    const onSave = async () => {
        const requiredField = ["BanquetCode", "EventDate", "HourFrom", "HourTo"];
        const resultValidate = checkValidateMaster(requiredField);
        const validateForm = MForm.formValidation(resultValidate);
        if (Object.keys(validateForm).length > 0) {
            setStateErrorText(validateForm);
            return false;
        } else {
            const params = dataMaster;
            setLoading({ formLoading: true });
            dispatch(WA3F2106Actions.save(params, async (error, data) => {
                setLoading({ formLoading: false });
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else if (data) {
                    if (data.Status === 0) {
                        await onSaveHistory();
                        saveSuccess.current = true;
                        setMode("view"); // Chuyển về View
                        Config.notify.show("success", _.get(data, "Message", "") || Config.lang("Luu_thanh_cong"), 2000);
                    } else {
                        Config.popup.show("INFO", _.get(data, "Message", "") || Config.lang("Loi_chua_xac_dinh"));
                        return false;
                    }
                }
            }));
        }
    };

    return (
        <Modal
            id={"Popup-WA3F2106"}
            width={"960"}
            open={openModal}
            onClose={() => {
                if (onOpenModalAction) {
                    let reLoad = false;
                    if (saveSuccess.current) reLoad = true;
                    onOpenModalAction(false, reLoad);
                }
            }}>
            <ModalHeader>{Config.lang("Cap_nhat_thong_tin_Booking")}</ModalHeader>
            <ModalBody>
                <React.Fragment>
                    <LoadPanel
                        position={{ my: 'center', of: '#Popup-WA3F2106' }}
                        visible={disabledLoading}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                    />
                    <Row>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <TextInput
                                disabled
                                label={Config.lang("Ma_booking")}
                                value={dataMaster?.BookingNo}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <TextInput
                                disabled
                                label={Config.lang("Ma_khach_hang")}
                                value={dataMaster?.ObjectID}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={6}>
                            <TextInput
                                disabled
                                label={Config.lang("Ten_khach_hang")}
                                value={dataMaster?.ObjectName}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <Dropdown
                                clearAble
                                required
                                readOnly={disabled}
                                valueExpr={"BanquetCode"}
                                displayExpr={"BanquetCode"}
                                label={Config.lang("Sanh")}
                                error={error && error["BanquetCode"]}
                                onChange={e => onChange("BanquetCode", e)}
                                dataSource={dataCboBanquetCode}
                                loading={cboBanquetCodeLoading}
                                value={dataMaster?.BanquetCode}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={3}>
                            <TextInput
                                readOnly
                                label={Config.lang("Ten_sanh")}
                                value={dataMaster?.BanquetName}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Vi_tri")}
                                value={dataMaster?.Location}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <DatePicker
                                required
                                error={error && error["EventDate"]}
                                placeholder={"dd/mm/yyyy"}
                                displayFormat={"DD/MM/YYYY"}
                                label={Config.lang("ngay_su_kien")}
                                value={dataMaster?.EventDate}
                                onChange={(e) => onChange("EventDate", e)}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <Row className={"wrap align-right"}>
                                <Col xs={6} sm={6} md={6} lg={6}>
                                    <TimePickerDropDown
                                        required
                                        readOnly={disabled}
                                        error={error && error["HourFrom"]}
                                        label={Config.lang("Gio_su_kien")}
                                        onChange={(e) => onChange("HourFrom", e)}
                                        placeholder={Config.lang("hh:mm")}
                                        value={dataMaster?.HourFrom}
                                    />
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={6} style={{ height: 'fit-content' }}>
                                    <TimePickerDropDown
                                        required
                                        readOnly={disabled}
                                        error={error && error["HourTo"]}
                                        onChange={(e) => onChange("HourTo", e)}
                                        placeholder={Config.lang("hh:mm")}
                                        value={dataMaster?.HourTo}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={6}>
                            <TextInput
                                readOnly={disabled}
                                inputProps={{
                                    maxLength: 500
                                }}
                                label={Config.lang("Ten_su_kien")}
                                value={dataMaster?.EventName}
                                onChange={(e) => onChange("EventName", e)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                readOnly={disabled}
                                label={Config.lang("Ghi_chu")}
                                value={dataMaster?.NotesChange}
                                onChange={e => onChange("NotesChange", e)}
                            />
                        </Col>
                    </Row>
                </React.Fragment>
            </ModalBody>
            <ModalFooter>
                <div className={"wrap"}>
                    <Button
                        disabled={disabled || disabledLoading}
                        size={"medium"}
                        color={"info"}
                        startIcon={"Save"}
                        viewType={"filled"}
                        label={Config.lang("Luu")}
                        onClick={onSave}
                    />
                </div>
            </ModalFooter>
        </Modal>
    );
};

WA3F2106.defaultProps = {
    openModal: false,
    dataInfo: {}
};

WA3F2106.propTypes = {
    openModal: PropTypes.bool,
    dataInfo: PropTypes.object,
    onOpenModalAction: PropTypes.func,
};

export default WA3F2106;
