import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, TextInput, Checkbox, Row, Col } from 'diginet-core-ui/components';
import { LoadPanel } from "devextreme-react";
import Config from '../../../../config/index';
import * as WA3F2100Actions from '../../../../redux/modules/WA3/WA3F2100/WA3F2100_actions';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
// import { Column } from "devextreme-react/data-grid";
// import GridContainer from "../../../grid-container/grid-container";

function WA3F2101(props) {
    const { open, onClose, item } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState(null);
    const [filter] = useState({
        skip: 0, limit: 10
    })
    useEffect(() => {
        loadForm();
        /* eslint-disable */
    }, [])

    const loadForm = () => {
        const params = { ...filter, BanquetCode: item.BanquetCode };
        dispatch(WA3F2100Actions.loadFormA301(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show(message);
                setLoading(false);
                return false;
            }
            setLoading(false);
            setFormData(data[0]);
        }));
    };

    // const onChangePage = (page) => {
    //     setFilter({ ...filter, skip: page * filter.limit });
    // };

    // const onChangePerPage = (perPage) => {
    //     setFilter({ skip: 0, limit: perPage });
    // };

    return (
        <div>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{ my: 'center', of: "#root" }}
                visible={loading}
                showIndicator={true}
                shading={false}
                showPane={true}
            />
            <Modal
                open={open}
                onClose={onClose}
                zIndex={1399}
                width={'50%'}
            >
                <ModalHeader>
                    {Config.lang("Thong_tin_sanh")}
                </ModalHeader>
                <ModalBody >
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Col md={3}>
                            <TextInput
                                label={Config.lang('Sanh')}
                                value={_.get(formData, 'BanquetCode', '')}
                                readOnly
                            />
                        </Col>
                        <Col md={7}>
                            <TextInput
                                label={Config.lang('Ten_sanh')}
                                value={_.get(formData, 'BanquetName', '')}
                                readOnly
                            />
                        </Col>
                        <Col md={2}>
                            <Checkbox disabled checked={_.get(formData, 'Disabled', 0) === 1} label={Config.lang('KSD')} />
                        </Col>
                    </Row>
                    <TextInput
                        label={Config.lang('Vi_tri')}
                        value={_.get(formData, 'Location', '')}
                        readOnly
                    />
                    <TextInput
                        label={Config.lang('Cong_suat_toi_da')}
                        value={_.get(formData, 'DeliverMax', '')}
                        readOnly
                    />
                    <TextInput
                        readOnly
                        multiline
                        rows={5}
                        maxRows={5}
                        inputProps={{
                            maxLength: 5000
                        }}
                        viewType={"outlined"}
                        label={Config.lang("Ghi_chu")}
                        value={_.get(formData, 'Notes', '')}
                    />
                    {/* <div>
                        <div className={classes.textInfo}>{Config.lang('Thong_tin_chi_tiet')}</div>

                        <GridContainer
                            dataSource={_.get(formData, 'detail.rows', [])}
                            totalItems={_.get(formData, 'detail.total', 0)}
                            height={300}
                            columnAutoWidth={true}
                            pagerFullScreen={false}
                            itemPerPage={filter.limit}
                            skipPerPage={filter.skip}
                            onChangePage={onChangePage}
                            onChangePerPage={onChangePerPage}
                        >
                            <Column
                                caption={Config.lang("STT")}
                                dataField={'OrderNum'}
                                width={100}
                            />
                            <Column
                                caption={Config.lang("Hinh_thuc_tiec")}
                                dataField={'FormSetup'}
                                width={150}
                            />
                            <Column
                                caption={Config.lang("So_luong_ban_B")}
                                dataField={'QTYTable'}
                                width={150}
                            />
                            <Column
                                caption={Config.lang("So_luong_khach")}
                                dataField={'QTYCustomer'}
                                width={150}
                            />
                            <Column
                                caption={Config.lang("Dien_giai")}
                                dataField={'Description'}
                            />
                        </GridContainer>
                    </div> */}
                </ModalBody>
            </Modal>
        </div>
    );
}

export default WA3F2101;
