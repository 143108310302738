/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 5/4/2021
 * @Example
 */

import React, {useEffect, useRef, useState, useCallback} from "react";
import {connect}                            from "react-redux";
import * as WA3F2103Actions                 from "../../../../redux/modules/WA3/WA3F2103/WA3F2103_actions";
import {bindActionCreators, compose}        from "redux";
import Config                               from "../../../../config";
import ActionToolbar                        from "../../../common/layouts/toolbar/action-toolbar";
import withStyles                           from "@material-ui/core/styles/withStyles";
import * as generalActions                  from "../../../../redux/general/general_actions";
import FullCalendar                         from "@fullcalendar/react";
import dayGridPlugin                        from "@fullcalendar/daygrid";
import moment                               from "moment";
import {Row, Col}                           from "react-bootstrap";
import interactionPlugin                    from "@fullcalendar/interaction"; // needed for dayClick
import {makeStyles}                         from "diginet-core-ui/theme";
import _                                    from "lodash";
import {LoadPanel}                          from "devextreme-react/load-panel";
import mouse                                from "../../../common/libs/mouse";
import {Dropdown, TextInput, ButtonIcon}    from "diginet-core-ui/components";
import {CalendarNew}                        from 'diginet-core-ui/icons';
import {Popover, Calendar}                  from "devextreme-react";
import {browserHistory}                     from "react-router";
import 'moment-lunar';
import "./WA3F2103.scss";
import WA3F2110                             from '../WA3F2110/WA3F2110';
import WA3F2105                             from '../WA3F2105/WA3F2105';

const useStyles = makeStyles(() => ({
    calendarPopup: {
        width:                      380,
        height:                     250,
        minHeight:                  250,
        "& .dx-calendar-cell span": {
            width:        86,
            borderRadius: 4,
            border:       '0.5px solid #7F828E'
        }
    },
    popoverDate:   {
        "& .dx-popup-content": {
            padding: "10px 0 !important"
        }
    },
}));

const initCalendar    = {
    calendarWeekends: true,
    calendarEvents:   [
        // initial event data
    ],
};
const _initDataMaster = {
    Banquet:   {
        BanquetCode: "",
        BanquetName: ""
    },
    MothOfDay: moment().format("YYYY-MM-DD"),
    DateFrom:  moment().startOf("M").format("YYYY-MM-DD"),
    DateTo:    moment().endOf("M").format("YYYY-MM-DD")
};
const WA3F2103        = (props) => {
    const { location } = props;
    const classes = useStyles();

    const {dataCboBanquet}                              = props;
    const [loading, _setLoading]                        = useState(false);
    const [dataMaster, _setDataMaster]                  = useState(_initDataMaster);
    const [calendar, setCalendar]                       = useState(initCalendar);
    const [iPermission, setIPermission]                 = useState(0);
    const [iPermissionWA3F2110, setIPermissionWA3F2110] = useState(0);
    const [openWA3F2110,setOpenWA3F2110]                = useState(false);
    const [openWA3F2105, setOpenWA3F2105]               = useState(false);

    const refLoading      = useRef({});
    const refCalendar     = useRef(null);
    const refWrapCalendar = useRef(null);
    const refInputDate    = useRef(null);
    const refPopoverDate  = useRef(null);
    const item            = useRef(null);
    const WA3F2110Mode    = useRef("add");
    const dataInfoF2105   = useRef({
        Mode: "view",
        fromScreen: "WA3F2103",
        Permission: _.get(location, "state.Permission", null),
        BanquetCode: _.get(location, "state.BanquetCode", ""),
        BanquetName: _.get(location, "state.BanquetName", ""),
        EventDate: null,
        EventLunaDate: null,
    });

    const _loading = !!Object.keys(loading).find(k => loading[k]);

    const setLoading    = (obj) => {
        refLoading.current = {...refLoading.current, ...obj};
        _setLoading(refLoading.current);
    };
    const setDataMaster = (obj) => {
        _setDataMaster({...dataMaster, ...obj});
    };
    const getBanquet    = (data) => {
        if (!data) return null;
        return {
            BanquetCode: data?.BanquetCode || "",
            BanquetName: data?.BanquetName || "",
            Description:  data?.Description || "",
        }
    };

    const loadPermission = () => {
        props.generalActions.getPermission(["WA3F2100", "WA3F2110"], (arrPer) => {
            const pWA3F2100 = arrPer.find(a => a?.FormID === "WA3F2100");
            const pWA3F2110 = arrPer.find(a => a?.FormID === "WA3F2110");
            setIPermission(pWA3F2100?.Permission || 0);
            setIPermissionWA3F2110(pWA3F2110?.Permission || 0);
        });
    };

    const loadCboBanquet = () => {
        const {BanquetCode} = getInfo();
        setLoading({cboBanquet: true});
        props.WA3F2103Actions.loadCboBanquet({
            BanquetCode: BanquetCode || "",
        }, (error, data) => {
            setLoading({cboBanquet: false});
            if (error) {
                Config.popup.show(error);
                return false;
            }
        });
    };

    const loadForm = () => {
        const {Banquet: {BanquetCode}, MothOfDay, DateFrom, DateTo} = dataMaster || {};
        const params                                                         = {
            BanquetCode,
            MothOfDay,
            DateFrom,
            DateTo
        };
        setLoading({main: true});
        props.WA3F2103Actions.loadForm(params, (error, data) => {
            setLoading({main: false});
            if (error) {
                Config.popup.show(error);
                return false;
            }
            if (data) {
                const _data = _.groupBy(data, "CalendarDate");
                let results = [];
                for (let key in _data) {
                    const obj         = {};
                    obj.start         = key;
                    obj.QTYBooking    = _data[key]?.length ? (_data[key][0]?.QTYBooking || 0) : 0;
                    obj.QTYDeposit    = _data[key]?.length ? (_data[key]?.[0].QTYDeposit || 0) : 0;
                    obj.extendedProps = _data[key]?.length ? _data[key][0] : null;
                    obj.dateStr       = moment(key).format("YYYY-MM-DD");
                    results.push(obj);
                }
                setCalendar({
                    ...calendar,
                    calendarEvents: results
                });
            }
        });
    };

    useEffect(loadPermission, []);

    useEffect(() => {
        if (iPermission > 0) {
            const { BanquetCode, BanquetName, Description } = getInfo();
            if (refWrapCalendar?.current) {
                mouse.dragToScroll(refWrapCalendar.current);
            }
            loadCboBanquet();
            if (BanquetCode) {
                setDataMaster({
                    Banquet: getBanquet({
                        BanquetCode, BanquetName, Description
                    }),
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);

    useEffect(() => {
        if (iPermission > 0) {
            loadForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMaster?.Banquet?.BanquetCode, dataMaster?.MothOfDay]);

    const getInfo = () => {
        const {location} = props;
        let state        = {};
        if (location?.state) {
            state = {
                BanquetCode: location?.state?.BanquetCode || "",
                BanquetName: location?.state?.BanquetName || "",
                Description: location?.state?.Description || "",
            };
        }
        return state;
    };

    const handleChange = (key, e) => {
        if (!key || !e) return false;
        const value = e?.value || e?.target?.value || "";
        switch (key) {
            case "Banquet":
                setDataMaster({[key]: e?.data || null});
                break;
            case "DateRange":
                setDataMaster({
                    DateFrom: null,
                    DateTo:   null
                });
                const MothOfDay = moment(value).format("YYYY-MM");
                const DateFrom  = moment(value).startOf("M").format("YYYY-MM-DD");
                const DateTo    = moment(value).endOf("M").format("YYYY-MM-DD");
                setDataMaster({
                    DateFrom,
                    DateTo,
                    MothOfDay
                });
                toogleSelectRangeDate(false);
                break;
            default:
                break;
        }
    };

    const toogleSelectRangeDate = (flag) => {
        if (refPopoverDate?.current && !_loading) {
            if (flag) {
                refPopoverDate.current.instance.show();
            } else {
                refPopoverDate.current.instance.hide();
            }
        }
    };

    const getStringDate = (dateFrom, dateTo) => {
        const {DateFrom, DateTo} = dataMaster;
        dateFrom                 = dateFrom ? dateFrom : DateFrom;
        dateTo                   = dateTo ? dateTo : DateTo;
        let result               = "";
        if (dateFrom) {
            result += moment(dateFrom).format("DD/MM/YYYY");
        }
        if (dateTo) {
            if (result) result += " - ";
            result += moment(dateTo).format("DD/MM/YYYY");
        }
        return result;
    };

    const onAction = (key, e, isModal = false) => {
        if (!key) return false;
        const {date}                                               = e || {};
        const {BanquetCode, MonthOfDay, DateFrom, DateTo} = dataMaster || {};
        let FormID                                                 = "";
        let params                                                 = {
            BanquetCode,
            MonthOfDay,
            DateFrom,
            DateTo,
        };
        const dataM = { ...dataMaster };
        if (date) {
            dataM.CalendarDate = moment(date).format("YYYY-MM-DD");
        }
        const banquet = dataM.Banquet;
        delete dataM['Banquet']
        const item = Object.assign(banquet, dataM);
        switch (key) {
            case "Booking":
                // FormID = "WA3F2110";
                onOpenWA3F2110(item)
                break;
            case "DetailCalendar":
                if (date) {
                    FormID = "WA3F2105";
                    params = {
                        ...params,
                        CalendarDate: moment(date).format("YYYY-MM-DD")
                    };
                }
                dataInfoF2105.current.BanquetCode = item?.BanquetCode;
                dataInfoF2105.current.BanquetName = item?.BanquetName;
                if (e?.date) {
                    dataInfoF2105.current.EventDate = moment(e.date).format("YYYY-MM-DD");
                    dataInfoF2105.current.EventLunaDate = moment(e.date).lunar().format("YYYY-MM-DD");
                }
                    setOpenWA3F2105(true);
                    break;
            case "DetailBooking":
                onOpenWA3F2110(item)
                // if (date) {
                //     FormID = "WA3F2110";
                //     params = {
                //         ...params,
                //         CalendarDate: moment(date).format("YYYY-MM-DD")
                //     };
                // }
                break;
            default:
                break;
        }
        if (isModal === false) {
            if (FormID) {
                onRedirectScreen(FormID, params);
            }
        }
    };

    const onRedirectScreen = (FormID, params) => {
        if (FormID) {
            browserHistory.push({
                pathname: Config.getRootPath() + FormID,
                state:    params
            });
        }
    };

    const renderEventAction = (e) => {
        const {isOther, isPast} = e;
        return <div>
            <span>{e.dayNumberText || ""}</span>
            {!isOther && <div className={"display_col"}
                              style={{
                                  position: 'absolute',
                                  bottom:   15,
                                  right:    5
                              }}>
                <ButtonIcon name={"Event"} size={"small"} viewType={"text"} color={"primary"}
                            onClick={() => onAction("DetailCalendar", e, true)}
                />
                <ButtonIcon name={"Touch"} size={"small"} viewType={"text"} color={"primary"}
                            disabled={isPast}
                            onClick={() => onAction("DetailBooking", e)}
                />
            </div>}
        </div>
    };

    const getBanquetName = (data) => {
        if (data?.BanquetName) {
            return data?.BanquetName;
        } else if (dataMaster?.Banquet?.BanquetName) {
            return dataMaster?.Banquet?.BanquetName || "";
        } else {
            if (dataCboBanquet?.length && (dataMaster?.Banquet?.BanquetCode || data?.Banquet?.BanquetCode)) {
                const banquetCode = data?.Banquet?.BanquetCode || dataMaster?.Banquet?.BanquetCode || "";
                return dataCboBanquet.find(d => d.BanquetCode === banquetCode)?.BanquetName || "";
            }
        }
    };

    const onOpenWA3F2110 = (rs, isLoadForm = false) => {
        item.current = rs;
        if(isLoadForm === true) loadForm(); // Load lại form
        WA3F2110Mode.current = "add"; // đóng form set lại về mode "add"
        setOpenWA3F2110(!openWA3F2110)
    }

    const onMouseOver = useCallback(e => {
        const tdDay      = e.target.closest(".fc-daygrid-day");
        if (!e?.buttons) tdDay.style.backgroundColor = "#FFF4CC";
    }, []);
    const onMouseLeave = useCallback(e => {
        const tdDay      = e.target.closest(".fc-daygrid-day");
        if (!e?.buttons) tdDay.style.backgroundColor = "#FFF4CC";
    }, []);

    const itemInfo = () => {
        const dataM = { ...dataMaster };
        const banquet = dataM.Banquet;
        delete dataM['Banquet'];
        return Object.assign(banquet, dataM);
    };

    const {BanquetCode} = getInfo();
    if (!iPermission && !BanquetCode) return null;
    return (
        <React.Fragment>
            {openWA3F2110 && <WA3F2110 item={item.current} mode={WA3F2110Mode.current} open={openWA3F2110}
                                       onClose={(isLoadForm) => onOpenWA3F2110(null, isLoadForm)}/>}
            {openWA3F2105 && <WA3F2105
                dataInfo={dataInfoF2105.current}
                openModal={openWA3F2105}
                onOpenWA3F2110={(status, data, mode = "add") => {
                    if (!_.isEmpty(data)) item.current = data;
                    if (!_.isEmpty(mode)) WA3F2110Mode.current = mode;
                    if (_.isBoolean(status)) {
                        if (status && mode === "add") item.current = itemInfo();
                        setOpenWA3F2110(status);
                        setOpenWA3F2105(false);
                    }
                }}
                onOpenModalAction={(status, isLoadForm = false) => {
                    if (_.isBoolean(status)) setOpenWA3F2105(status);
                    if (isLoadForm === true) loadForm(); // load lại form
                }}/>}
            <ActionToolbar
                title={Config.lang("Lich")}
                onBack={() => onRedirectScreen("WA3F2100")}
            />
            <Popover
                ref={refPopoverDate}
                target={refInputDate?.current}
                position={"bottom"}
            >
                <div style={{padding: 10}}>
                    <Calendar
                        value={dataMaster?.MothOfDay || null}
                        onValueChanged={(e) => handleChange("DateRange", e)}
                        maxZoomLevel={"year"}
                        minZoomLevel={"century"}
                        disabled={false}
                        className={classes?.calendarPopup || ""}
                        visible={true}/>
                </div>
            </Popover>
            <LoadPanel
                position={{
                    my: 'center',
                    of: '#root'
                }}
                visible={_loading}
                showIndicator={true}
                shading={true}
                showPane={true}
            />
            <div style={{
                width:     "100%",
                marginTop: 10
            }}>
                <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <Row>
                            <Col lg={4} md={4} sm={12} xs={12}>
                                <Dropdown
                                    dataSource={dataCboBanquet}
                                    disabled={_loading}
                                    displayExpr={"BanquetName"}
                                    valueExpr={"BanquetCode"}
                                    renderSelectedItem={(e) => {
                                        const {data} = e || {};
                                        return data?.BanquetCode || "";
                                    }}
                                    label={Config.lang("Sanh_tiec")}
                                    placeholder={Config.lang("Sanh_tiec")}
                                    value={dataMaster?.Banquet?.BanquetCode || ""}
                                    onChange={(e) => handleChange("Banquet", e)}
                                />
                            </Col>
                            <Col lg={8} md={8} sm={12} xs={12}>
                                <TextInput
                                    label={" "}
                                    style={{marginTop: 3}}
                                    readOnly={true}
                                    value={getBanquetName()}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <div className={"display_row align-center valign-bottom"}>
                            <TextInput
                                ref={refInputDate}
                                label={Config.lang("Thoi_gian")}
                                style={{width: window.innerWidth <= 768 ? "100%" : "70%"}}
                                value={getStringDate()}
                                readOnly={true}
                                onClick={() => toogleSelectRangeDate(true)}
                                endIcon={<CalendarNew/>}
                            />
                            <ButtonIcon
                                name={"Touch"}
                                color={"primary"}
                                className={"mgl15"}
                                size={"small"}
                                disabled={!iPermissionWA3F2110}
                                onClick={() => onAction("Booking")}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <Row style={{
                height: "84%",
                // width:  "100%",
            }}>
                <Col lg={12} md={12} sm={12} style={{height: "100%"}}>
                    <div style={{
                        height:   "100%",
                        overflow: "auto",
                    }}
                         ref={refWrapCalendar}
                    >
                        <div className="demo-app-calendar">
                            {dataMaster?.DateFrom && <FullCalendar
                                timeZone="UTC"
                                headerToolbar={false}
                                titleFormat={{
                                    year:  "numeric",
                                    month: "numeric",
                                }}
                                // eventBackgroundColor="black"
                                // eventBorderColor="yellow"
                                // eventTextColor="yellow"
                                height={"100%"}
                                locale={Config.language === "84" ? "vi" : "en"}
                                initialDate={moment(dataMaster?.DateFrom).format("YYYY-MM-DD")}
                                eventContent={(arg) => {
                                    const event                    = arg.event.toPlainObject();
                                    const {QTYBooking, QTYDeposit} = event.extendedProps || {};
                                    return (
                                        <div style={{
                                            width:      "100%",
                                            whiteSpace: "pre-wrap"
                                        }}>
                                            <div className={"display_row align-center"} style={{height: 36}}>
                                                {QTYBooking > 0 ? `${Config.lang("Giu_cho")} ${QTYBooking}` : " "}
                                            </div>
                                            <div className={"display_row align-center"} style={{height: 36}}>
                                                {QTYDeposit > 0 ? `${Config.lang("Dat_coc")} ${QTYDeposit}` : " "}
                                            </div>
                                        </div>
                                    );
                                }}
                                dayCellContent={(e) => {
                                    const date      = moment(e?.date);
                                    let strDay      = date.format("DD");
                                    e.dayNumberText = strDay === "01" ? strDay + "/" + date.format("MM") : strDay;
                                    return renderEventAction(e);
                                }}
                                dayCellDidMount={(evt) => {
                                    const tdDay         = evt.el.closest(".fc-daygrid-day");
                                    const dateTop       = tdDay.querySelector(".fc-daygrid-day-top");
                                    const mainContainer = document.createElement("div");
                                    const elLunarDate   = document.createElement("div");
                                    mainContainer.setAttribute("data-id", evt.date);
                                    //lunar date..
                                    const lunarDate = moment(evt?.date).lunar();
                                    let strLunarDay = lunarDate.format("DD");
                                    if (lunarDate.format("DD") === "01") {
                                        strLunarDay += "/" + lunarDate.format("MM");
                                    }
                                    elLunarDate.innerHTML = strLunarDay;

                                    dateTop.append(mainContainer);
                                    dateTop.append(elLunarDate);
                                }}
                                eventDidMount={(evt) => {
                                    const tdDay      = evt.el.closest(".fc-daygrid-day");
                                    const tdDayEvent = tdDay.querySelector(".fc-daygrid-event");
                                    const bgColor    = "#FFF4CC";
                                    if (tdDayEvent) {
                                        tdDay.classList.add("fc-day-has-event");
                                        tdDay.style.backgroundColor = bgColor;
                                        tdDayEvent.style.color      = "#000";//theme.palette.getContrastText(bgColor);
                                        tdDay.addEventListener("mouseover", onMouseOver, false);
                                        tdDay.addEventListener("mouseleave", onMouseLeave, false);
                                    } else {
                                        tdDay.classList.remove("fc-day-has-event");
                                        tdDay.style.backgroundColor = null;
                                        tdDay.removeEventListener("mouseover", onMouseOver, false);
                                        tdDay.removeEventListener("mouseleave", onMouseLeave, false);
                                    }
                                    //add icon
                                }}
                                eventWillUnmount={(evt) => {
                                    const tdDay   = evt.el.closest(".fc-daygrid-day");
                                    if (tdDay) {
                                        if (tdDay.classList.contains("fc-day-has-event")) {
                                            tdDay.classList.remove("fc-day-has-event");
                                            tdDay.style.backgroundColor = null;
                                        }
                                        tdDay.removeEventListener("mouseover", onMouseOver, false);
                                        tdDay.removeEventListener("mouseleave", onMouseLeave, false);
                                    }
                                    //add icon
                                }}
                                firstDay="1"
                                visibleRange={{
                                    start: dataMaster?.DateFrom,
                                    end:   dataMaster?.DateTo,
                                }}
                                plugins={[
                                    dayGridPlugin,
                                    interactionPlugin,
                                ]}
                                dayHeaderFormat={{weekday: "long"}}
                                displayEventEnd
                                ref={refCalendar}
                                weekends={calendar?.calendarWeekends}
                                events={calendar.calendarEvents}
                            />}
                            <LoadPanel
                                position={{
                                    my: "center",
                                    of: refWrapCalendar?.current ? refWrapCalendar?.current : null,
                                }}
                                visible={!dataMaster?.DateFrom}
                                showIndicator={true}
                                shading={false}
                                showPane={true}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default compose(
    connect(state => ({
            dataCboBanquet: state.WA3F2103.dataCboBanquet
        }),
        (dispatch) => ({
            generalActions:  bindActionCreators(generalActions, dispatch),
            WA3F2103Actions: bindActionCreators(WA3F2103Actions, dispatch),
        })
    ), withStyles(null, {withTheme: true})
)(WA3F2103);
