/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/01/12 10:37
 * @update 2019/01/12 10:37
 * @file src/container/index.js
 */

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';

import * as mainActions    from '../redux/main/main_actions';
import Popup               from '../components/common/layouts/popup/popup';
import Config              from '../config';
import Header              from '../components/header/header';
import LoginPage           from "../components/login/login";
import {browserHistory}    from "react-router";
import SideNav             from "../components/side-nav/side-nav";
import LocalizedStrings    from "react-localization";
import Notify              from "../components/common/layouts/notify/notify";
import Controller          from "../config/controller";
import EventTracking       from "../components/common/layouts/tracking/event";
import * as generalActions from "../redux/general/general_actions";
import NotPermission       from '../components/common/layouts/errors/not-permission';
import AuthPage            from "../components/login/auth";
import {localize}          from "../localize/localize";
import Maintenance         from "../components/common/layouts/maintenance";
import moment              from "moment";
import Debugger from "../components/debugger/debugger";
import packaged from '../../package.json';
moment.locale('vi');

class Index extends Component {


    constructor(props){
        super(props);

        this.state = {
            expandSideBar: false,
            opened: true,
            iPermission: 0,
        };
        this.loading = true;
        this.notifyError = null;
    }

    componentDidMount = async () => {

        // Log ngày build code cuối cùng.
        console.log("updatedAt:", moment(packaged?.updatedAt || '').format('LLL'));

        // Log thời gian commit cuối của git log.
        console.log("codeLatest:", moment(new Date(packaged?.gitLatest)).format('LLL'));

        const {children} = this.props;
        document.addEventListener('mousedown', this.outClickSideBar);
        window.addEventListener('online', () => this.alertConnection(true));
        window.addEventListener('offline', () => this.alertConnection(false));
        Config.setLangDevextreme();
        this.loadLocalize();
        await this.changedRouterEvent();

        const pathname = children.props.route && children.props.route.path ? children.props.route.path : "";
        const isAdmin = pathname && pathname.indexOf("admin") !== -1;
        const stAdmin = Config.getLocalStorage('STADMINBEM');
        if (!isAdmin && stAdmin) {
            Config.setLocalStorage('STADMINBEM', '');
            Config.logout();
            return false;
        }
        this.redirectPage();
    };

    /**
     * func update fav icon from system LOGO_URL
     */
    setFaviconEl = () => {

        // get element favicon
        let svg = Config.getLocalStorage("LOGO_URL");

        // check setting change logo
        if(svg && svg === Config.getSetting('LOGO_URL')) return;

        // // save LOGO URL to LocalStorage
        svg = Config.getSetting("LOGO_URL");
        Config.setLocalStorage("LOGO_URL", svg);

        // update fav icon
        const link = document.querySelector('#favicon');
        link.href = svg;

    };

    redirectPage = () => {
        let pathinfo = Config.getLocalStorage('AUTHREDIRECTBEM');
        if (pathinfo) {
            pathinfo = JSON.parse(pathinfo);
            Config.removeLocalStorage('AUTHREDIRECTBEM');
            browserHistory.push({
                pathname: pathinfo.pathname || "",
                state: pathinfo.state
            });
        }
    };

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.outClickSideBar);
        window.removeEventListener('online', () => this.alertConnection(true));
        window.removeEventListener('offline', () => this.alertConnection(false));
    }

    alertConnection = (status) => {
        if (!this.notifyError) return false;
        if (!status) {
            const options = {
                ClickAwayListenerProps: {mouseEvent: false}
            };
            this.notifyError.show('error', Config.lang("Khong_co_ket_noi"), null, null, options);
        } else {
            this.notifyError.show('success', Config.lang("Da_ket_noi"), 5000);
        }
    };

    outClickSideBar = (e) => {
        const btnToogle = document.getElementById('toogle-nav');
        if (this.refs && this.refs['sideBar'] && this.refs['sideBar'].refs['side-container'] && this.refs['sideBar'].refs['side-container'].childNodes.length > 0
            && !this.refs['sideBar'].refs['side-container'].childNodes[0].contains(e.target) && !btnToogle.contains(e.target)) {
            this.setState({
                expandSideBar: false
            })
        }
    };

    changedRouterEvent = async () => {
        const pathname = window.location.pathname;
        const formID = Config.helpers.getFormIDFromPath(pathname);
        Config.formID = formID;
        Config.setLocalStorage('formActive', JSON.stringify(Config.helpers.updateCurrentForm(formID)));
        await browserHistory.listen( async (location) =>  {
            const formID = Config.helpers.getFormIDFromPath(location.pathname);
            if (formID !== Config.formID || (!formID || formID === '/' || formID === 'dashboard')) {
                this.props.generalActions.resetPermission();
            }
            if (Config.formID !== formID && Config.filters) Config.filters.setFilter(null);
            Config.formID = formID;
            Config.setLocalStorage('formActive', JSON.stringify(Config.helpers.updateCurrentForm(formID)));
        });
    };

    loadLocalize = () => {
        let cfLocalize = null;
        let lang = Config.getLocalStorage("langBEM");

        if (!lang || lang.length !== 2){
            lang = "vi";
            Config.setLocalStorage('langBEM','vi');
        }

        try {
            const lc = Config.getLocalStorage('LOCALIZE');
            cfLocalize = lc ? JSON.parse(lc) : null;

            if (!cfLocalize || cfLocalize.timestamps !== localize.timestamps) {
                cfLocalize = localize;
                Config.setLocalStorage('LOCALIZE',JSON.stringify(cfLocalize));
            }

        } catch (e) {

        }

        Config.localization = new LocalizedStrings(cfLocalize);
        Config.localization.setLanguage(lang);

        if (lang === "vi"){
            Config.language = "84";
        } else {
            Config.language = "01";
        }
    };

    UNSAFE_componentWillMount(){
        this.props.mainActions.loading();
        this.props.generalActions.resetPermission();
        // this.setState({isTab: Config.menuType});
    }

    onLogout = () => {
        this.props.generalActions.resetPermission();
        Config.logout();
    };

    // toggleMenuEss = (number, flag) => {
    //     this.setState({
    //         isTab: number
    //     },()=>{
    //         if(flag) {
    //             browserHistory.push({
    //                 pathname: Config.getRootPath() + 'dashboard',
    //                 state: {
    //                     mode: !number ? 'ESS' : 'MSS'
    //                 }
    //             });
    //         }
    //     })
    // };

    toogleSideBar = (flag) => {
        if (typeof flag === "undefined") {
            this.setState({
                expandSideBar: !this.state.expandSideBar
            });
        } else {
            this.setState({
                expandSideBar: flag
            });
        }
    };

    checkMaintenance = () => {
        const keyFrom = "DateFrom";
        const keyTo = "DateTo";
        const {maintenance} = this.props;
        let result = null;
        if (maintenance && maintenance[keyFrom]) {
            const now = moment();
            //Check current time greater than from time..
            const checkFrom = now.diff(moment(maintenance[keyFrom])) >= 0;
            //Check has't to or current time less than to time..
            const checkTo = !maintenance[keyTo] || now.diff(moment(maintenance[keyTo])) <= 0;
            result = checkFrom && checkTo ? maintenance : null;
        }
        return result
    };

    render() {
        const {children, token, setting, profile, iPermission} = this.props;
        const {expandSideBar} = this.state;

        let permission = false;

        if(token && token.id && setting && setting.length > 0) {
            this.loading = false;

            const _loading = document.getElementById("_preloader_loading");
            if (_loading) {
                _loading.style.display = "none";
            }

            // Khởi tạo localize sau khi có token
            this.loadLocalize();
        }
        if(setting) this.setFaviconEl();
        if(profile && profile.type !== 0) {
            permission = profile.type;
        }
        const listExpandContent = Config.helpers && Config.helpers.listExpandContent ? Config.helpers.listExpandContent : ["W75F2000", "W09F2000"];
        const listFormIgnoreTracking = Config.helpers && Config.helpers.listFormIgnoreTracking ? Config.helpers.listFormIgnoreTracking : [];
        const pathname = children?.props?.route?.path || "";
        this.isAdmin = pathname && pathname.indexOf("admin") !== -1;

        // check maintenance
        let maintenance = null;
        const url = new window.URLSearchParams(window.location.search);
        let pass = url.get('pass');
        let localDeployer = Config.getLocalStorage('DEPLOYER_SESSION');
        localDeployer = localDeployer && Config.isJson(localDeployer) ? JSON.parse(localDeployer) : null;

        if(!pass && localDeployer?.pass && moment(localDeployer.time).isAfter(moment())){
          pass = localDeployer?.pass;
        }

        if(Config.deployer !== pass){
            maintenance = this.checkMaintenance();
        }

        if(pass){
          Config.setLocalStorage('DEPLOYER_SESSION', JSON.stringify({pass:pass, time: moment().add(4,'hours')}))
        }

        if((!pass && localDeployer) || (maintenance?.DateTo && moment(maintenance.DateTo).isBefore(moment()))){
          Config.removeLocalStorage('DEPLOYER_SESSION');
        }

        if(this.isAdmin){
            window.location.replace(`${Config.env?.api}/admin`);
        }


        return (
            <div className="display_col full_w">
                <Controller ref={(ref) => Config.controller = ref} />
                {/*{(this.loading || iPermission === null) && (*/}
                {/*    <Loading />*/}
                {/*)}*/}

                {!this.isAdmin && pathname !== "report" && pathname === "auth" &&
                    <div className="display_col">
                        <AuthPage loading={this.loading}/>
                    </div>
                }

                {!this.isAdmin && maintenance &&
                    <div className="display_col">
                        <Maintenance data={maintenance}/>
                    </div>
                }

                {!this.isAdmin && !maintenance ? (
                    <>
                        {!this.isAdmin && !this.loading && !permission && pathname !== "report" &&
                        <div className="display_col">
                            <LoginPage/>
                        </div>
                        }

                        {!this.isAdmin && ((!this.loading && pathname === "report") || (profile && !this.loading && permission)) &&
                        <div className="body-container">
                            {profile && profile.UserID && listFormIgnoreTracking.indexOf(pathname) < 0 &&
                            <EventTracking category={"TRACKING_USER"} action={pathname ? pathname : "/"} label={profile.UserID} />
                            }
                            {pathname === "report" &&
                            <div className="body-container_right display_col">
                                {children}
                                <Notify ref={(ref) => Config.notify = ref}/>
                            </div>
                            }

                            {pathname !== "report" && <div className="body-container_right display_col">
                                <Header onLogout={this.onLogout}
                                        toggleMenuEss={this.toggleMenuEss}
                                        toogleSideBar={this.toogleSideBar}
                                        {...this.props}
                                />
                                <div className={'display_row side-nav'}>
                                    {/*{((pathname && pathname !== 'dashboard') || window.innerWidth <= 370) &&*/}
                                    <SideNav ref={'sideBar'}
                                             expand={expandSideBar}
                                             className={(listExpandContent.indexOf(pathname) > -1 ? "not-shadow" : "")}
                                             onLogout={this.onLogout}
                                             onToogle={(e) => this.toogleSideBar(e)}
                                             {...this.props}
                                    />
                                    {/*}*/}
                                    {iPermission === 0 && !pathname?.includes('W09F9000') && <div className={"content-container"}>
                                        <NotPermission/>
                                    </div>
                                    }
                                    {iPermission !== 0 && <div className={"content-container" + (listExpandContent.indexOf(pathname) > -1 ? " expand" : "")}>
                                        {children}
                                        <Notify ref={(ref) => Config.notify = ref} />
                                        <Notify ref={(ref) => this.notifyError = Config.notifyError = ref} minWidth={"50%"} anchorVertical={"top"} anchorHorizontal={"center"} />
                                    </div>
                                    }
                                </div>
                            </div>
                            }
                        </div>
                        }

                        {!this.loading && pathname === "error-page" && !profile && !permission && iPermission > 0 &&
                        <div className="body-container">
                            <div className="body-container_right display_col">
                                {children}
                            </div>
                        </div>
                        }

                    </>
                ) : null}

                <Popup ref={(ref) => {Config.popup = Config.popup2 = ref}} />
                {Config.getSetting('DEBUGGER') === "true" && <Debugger ref={ref => Config.debugger = ref} />}
            </div>
        );
    }
}

export default connect(state => ({
        token: state.main.token,
        setting: state.main.setting,
        profile: state.main.profile,
        maintenance: state.main.maintenance,
        iPermission: state.general.iPermission,
    }),
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
    })
)(Index);
