import { Column } from "devextreme-react/data-grid";
import { Modal, ModalBody, ModalHeader, Row, Col, TextInput } from 'diginet-core-ui/components';
import _ from "lodash";
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Config from '../../../../config/index';
import * as WA3F2041Actions from "../../../../redux/modules/WA3/WA3F2041/WA3F2041_actions";
import GridContainer from "../../../common/grid-container/grid-container";

const ChoseCBModal = (props) => {
    const dispatch = useDispatch();
    const { openModal, onOpenModalAction, FormID, dataKeyChose, keyExpr, keyExprName } = props;
    const [dataGridLoading, setDataGridLoading] = useState(false);
    const [dataCbo, setDataCbo] = useState({
        rows: [],
        total: 0
    });

    const timerSearch = useRef(null);
    const filterCbo = useRef({
        skip: 0,
        limit: 10,
        search: "",
    });

    useEffect(() => {
        if (openModal === true) loadCboName(keyExpr);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal]);

    const loadCboName = () => {
        let apiName = "";
        if (keyExpr === "InventoryID") {
            apiName = "loadCboInventory";
        } else if (keyExpr === "UnitID") {
            apiName = "loadCboUnit";
        }
        if (_.isEmpty(apiName)) return;
        const { skip, limit, search } = filterCbo.current;
        const param = {
            FormID,
            skip,
            limit,
            search
        };
        setDataGridLoading(true);
        dispatch(WA3F2041Actions[apiName](param, (error, data) => {
            setDataGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setDataCbo({
                    rows: _.get(data, "rows", []),
                    total: _.get(data, "total", 0)
                });
            }
        }));
    };

    const onChangeContractPage = (page) => {
        filterCbo.current.skip = page * filterCbo.current.limit;
        loadCboName();
    };

    const onChangeContractPerPage = (perPage) => {
        filterCbo.current.skip = 0;
        filterCbo.current.limit = perPage;
        loadCboName();
    };

    const getCaptionName = (varStr) => {
        let result = "";
        if (keyExpr === "InventoryID") {
            if (varStr === "Title") result = Config.lang("Ma_hang");
            if (varStr === "ID") result = Config.lang("Ma_hang");
            if (varStr === "Name") result = Config.lang("Ten_hang");
        } else if (keyExpr === "UnitID") {
            if (varStr === "Title") result = Config.lang("Don_vi_tinh");
            if (varStr === "ID") result = Config.lang("Ma");
            if (varStr === "Name") result = Config.lang("Ten");
        }
        return result;
    };

    return (
        <Modal
            zIndex={1020}
            width={"720"}
            open={openModal}
            onClose={() => { if (onOpenModalAction) onOpenModalAction(false) }}>
            <ModalHeader>{getCaptionName("Title")}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <TextInput
                            viewType={"outlined"}
                            endIcon={"Search"}
                            placeholder={Config.lang("Tim_kiem")}
                            onChange={(e) => {
                                const value = e?.target?.value;
                                if (timerSearch.current) clearTimeout(timerSearch.current);
                                timerSearch.current = setTimeout(() => {
                                    filterCbo.current.search = value;
                                    filterCbo.current.skip = 0;
                                    loadCboName();
                                }, 700);
                            }}
                            value={filterCbo.current.search}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <GridContainer
                            itemPerPage={filterCbo.current.limit}
                            skipPerPage={filterCbo.current.skip}
                            keyExpr={keyExpr}
                            typePaging={"remote"}
                            gridProps={{
                                style: { minHeight: 410 }
                            }}
                            pagerFullScreen={false}
                            loading={dataGridLoading}
                            typeShort={window.innerWidth < 768}
                            filterRow={{
                                visible: true,
                                showOperationChooser: false,
                            }}
                            onChangePage={onChangeContractPage}
                            onChangePerPage={onChangeContractPerPage}
                            totalItems={_.get(dataCbo, "total", 0)}
                            dataSource={_.get(dataCbo, "rows", [])}
                            onContentReady={(e) => {
                                if (dataKeyChose && !_.isEmpty(dataCbo?.rows)) {
                                    const grid = e.component;
                                    const index = dataCbo.rows.findIndex(item => item[keyExpr] === dataKeyChose);
                                    grid.selectRowsByIndexes([_.toNumber(index)]); // Default focus dòng đầu tiên
                                }
                            }}
                            onRowDblClick={(e) => {
                                if (onOpenModalAction) {
                                    const data = _.get(e, "data", {});
                                    onOpenModalAction(false, data) //Chọn và đóng modal
                                }
                            }}
                        >
                            <Column
                                caption={getCaptionName("ID")}
                                dataField={keyExpr}
                                width={240}
                            />
                            <Column
                                caption={getCaptionName("Name")}
                                dataField={keyExprName}
                            />
                        </GridContainer>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

ChoseCBModal.propTypes = {
    openModal: PropTypes.bool,
    FormID: PropTypes.string,
    mode: PropTypes.string,
    keyExpr: PropTypes.string,
    keyExprName: PropTypes.string,
    onOpenModalAction: PropTypes.func,
};

export default ChoseCBModal;

