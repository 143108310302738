/**
 * @copyright 2022 @ DigiNet
 * @author ANHTAI
 * @create 05/05/2022
 * @Example
 */
import { Column } from "devextreme-react/data-grid";
import { Modal, ModalBody, ModalHeader, ModalFooter, TextInput, Row, Col, Button, Checkbox } from 'diginet-core-ui/components';
import _ from "lodash";
import moment from "moment";
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from "react";
import * as WA3F2041Actions from "../../../../redux/modules/WA3/WA3F2041/WA3F2041_actions";
import { useDispatch } from "react-redux";
import Config from '../../../../config/index';
import GridContainer from "../../../common/grid-container/grid-container";

const EventModal = (props) => {
    const dispatch = useDispatch();
    const { openModal, onOpenModalAction, FormID, dataInfo, dataChosenProps } = props;
    const dataChosen = useRef([]);
    const gridPages = useRef({
        skip: 0,
        limit: 10,
        search: ""
    });
    const [isChecked, setIsChecked] = useState(false);
    const [dataGridLoading, setDataGridLoading] = useState(false);
    const [dataGrid, setDataGrid] = useState({
        total: 0,
        rows: []
    });

    useEffect(() => {
        loadCboEventCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadCboEventCode = () => {
        const { ObjectID = "" } = dataInfo;
        const { skip, limit, search } = gridPages.current;
        const param = {
            FormID,
            ObjectID,
            skip,
            limit,
            search
        };
        setDataGridLoading(true);
        dispatch(WA3F2041Actions.loadEvent(param, (error, data) => {
            setDataGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const total = _.get(data, "total", []);
                const rows = _.get(data, "rows", []);
                if (dataChosenProps) {
                    for (let i = 0; i < rows.length; i++) {
                        const current = rows[i];
                        if (dataChosenProps.includes(current.EventID)) {
                            current["IsUsed"] = true;
                            dataChosen.current = [...dataChosen.current, current];
                        }
                    }
                }
                setDataGrid({
                    total,
                    rows
                });
            }
        }));
    };

    const formatValidTime = (date, isLuna = false) => {
        let result = date;
        if (!_.isNull(date) && Config.isValidDateTime(date)) {
            result = !!isLuna ? moment(date).lunar().format("YYYY-MM-DD") : Config.convertDate(date, null, "YYYY-MM-DD");
        }
        return result;
    };

    const renderChoseColumn = (e) => {
        const { IsUsed = false, EventID = "", BookingNo = "" } = e?.data || {};
        return <Checkbox
            className={"display_row valign-middle"}
            style={{ margin: 'auto' }}
            checked={IsUsed}
            onChange={(data) => {
                const currentBookingNo = dataChosen.current?.[0]?.BookingNo || "";
                if (!!currentBookingNo && currentBookingNo !== BookingNo) return;
                if (!isChecked) setIsChecked(true);
                dataChosen.current = data.value ? [...dataChosen.current, e.data] : dataChosen.current.filter(item => item?.EventID !== EventID);
                e.component.cellValue(e.rowIndex, "IsUsed", !!data?.value);
                e.component.saveEditData();
            }}
        />;
    };

    return (
        <Modal
            zIndex={1020}
            width={"960"}
            open={openModal}
            alignment={"center"}
            onClose={() => { if (onOpenModalAction) onOpenModalAction(false) }}>
            <ModalHeader>{Config.lang("Chon_ma_su_kien")}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <TextInput
                            viewType={"outlined"}
                            endIcon={"Search"}
                            placeholder={Config.lang("Tim_kiem")}
                            delayOnChange={700}
                            onChange={(e) => {
                                const value = e?.target?.value;
                                gridPages.current.search = value;
                                gridPages.current.skip = 0;
                                loadCboEventCode();
                            }}
                            value={gridPages.current.search}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <GridContainer
                            itemPerPage={gridPages.current.limit}
                            skipPerPage={gridPages.current.skip}
                            keyExpr={"EventID"}
                            pagerFullScreen={false}
                            typePaging={"normal"}
                            gridProps={{ style: { minHeight: 410 } }}
                            loading={dataGridLoading}
                            totalItems={_.get(dataGrid, "total", 0)}
                            dataSource={_.get(dataGrid, "rows", [])}
                            filterRow={{
                                visible: true,
                                showOperationChooser: false,
                            }}
                        >
                            <Column
                                width={115}
                                alignment={"center"}
                                dataField={"IsUsed"}
                                dataType={"boolean"}
                                caption={Config.lang("Chon")}
                                trueText={Config.lang("Co")}
                                falseText={Config.lang("Khong")}
                                cellRender={renderChoseColumn}
                            />
                            <Column
                                width={160}
                                dataField={"EventID"}
                                caption={Config.lang("Ma_su_kien")}
                            />
                            <Column
                                minWidth={160}
                                caption={Config.lang("Ten_su_kien")}
                                dataField={"EventName"}
                            />
                            <Column
                                width={140}
                                dataField={"ContractNo"}
                                caption={Config.lang("So_hop_dong")}
                            />
                            <Column
                                width={110}
                                caption={Config.lang("Ngay_su_kien")}
                                dataField={"EventDate"}
                                alignment={"right"}
                                dataType={"date"}
                                format={"dd/MM/yyyy"}
                            />
                            <Column
                                width={80}
                                alignment={"center"}
                                dataField={"HourFrom"}
                                caption={Config.lang("Tu_gio")}
                            />
                            <Column
                                width={80}
                                alignment={"center"}
                                dataField={"HourTo"}
                                caption={Config.lang("Den_gio")}
                            />
                            <Column
                                minWidth={120}
                                caption={Config.lang("Hinh_thuc_tiec")}
                                dataField={"EventTypeName"}
                            />
                            <Column
                                minWidth={120}
                                caption={Config.lang("Loai_ban_tiec")}
                                dataField={"SetupName"}
                            />
                        </GridContainer>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div style={{ margin: 'auto' }}>
                    <Button
                        disabled={!isChecked}
                        size={"medium"}
                        color={"info"}
                        viewType={"filled"}
                        label={Config.lang("Chon")}
                        onClick={() => {
                            let dataObj = {};
                            let objPlus = { EventID: [], ContractNo: [], BatchID: [] };
                            if (!!dataChosen.current.length) {
                                const { BookingNo = "", EventDate = null, EventLunaDate = null } = dataChosen.current?.[0] || {};
                                dataObj = dataGrid.rows.find(item => item?.BookingNo === BookingNo);
                                objPlus = {
                                    ...objPlus,
                                    EventDate: formatValidTime(EventDate),
                                    EventLunaDate: formatValidTime(EventLunaDate, true),
                                };
                                for (let i = 0; i < dataChosen.current.length; i++) {
                                    const obj = dataChosen.current[i];
                                    objPlus.EventID = [...objPlus?.EventID, obj.EventID];
                                    objPlus.ContractNo = [...objPlus?.ContractNo, obj.ContractNo];
                                    objPlus.BatchID = [...objPlus?.BatchID, obj.BatchID];
                                }
                            }
                            const data = {
                                ..._.omit(dataObj, ["EventID", "ContractNo", "BatchID"]),
                                ...objPlus
                            };
                            onOpenModalAction(false, data); //Chọn và đóng modal
                        }}
                    />
                </div>
            </ModalFooter>
        </Modal>
    );
};

EventModal.defaultProps = {
    openModal: false,
    FormID: "",
    dataInfo: {},
    dataChosenProps: []
};

EventModal.propTypes = {
    openModal: PropTypes.bool,
    FormID: PropTypes.string,
    dataInfo: PropTypes.object,
    dataChosenProps: PropTypes.array,
    onOpenModalAction: PropTypes.func,
};

export default EventModal;