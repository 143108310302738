/**
 * @copyright 2022 @ DigiNet
 * @author ANHTAI
 * @create 05/05/2022
 * @Example
 */
import { Column, Summary, TotalItem } from "devextreme-react/data-grid";
import { LoadPanel } from 'devextreme-react/load-panel';
import {
    Accordion,
    AccordionDetails,
    AccordionGroup,
    AccordionSummary,
    Attachment,
    Button,
    ButtonIcon,
    DatePicker,
    Dropdown,
    NumberInput,
    TabContainer,
    TabHeader,
    TabItem,
    TabPanel,
    TextInput,
    Col,
    Row,
    Typography
} from 'diginet-core-ui/components';
import { makeStyles, useTheme } from "diginet-core-ui/theme";
import _ from "lodash";
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState, memo, useImperativeHandle, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { browserHistory } from "react-router";
import Config from '../../../../config/index';
import * as generalActions from "../../../../redux/general/general_actions";
import * as WA3F2041Actions from "../../../../redux/modules/WA3/WA3F2041/WA3F2041_actions";
import MForm from "../../../common/forms/form-material/form";
import GridActionBar from "../../../common/grid-container/grid-actionbar";
import GridContainer from "../../../common/grid-container/grid-container";
import ActionToolbar from "../../../common/layouts/toolbar/action-toolbar";
import History from "../../../common/libs/history/history";
import CDN from "../../../common/utils/CDN";
import ExportReportModal from "../../../custom/ExportReportModal";
import EventModal from "./EventModal";
import moment from "moment";
import CboPop from "./CboPop";

const useStyles = makeStyles({
    moneyInputColumn: {
        width: '100%',
        margin: 0,
        '& input': {
            textAlign: 'right'
        }
    },
    ellipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    hiddenNewRow: {
        "& .dx-datagrid-rowsview table.dx-datagrid-table tbody tr.dx-row-inserted": {
            display: "none",
        }
    },
});

const FormID = "WA3F2041";
const attachmentType = ["txt", "jpg", "png", "doc", "docx", "xls", "xlsx", "jpeg", "pdf", "gif", "ppt", "pptx"];
const initialMasterState = {
    BatchID: "",
    StatusID: "", // default
    StatusName: "",
    EventDate: null,
    VoucherDate: moment().format("YYYY-MM-DD"),
    ExchangeRate: null,
    SumTotalOAmount: 0,
    SumNewAmount: 0,
    DepositAmount: 0,
    RemainAmount: 0,
    ObjectTypeID: "KH", // default
    CurrencyID: "VND", // default
    VoucherTypeID: "",
    VoucherNo: "",
    EmployeeID: "",
    ObjectID: "",
    ObjectName: "",
    Description: "",
    EventID: "",
    ContractNo: "",
    Location: "",
    BanquetName: "",
    HourFrom: "",
    HourTo: ""
};

const WA3F2041 = (props) => {
    const UserID = Config.profile?.UserID || "";
    const Language = Config.language || "84";
    const DivisionID = Config.getLocalStorage("DIVISIONBEM") || _.get(Config.profile, "DivisionID", "");
    const dataCboReport = useSelector(state => state?.general?.dataCboReport ?? []);
    const dataCboCurrency = useSelector(state => state?.general?.dataCboCurrency ?? []);
    const dataCboVoucherType = useSelector(state => state?.general?.dataCboVoucherType ?? []);
    const { spacing, colors } = useTheme();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { location } = props;

    //==========STATE==========
    const [Mode, setMode] = useState(_.get(location, "state.Mode", "add"));
    const [loading, _setLoading] = useState({
        formLoading: false,
        grid01Loading: false,
        cboCreateUserLoading: false,
        cboVoucherTypeLoading: false,
        cboCustomerLoading: false,
        cboCurrencyLoading: false,
    });

    const dataCboStatus = useMemo(() => {
        return [{
            StatusID: 1,
            StatusName: Config.lang("Chuyen_YCTC")
        },
        {
            StatusID: 100,
            StatusName: Config.lang("Huy")
        }]
    }, []);

    const { formLoading, cboCreateUserLoading, cboVoucherTypeLoading, cboCustomerLoading, cboCurrencyLoading, grid01Loading } = loading;
    const disabled = useMemo(() => {
        return formLoading || Mode === "view";
    }, [formLoading, Mode]);
    const isModeAdd = Mode === "add";
    const [openEventModal, setOpenEventModal] = useState(false);
    const [voucherNoStatus, setVoucherNoStatus] = useState(Mode === "view");
    const [tabIndex, setTabIndex] = useState(0);
    const [error, setError] = useState({});
    const [dataCboCreateUser, setDataCboCreateUser] = useState({
        rows: [],
        total: 0
    });
    const [dataCboCustomer, setDataCboCustomer] = useState({
        rows: [],
        total: 0
    });
    const [dataMaster, setDataMaster] = useState(initialMasterState);
    const [dataGrid01, setDataGrid01] = useState([]);
    const [dataGrid02, setDataGrid02] = useState([]);
    const [dataGrid03, setDataGrid03] = useState([]);
    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [dataCboPaymentMethod, setDataCboPaymentMethod] = useState([]);
    const [dataCboBank, setDataCboBank] = useState([]);

    //=======REF==========
    const cbColumnPopRef = useRef(null);
    const selectedRowIndx = useRef(null);
    const attRef = useRef(null);
    const SumTotalOAmountRef1 = useRef(0);
    const SumTotalOAmountRef2 = useRef(0);
    const DepositAmountRef1 = useRef(0);
    const DepositAmountRef2 = useRef(0);
    const RemainAmountRef1 = useRef(0);
    const RemainAmountRef2 = useRef(0);
    const dataGrid01Ref = useRef(null);
    const dataGrid02Ref = useRef(null);
    const btnMoreRef = useRef(null);
    const voucherNoRef = useRef(null);
    const ExportReportModalRef = useRef(null);
    const cboCustomer = useRef({
        skip: 0,
        limit: 20,
        search: ""
    });
    const cboCreateUser = useRef({
        skip: 0,
        limit: 20,
        search: ""
    });
    const SumNewAmountRef1 = useRef(0);
    const SumNewAmountRef2 = useRef(0);
    const countOrderNum = useRef(1);
    const tempOrderNum = useRef(1);
    const objValueDefault = useRef({
        StatusID: dataCboStatus[0].StatusID,
        StatusName: dataCboStatus[0].StatusName
    });
    const refLoading = useRef({});
    const oldDataMaster = useRef({});
    const oldDataAttachments = useRef([]);
    const attachments = useRef([]);
    const deletedFile = useRef([]);
    const dataInfo = useRef({
        permission: _.get(location, "state.permission", 0),
        EventID: _.get(location, "state.EventID", dataMaster.EventID),
        VoucherNo: _.get(location, "state.VoucherNo", ""),
        VoucherID: _.get(location, "state.VoucherID", ""),
        BatchID: _.get(location, "state.BatchID", "")
    });
    const dataGrid03Ref = useRef(null);

    //=======Variable==========
    const StoreParams = [
        { id: "DivisionID", type: "VarChar", value: DivisionID },
        { id: "DataType", type: "VarChar", value: "ViewReport" },
        { id: "Language", type: "VarChar", value: Language },
        { id: "FormID", type: "VarChar", value: FormID },
        { id: "BatchID", type: "VarChar", value: _.get(dataInfo.current, "BatchID", "") },
        { id: "EventID", type: "VarChar", value: _.get(dataInfo.current, "EventID", "") },
        { id: "VoucherNo", type: "VarChar", value: _.get(dataInfo.current, "VoucherNo", "") },
        { id: "VoucherID", type: "VarChar", value: _.get(dataInfo.current, "VoucherID", "") },
    ];

    const getInfo = useCallback(() => {
        let status = true;
        const permission = _.get(location, "state.permission", 0);
        if (permission > 0) {
            status = true;
        } else {
            browserHistory.push(Config.getRootPath() + "WA3F2040");
            return null;
        }
        return status;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getInfo()) {
            getDataCboGeneral("getCboCurrency");
            getDataCboGeneral("getCboVoucherType");
            loadCboCreateUser(); // Cbo người lập
            loadCboCustomer(); // Cbo khách hàng
            loadCboPaymentMethod();
            loadCboBank();
            if (Mode !== "add") {
                loadForm();
                loadGrid3();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dataCboCurrency && isModeAdd) {
            objValueDefault.current = { ...objValueDefault.current, ExchangeRate: _.get(dataCboCurrency.find(item => item.CurrencyID === "VND"), "ExchangeRate", null) };
            setDefaultValueGeneral();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboCurrency, isModeAdd]);

    useEffect(() => {
        if (!_.isEmpty(dataMaster.EventID) && isModeAdd) {
            loadGridDetail(); // Load lưới chi tiết
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMaster.EventID]);

    useEffect(() => {
        if (Mode === "view" && ExportReportModalRef.current && ExportReportModalRef.current.hasOwnProperty("loadCboReports")) {
            ExportReportModalRef.current.loadCboReports(); // Load
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Mode]);

    useEffect(() => {
        if (dataMaster.Auto === 1) loadVoucherNo(dataMaster.VoucherTypeID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMaster.VoucherTypeID]);

    const setCboLoading = (cboName = "", status) => {
        if (_.isEmpty(cboName) || !_.isBoolean(status)) return;
        switch (cboName) {
            case "getCboVoucherType":
                setLoading({ cboVoucherTypeLoading: status });
                break;
            case "getCboCurrency":
                setLoading({ cboCurrencyLoading: status });
                break;
            default:
                break;
        }
    };

    const getDataCboGeneral = (cboName = "") => {
        if (_.isEmpty(cboName)) return;
        setCboLoading(cboName, true);
        dispatch(generalActions[cboName]((error) => {
            setCboLoading(cboName, false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        }));
    };

    const loadVoucherNo = (VoucherTypeID = "") => {
        if (_.isEmpty(VoucherTypeID)) return;
        const params = {
            DivisionID,
            VoucherTypeID
        };
        setLoading({ cboVoucherTypeLoading: true });
        dispatch(generalActions.loadVoucherNo(params, (error, data) => {
            setLoading({ cboVoucherTypeLoading: false });
            if (error) {
                setDataMaster({ ...dataMaster, VoucherNo: "" });
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setDataMaster({ ...dataMaster, VoucherNo: data?.VoucherNo });
            }
        }));
    };

    const setLoading = (obj) => {
        refLoading.current = { ...refLoading.current, ...obj };
        _setLoading(refLoading.current);
    };

    const setDefaultValueGeneral = () => { // Dùng để set default value
        if (!_.isEmpty(objValueDefault.current)) {
            setDataMaster({ ...dataMaster, ...objValueDefault.current });
        }
    };

    const loadCboCustomer = () => {
        const { ObjectID } = dataMaster;
        const { skip, limit, search } = cboCustomer.current;
        const param = {
            FormID,
            ObjectID,
            BookingID: "",
            skip,
            limit,
            search
        };
        setLoading({ cboCustomerLoading: true });
        dispatch(generalActions.getCboCustomer(param, (error, data) => {
            setLoading({ cboCustomerLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                let rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                if (!_.isEmpty(rows)) {
                    rows = rows.map(item => {
                        const line = _.isEmpty(item.ObjectID) || _.isEmpty(item.ObjectName) ? "" : "-";
                        return ({ ...item, ObjectCustomName: `${item.ObjectID} ${line} ${item.ObjectName}` })
                    });
                }
                setDataCboCustomer({
                    rows: skip === 0 ? rows : dataCboCustomer.rows.concat(rows),
                    total
                });
            }
        }));
    };

    const loadForm = () => {
        const { VoucherNo, VoucherID, BatchID } = dataInfo.current;
        const param = {
            FormID,
            DataType: "ViewWA3F2041",
            BatchID,
            VoucherNo,
            VoucherID,
        };
        setLoading({ formLoading: true });
        dispatch(WA3F2041Actions.loadForm(param, (error, data) => {
            setLoading({ formLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                oldDataMaster.current = initialMasterState;
                setDataMaster(initialMasterState);
                oldDataAttachments.current = [];
                setDataOldAttachments([]);
                setDataGrid01([]);
                setDataGrid02([]);
                return false;
            } else if (data) {
                const dataMaster = data?.Master || {};
                const dataDetail = data?.Details || [];
                const attachment = data?.Attachment || [];
                if (dataMaster) {
                    oldDataMaster.current = { ...dataMaster };
                    setDataMaster(dataMaster);
                }
                if (dataDetail) {
                    const dataGrid01 = dataDetail.filter(item => item.TypeID === 0);
                    const dataGrid02 = dataDetail.filter(item => item.TypeID === 1);
                    setDataGrid01(dataGrid01);
                    setDataGrid02(dataGrid02);
                }
                if (attachment) {
                    oldDataAttachments.current = [...attachment];
                    setDataOldAttachments(attachment);
                }
            }
        }));
    };

    const loadGrid3 = () => {
        const { VoucherNo, VoucherID } = dataInfo.current;
        const param = {
            FormID: "WA3F2041",
            DataType: "ViewPaymentMethodID",
            VoucherNo,
            VoucherID,
        };
        dispatch(WA3F2041Actions.loadGrid3(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            const dtGrid03 = data?.map((d, idx) => ({...d, OrderNum: idx + 1})) || [];
            tempOrderNum.current = data.length;
            setDataGrid03(dtGrid03)
        }));
    };

    const loadCboCreateUser = () => {
        const { ObjectID } = dataInfo.current;
        const { skip, limit, search } = cboCreateUser.current;
        const param = {
            FormID,
            ObjectID,
            ObjectTypeID: "",
            BookingID: "",
            skip,
            limit,
            search
        };
        setLoading({ cboCreateUserLoading: true });
        dispatch(generalActions.getCboCreateUser(param, (error, data) => {
            setLoading({ cboCreateUserLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                if (isModeAdd) {
                    const dataDefaultObj = rows.find(item => item.UserID === UserID);
                    if (dataDefaultObj) {
                        objValueDefault.current = { ...objValueDefault.current, EmployeeID: dataDefaultObj?.EmployeeID || "", EmployeeName: dataDefaultObj?.EmployeeName || "" };
                        setDefaultValueGeneral();
                    }
                }
                setDataCboCreateUser({
                    rows: skip === 0 ? rows : dataCboCreateUser.rows.concat(rows),
                    total
                });
            }
        }));
    };

    const loadCboPaymentMethod = () => {
        const params = {
            FormID: "WA3F2022",
            DataType: "PaymentMethodID",
            ObjectTypeID: "",
            ObjectID: "",
            BookingID: "",
            BookingItemID: "",
            strSearch: "",
            BatchID: "",
            EventID: ""
        };
        dispatch(WA3F2041Actions.loadCboDropDown(params, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            setDataCboPaymentMethod(data || [])
        }));
    };

    const loadCboBank = () => {
        const params = {
            FormID: "WA3F2022",
            DataType: "BankID",
            ObjectTypeID: "",
            ObjectID: "",
            BookingID: "",
            BookingItemID: "",
            strSearch: "",
            BatchID: "",
            EventID: ""
        };
        dispatch(WA3F2041Actions.loadCboDropDown(params, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            setDataCboBank(data || [])
        }));
    };

    const getArrStrValue = (name = "") => {
        if (!Object.hasOwn(dataMaster, name)) return "";
        return _.isArray(dataMaster[name]) ? `('${dataMaster[name].join("', '")}')` : dataMaster[name];
    };

    const loadGridDetail = () => {
        const param = {
            FormID,
            DataType: "LoadGridDetail",
            BatchID: getArrStrValue('BatchID'),
            EventID: getArrStrValue('EventID')
        };
        setLoading({ grid01Loading: true });
        dispatch(WA3F2041Actions.loadGridDetail(param, (error, data) => {
            setLoading({ grid01Loading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                setDataGrid01([]);
                return false;
            } else if (data) {
                const detail = data?.Detail || [];
                const DepositAmount = data?.DepositAmount || 0;
                const { SumNewAmount } = dataMaster;
                const SumTotalOAmount = detail.reduce((currentValue, sum) => currentValue + sum.OTotalAmount, 0);
                const RemainAmount = SumTotalOAmount + SumNewAmount - DepositAmount;
                setDataGrid01(detail);
                setDataMaster({ ...dataMaster, SumTotalOAmount, DepositAmount, RemainAmount });
            }
        }));
    };

    const onChange = (fieldName, e) => {
        if (!e || _.isEmpty(fieldName)) return;
        let value = _.isObject(e) ? _.get(e, "data.value", _.get(e, "value", _.get(e, "target.value", ""))) : e;
        let objectValuePlus = {};
        switch (fieldName) {
            //DropDown
            case "VoucherTypeID":
            case "EmployeeID":
            case "ObjectID":
            case "CurrencyID":
                const { Auto = null, ExchangeRate = null } = _.get(e, "data", {});
                const positionID = fieldName.indexOf("ID");
                const standardDDName = positionID > 0 ? fieldName.slice(0, positionID) : fieldName;
                const DDName = standardDDName + "Name";
                const valueDDName = _.get(e.data, [DDName], _.get(e.data, "ObjectName", ""));
                objectValuePlus = { ...objectValuePlus, [DDName]: valueDDName };
                if (fieldName === "VoucherTypeID") {
                    if (Auto === 0 || _.isEmpty(value)) { // Tự Nhập hoặc clear Data
                        setVoucherNoStatus(_.isEmpty(value));
                        objectValuePlus = { ...objectValuePlus, Auto, VoucherNo: "" };
                        if (voucherNoRef.current) voucherNoRef.current.value = "";
                    } else if (Auto === 1) {
                        setVoucherNoStatus(true);
                        objectValuePlus = { ...objectValuePlus, Auto };
                    }
                }
                else if (fieldName === "CurrencyID") {
                    objectValuePlus = { ...objectValuePlus, ExchangeRate };
                }
                break;
            default:
                break;
        }
        objectValuePlus = { ...objectValuePlus, [fieldName]: value };
        if (dataInfo.current && Object.keys(dataInfo.current).includes(fieldName)) {
            dataInfo.current[fieldName] = value;
        }
        setDataMaster({
            ...dataMaster,
            ...objectValuePlus
        });
        if (error.hasOwnProperty(fieldName) && !Config.isEmpty(value, true)) {
            if (fieldName === "VoucherTypeID" && _.get(e, "data.Auto", null) === 1) {
                setError(_.omit(error, [fieldName, "VoucherNo"]));
            } else {
                setError(_.omit(error, fieldName));
            }
        }
    };

    const onChangeAttachments = (e) => {
        attachments.current = e.allNewAttached ? e.allNewAttached : [];
        if (e.removedAttached && e.removedAttached.length > 0) {
            deletedFile.current = [...e.removedAttached];
            const _arrRemove = deletedFile.current.map(d => d.AttachmentID);
            setDataOldAttachments(
                dataOldAttachments.filter(att => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            );
        }
    };

    const setStateErrorText = (objValue) => {
        setError({ ...error, ...objValue });
        return Object.keys(objValue).length !== 0;
    };

    const checkValidateMaster = (arrName) => {
        if (_.isEmpty(arrName)) return;
        return arrName.map(item => {
            return {
                name: item,
                rules: ["isRequired"],
                value: dataMaster[item]
            }
        });
    };

    const onSaveHistory = async (VoucherID = "", dataAttachmentCDN = []) => {
        let action = 0; // add hoac copy
        if (Mode === "edit") action = 1;
        const TransactionName = Config.lang("Thu_tien_tiec_su_kien");
        const captions = {
            VoucherNo: "So_phieu",
            EmployeeName: "Nguoi_lap",
            ObjectID: "Ma_khach_hang",
            ObjectName: "Ten_khach_hang",
            EventID: "Ma_su_kien",
            ContractNo: "So_hop_dong",
            BanquetName: "Sanh",
            SumTotalOAmount: `${Config.lang("Tong_tien_thuc_thu")} (1)`,
            SumNewAmount: `${Config.lang("Tong_phat_sinh_sau_tiec")} (2)`,
            DepositAmount: `${Config.lang("Da_dat_coc")} (3)`,
            RemainAmount: `${Config.lang("Con_lai")} (1)+(2)-(3)`,
        };
        const captionAttachment = {
            URL: "URL",
            FileName: "Ten_dinh_kem",
        };
        const newData = { ...dataMaster };
        const oldData = { ...oldDataMaster.current };
        const options = {
            data: newData,
            dataCompare: oldData,
            captions,
            action,
            ModuleID: "DA3",
            TransactionID: FormID,
            TransID: VoucherID ? VoucherID : _.get(dataInfo.current, "VoucherID ", ""),
            TransactionName,
        };
        const history = new History(options);
        const attachment = [...dataAttachmentCDN, ...dataOldAttachments]; // Đính kèm mới
        const dataAttachmentOld = oldDataAttachments.current; // Đính kèm cũ
        history.createDetailHistory("Dinh_kem", attachment, dataAttachmentOld, captionAttachment, "URL", null, options);
        // console.log("===========HIS=========>", history.get());
        return history.get()?.length > 0 ? await history.save() : true;
    };

    const _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    const _getAttachments = (file) => {
        const dataFile = _.get(file, "data.paths", []);
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: _.get(att, "url", ""),
                FileName: _.get(att, "fileName", ""),
                FileSize: _.get(att, "fileSize", ""),
                FileExt: att.fileName ? att.fileName.split(".").pop() : "",
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }
        return arrAttachment;
    };

    const onSave = async (dataDetail01, dataDetail02, dataDetail03) => {
        const dataResUploadFile = !_.isEmpty(attachments.current) ? await _uploadFile(attachments.current, true) : {};
        const attachment = JSON.stringify(_getAttachments(dataResUploadFile));
        const param = {
            ...dataMaster, // Master
            EventID: getArrStrValue('EventID'),
            ContractNo: getArrStrValue('ContractNo'),
            BatchID: getArrStrValue('BatchID'),
            detail: JSON.stringify(dataDetail01),
            derivative: JSON.stringify(dataDetail02),
            payments: JSON.stringify(dataDetail03),
            attachment,
        };
        setLoading({ formLoading: true });
        dispatch(WA3F2041Actions.onSave(param, async (error, data) => {
            setLoading({ formLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                if (data?.Status === 0) {
                    const VoucherID = data?.VoucherID || dataMaster?.VoucherID || "";
                    const VoucherNo = data?.VoucherNo || dataMaster?.VoucherNo || "";
                    let dataAttachmentCDN = _.get(dataResUploadFile, "data.paths", []);
                    if (!_.isEmpty(dataAttachmentCDN)) {
                        dataAttachmentCDN = dataAttachmentCDN.map(item => ({
                            ...item,
                            URL: _.get(item, "url", ""),
                            FileName: _.get(item, "fileName", "")
                        }));
                    }
                    await onSaveHistory(VoucherID, dataAttachmentCDN); // Lưu Lịch SỬ
                    Config.notify.show("success", _.get(data, "Message", "") || Config.lang("Luu_thanh_cong"), 2000);
                    if (Mode === "add") {
                        dataInfo.current.VoucherID = VoucherID;
                        dataInfo.current.VoucherNo = VoucherNo;
                        oldDataMaster.current = dataMaster; // Lấy lịch sử cũ Khi thêm mới xong
                        setDataMaster({ ...dataMaster, VoucherID, VoucherNo });
                    }
                    setMode("view"); // Chuyển về View
                } else {
                    Config.popup.show("INFO", _.get(data, "Message", "") || Config.lang("Loi_chua_xac_dinh"));
                    return false;
                }
            }
        }));
    };

    const convertStringToNumber = (value) => {
        const stripped = value.replace(/,/g, "");
        return parseFloat(stripped) || 0;
    }

    const onCheckSave = async () => {
        let requiredField = ["VoucherTypeID", "VoucherNo", "EmployeeID", "VoucherDate", "ObjectID", "CurrencyID", "EventID"];
        const resultValidate = checkValidateMaster(requiredField);
        const validateForm = MForm.formValidation(resultValidate);
        await dataGrid01Ref.current.instance.saveEditData();
        await dataGrid02Ref.current.instance.saveEditData();
        const dataDetail01 = dataGrid01Ref.current.instance.option("dataSource");
        const dataDetail02 = dataGrid02Ref.current.instance.option("dataSource");
        const dataDetail03 = dataGrid03Ref.current.instance.option("dataSource");
        const checkDetail02Require = !_.isEmpty(dataDetail02) ? dataDetail02.some(item => _.isEmpty(item.InventoryID) || _.isEmpty(item.UnitID) || Config.isEmpty(item.Quantity, true) || Config.isEmpty(item.UnitPrice, true)) : true;
        const checkDetail03Require = !_.isEmpty(dataDetail03) ? dataDetail03.some(item => _.isEmpty(item.PaymentMethodID) || Config.isEmpty(item.OAmount, true)) : true;
        const totalAmount = dataDetail03.reduce((total, currentValue) => total + currentValue.OAmount, 0);
        const remainAmount = RemainAmountRef2.current ? convertStringToNumber(RemainAmountRef2.current.value) : 0;
        switch (true) {
            case Object.keys(validateForm).length > 0:
                setStateErrorText(validateForm);
                handleChangeTab(0);
                break;
            case _.isEmpty(dataDetail02):
                if(checkDetail03Require) {
                    Config.popup.show("INFO", Config.lang(`${Config.lang("Vui_long_nhap_day_du_thong_tin_tren_luoi")} ${Config.lang("Thong_tin_thanh_toan").toLowerCase()}`));
                    handleChangeTab(3);
                    return false;
                }
                if(totalAmount !== remainAmount) {
                    Config.popup.show("INFO", Config.lang("So_tien_thanh_toan_khong_bang_tong_so_tien_can_thanh_toan"));
                    handleChangeTab(3);
                    return false;
                }
                Config.popup.show("YES_NO", _.get(error, "message", `${Config.lang(`Luoi_%${Config.lang("Phat_sinh").toLowerCase()}%_chua_nhap_lieu_ban_co_muon_luu_khong?`)}`), () => {
                    onSave(dataDetail01, dataDetail02, dataDetail03);
                }, () => { // No
                    handleChangeTab(2);
                });
                break;
            case checkDetail02Require:
                Config.popup.show("INFO", _.get(error, "message", `${Config.lang("Vui_long_nhap_day_du_thong_tin_tren_luoi")} ${Config.lang("Phat_sinh").toLowerCase()}`));
                handleChangeTab(2);
                break;
            case totalAmount !== remainAmount:
                Config.popup.show("INFO", Config.lang("So_tien_thanh_toan_khong_bang_tong_so_tien_can_thanh_toan"));
                handleChangeTab(3);
                break;
            case checkDetail03Require:
                Config.popup.show("INFO", Config.lang(`${Config.lang("Vui_long_nhap_day_du_thong_tin_tren_luoi")} ${Config.lang("Thong_tin_thanh_toan").toLowerCase()}`));
                handleChangeTab(3);
                break;
            case dataMaster?.RemainAmount < 0:
                Config.popup.show("INFO", Config.lang(`%${Config.lang("Thanh_tien")}%_dang_nho_hon_%${0}%_,ban_vui_long_kiem_tra_lai!`));
                handleChangeTab(1);
                break;
            default:
                onSave(dataDetail01, dataDetail02, dataDetail03);
                break;
        }
    };

    const onPrint = useCallback(() => {
        if (_.isArray(dataCboReport)) {
            if (dataCboReport.length === 0) {
                Config.popup.show("INFO", Config.lang("Ban_phai_thiet_lap_mau_bao_cao_truoc_khi_xuat_du_lieu"));
            } else if (dataCboReport.length === 1) { // 1 Mẫu
                if (ExportReportModalRef.current.hasOwnProperty("onExport")) ExportReportModalRef.current.onExport();
            } else { // Nhiều mẫu
                if (ExportReportModalRef.current && ExportReportModalRef.current.hasOwnProperty("onClickShow")) {
                    ExportReportModalRef.current.onClickShow();
                }
            }
        }
        if (btnMoreRef.current) btnMoreRef.current.close();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboReport, ExportReportModalRef.current]);

    const handleChangeTab = (index) => {
        if (_.isNumber(index)) setTabIndex(index);
    };

    const onBack = () => {
        Config.popup.show("YES_NO", Config.lang("Ban_co_muon_roi_khoi_trang_nay_khong?"), () => {
            browserHistory.goBack();
        });
    };

    const refreshGrid = (gridIndex) => {
        switch (gridIndex) {
            case 1:
                if (dataGrid01Ref.current) dataGrid01Ref.current.instance.refresh();
                break;
            case 2:
                if (dataGrid02Ref.current) dataGrid02Ref.current.instance.refresh();
                break;
            case 3:
                if (dataGrid03Ref.current) dataGrid03Ref.current.instance.refresh();
                break;
            default:
                break;
        }
    };

    const sortByOrder = () => {
        dataGrid02.forEach((item, idx) => item.OrderNum = idx + 1);
        countOrderNum.current = dataGrid02.length + 1;
    };

    const sortGrid03 = () => {
        dataGrid03.forEach((item, idx) => item.OrderNum = idx + 1);
        tempOrderNum.current = dataGrid03.length + 1;
    };

    const saveEditDataGrid = async (gridIndex = null) => {
        switch (gridIndex) {
            case 1:
                if (dataGrid01Ref.current) await dataGrid01Ref.current.instance.saveEditData();
                break;
            case 2:
                if (dataGrid02Ref.current) await dataGrid02Ref.current.instance.saveEditData();
                break;
            case 3:
                if (dataGrid03Ref.current) await dataGrid03Ref.current.instance.saveEditData();
                break;
            default:
                break;
        }
    };

    const onDelete = (e) => {
        if (!e) return;
        saveEditDataGrid(2);
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            e.component.deleteRow(e.rowIndex);
            refreshGrid();// Refesh lại grid để lấy đúng data
            sortByOrder();// Sắp xếp lại STT
        });
    };

    const onDeleteGrid03 = (e) => {
        if (!e) return;
        saveEditDataGrid(3);
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            e.component.deleteRow(e.rowIndex);
            refreshGrid(3);// Refesh lại grid để lấy đúng data
            sortGrid03();// Sắp xếp lại STT
        });
    };

    const renderActionGrid = (e) => {
        return (
            <GridActionBar >
                <ButtonIcon
                    circular
                    disabled={e?.row?.isNewRow || disabled}
                    name={"Delete"}
                    size={"medium"}
                    viewType={"text"}
                    onClick={() => onDelete(e)} />
            </GridActionBar>
        );
    };

    const renderNumberFormat = (e) => {
        if (!e) return;
        const { column: { dataField = "" }, row: { data = {} } } = e;
        const decimalValue = data[dataField];
        if (_.isUndefined(decimalValue)) return;
        const { column, row } = e;
        const cellElement = e.component.getCellElement(row.rowIndex, column.dataField);
        const requiredField = ["Quantity", "UnitPrice"];
        if (cellElement && Config.isEmpty(decimalValue, true) && requiredField.includes(dataField)) { //asd qwr wr wr wr wr wr
            if (!(row?.isNewRow === true)) {
                if (_.isEmpty(_.get(dataGrid02[e.rowIndex], dataField, ""))) {
                    cellElement.style.backgroundColor = `${Config.hexToRGBA(colors.danger, 0.3)}`;
                }
            }
        }
        return <NumberInput
            nonStyle
            readOnly
            decimalDigit={4}
            thousandSeparator={","}
            value={decimalValue}
            className={classes.moneyInputColumn}
        />
    };

    const numberFormat = (number, limitNumber = 0) => {
        const stringNum = _.toString(number);
        let digitNum = limitNumber;
        const index = stringNum.indexOf(".");
        if (index > -1) {
            const digitLength = stringNum.slice(index + 1, stringNum.length);
            if (digitLength > 4) digitNum = digitLength.length;
        }
        const formatter = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: digitNum,
            maximumFractionDigits: digitNum
        });
        const _number = formatter.format(String(number));
        return !_number || _.isNaN(_number) ? 0 : _number;
    };

    const onInsertRow = () => {
        if (dataGrid02Ref.current) setTimeout(() => dataGrid02Ref.current.instance.addRow(), 0);
    };

    const onInsertRowGrid3 = () => {
        if (dataGrid03Ref.current) setTimeout(() => dataGrid03Ref.current.instance.addRow(), 0);
    };

    const customText = () => {
        return Config.lang("Tong_cong").toUpperCase();
    };

    const renderGrid01 = useMemo(() => {
        return <GridContainer
            reference={ref => dataGrid01Ref.current = ref}
            height={510}
            itemPerPage={10}
            typePaging={"normal"}
            pagerFullScreen={false}
            style={{ border: 'none' }}
            loading={grid01Loading}
            editing={{
                mode: "cell",
                refreshMode: "reshape",
                allowUpdating: !disabled,
                texts: {
                    confirmDeleteMessage: "",
                },
                startEditAction: "click",
            }}
            dataSource={dataGrid01}
        >
            <Column
                width={60}
                allowEditing={false}
                allowFiltering={false}
                alignment={"center"}
                dataField={"OrderNum"}
                caption={Config.lang("STT")}
            />
            <Column
                width={160}
                allowEditing={false}
                caption={Config.lang("Ma_hang")}
                dataField={"InventoryID"}
            />
            <Column
                width={240}
                allowEditing={false}
                caption={Config.lang("Ten_hang")}
                dataField={"InventoryName"}
            />
            <Column
                width={140}
                allowEditing={false}
                caption={Config.lang("Don_vi_tinh")}
                dataField={"UnitID"}
            />
            <Column
                width={90}
                allowEditing={false}
                alignment={"right"}
                dataType={"number"}
                dataField={"Quantity"}
                caption={Config.lang("So_luong")}
            />
            <Column
                width={160}
                alignment={"right"}
                allowEditing={false}
                caption={Config.lang("Don_gia")}
                dataField={"UnitPrice"}
                cellRender={renderNumberFormat}
            />
            <Column
                width={180}
                allowEditing={false}
                alignment={"right"}
                dataField={"OAmount"}
                caption={Config.lang("Thanh_tien")}
                cellRender={renderNumberFormat}
            />
            <Column
                width={180}
                visible={false}
                allowEditing={false}
                alignment={"right"}
                dataField={"CAmount"}
                caption={Config.lang("Thanh_tien_quy_doi")}
                cellRender={renderNumberFormat}
            />
            <Column
                width={180}
                allowEditing={false}
                precision={0}
                format={"percent"}
                dataType={"number"}
                alignment={"right"}
                dataField={"DiscountRate"}
                caption={Config.lang("Khuyen_mai")}
            />
            <Column
                width={180}
                allowEditing={false}
                alignment={"right"}
                dataField={"DiscountOAmount"}
                caption={Config.lang("Thanh_tien_khuyen_mai")}
                cellRender={renderNumberFormat}
            />
            <Column
                width={180}
                visible={false}
                allowEditing={false}
                alignment={"right"}
                dataField={"DiscountCAmount"}
                caption={Config.lang("Thanh_tien_khuyen_mai_quy_doi")}
                cellRender={renderNumberFormat}
            />
            <Column
                width={180}
                allowEditing={false}
                alignment={"right"}
                dataField={"OTotalAmount"}
                caption={Config.lang("Thuc_thu")}
                cellRender={renderNumberFormat}
            />
            <Column
                width={180}
                visible={false}
                allowEditing={false}
                alignment={"right"}
                dataField={"CTotalAmount"}
                caption={Config.lang("Thuc_thu_quy_doi")}
                cellRender={renderNumberFormat}
            />
            <Column
                width={220}
                dataField={"Notes"}
                caption={Config.lang("Ghi_chu")}
            />
            <Summary>
                <TotalItem
                    column={"InventoryName"}
                    customizeText={customText}
                />
                <TotalItem
                    column={"OAmount"}
                    displayFormat={"{0}"}
                    valueFormat={numberFormat}
                    summaryType={"sum"}
                />
                <TotalItem
                    column={"DiscountOAmount"}
                    displayFormat={"{0}"}
                    valueFormat={numberFormat}
                    summaryType={"sum"}
                />
                <TotalItem
                    column={"OTotalAmount"}
                    displayFormat={"{0}"}
                    valueFormat={numberFormat}
                    summaryType={"sum"}
                />
            </Summary>
        </GridContainer>;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid01, disabled, grid01Loading]);
    const numberFormatLimitDitgit = (number, limitNumber = 0) => {
        let value = number;
        const stringNum = _.toString(number);
        const index = stringNum.indexOf(".");
        if (index > -1) value = _.toNumber(stringNum.slice(0, index + 1 + limitNumber));
        return value;
    };

    const renderMaxWidthColum = (e, maxWidth = 280) => {
        const value = _.get(e, "value", "");
        return <div title={value} className={classes.ellipsis}
            {...(_.isNumber(maxWidth) ? { style: { maxWidth } } : {})} >{value}</div>
    };

    const getAdatcellData = (cellData) => {
        if (_.isEmpty(cellData)) return;
        let value = "";
        const dataField = _.get(cellData, "column.dataField", "");
        switch (dataField) {
            case "InventoryID":
                value = _.get(cellData, "data.InventoryID", "") || "";
                break;
            case "UnitID":
                value = _.get(cellData, "data.UnitID", "") || "";
                break;
            case "Quantity":
                value = _.get(cellData, "data.Quantity", null) ?? null;
                break;
            case "UnitPrice":
                value = _.get(cellData, "data.UnitPrice", null) ?? null;
                break;
            default:
                break;
        }
        return { value, dataField };
    };

    const ddPopupAction = (cellData) => {
        const { column, row } = cellData;
        const { value, dataField } = getAdatcellData(cellData);
        const cellElement = cellData.component.getCellElement(row.rowIndex, column.dataField);
        const requiredField = ["InventoryID", "UnitID", "Quantity", "UnitPrice"];
        if (cellElement && _.isEmpty(value) && requiredField.includes(dataField)) {
            if (!(row?.isNewRow === true)) {
                if (Config.isEmpty(_.get(dataGrid02[cellData.rowIndex], dataField, ""), true)) {
                    cellElement.style.backgroundColor = `${Config.hexToRGBA(colors.danger, 0.3)}`;
                }
            }
        }
        return (
            <div className={"display_row align-center"}>
                <div className={"pull-left mgr5"}>
                    {(dataField === "InventoryID" || dataField === "UnitID") &&
                        <ButtonIcon
                            circular
                            name={"Menu"}
                            size={"medium"}
                            viewType={"text"}
                            disabled={disabled}
                            onClick={() => {
                                saveEditDataGrid(2);
                                const dataSource = dataGrid02Ref.current.instance.option("dataSource");
                                const indexRow = dataSource.findIndex(obj => _.get(obj, "OrderNum", null) === _.get(cellData, "data.OrderNum", null));
                                selectedRowIndx.current = indexRow;
                                const positionID = dataField.indexOf("ID");
                                const standardDDName = positionID > 0 ? dataField.slice(0, positionID) : dataField;
                                const dataFieldName = standardDDName + "Name";
                                const dataRowKey = {
                                    dataField,
                                    dataFieldName,
                                    valueID: value
                                };
                                if (cbColumnPopRef.current?.hasOwnProperty("show")) cbColumnPopRef.current.show(true, dataRowKey); // Đóng Action nếu có mở
                            }} />}
                </div>
                <div className={`${classes.ellipsis} pull-left textCellHidden`} title={value}>{value}</div>
            </div>
        );
    };

    const forcusColumn = (rowIdx) => { //focus vào ô trống ghi chọn xong pop column
        const dataSource = dataGrid02Ref.current.instance.option("dataSource");
        const row = dataSource[rowIdx];
        const allColumn = dataGrid02Ref.current.instance.getVisibleColumns();
        const columnNameEdit = allColumn?.find(item => item?.allowEditing && Config.isEmpty(_.get(row, item?.dataField, ""), true)); // tìm cloumn edit gần nhất
        if (columnNameEdit) {
            const columnEditIndex = allColumn.findIndex(item => item.dataField === columnNameEdit.dataField);
            if (columnEditIndex > -1) dataGrid02Ref.current.instance.editCell(rowIdx, columnEditIndex);
        }
    };

    const rowInitOpenDD = () => { // Thêm dòng mở mở Popup chọn Menu luôn
        const data = {
            dataField: "InventoryID",
            dataFieldName: "InventoryName",
            isAddNew: true
        };
        selectedRowIndx.current = dataGrid02.length;
        if (cbColumnPopRef.current?.hasOwnProperty("show")) cbColumnPopRef.current.show(true, data); // Có DD Column mở lên luôn
    };

    const renderGrid02 = useMemo(() => {
        return <GridContainer
            reference={ref => dataGrid02Ref.current = ref}
            height={510}
            itemPerPage={10}
            typePaging={"normal"}
            pagerFullScreen={false}
            style={{ border: 'none' }}
            editing={{
                mode: "cell",
                refreshMode: "reshape",
                allowUpdating: !disabled,
                texts: {
                    confirmDeleteMessage: "",
                },
                startEditAction: "click",
            }}
            onContentReady={(e) => {
                const SumNewAmount = _.toNumber(e.component.getTotalSummaryValue("OAmount"));
                if (SumNewAmount !== SumNewAmountRef1.current && !_.isEmpty(dataGrid02)) {
                    const SumTotalOAmount = _.toNumber(SumTotalOAmountRef1.current.value.replaceAll(",", ""));
                    const DepositAmount = _.toNumber(DepositAmountRef1.current.value.replaceAll(",", ""));
                    const RemainAmount = SumTotalOAmount + SumNewAmount - DepositAmount;
                    dataMaster.SumNewAmount = SumNewAmount;
                    dataMaster.RemainAmount = RemainAmount;
                    SumNewAmountRef1.current.value = SumNewAmountRef2.current.value = numberFormat(SumNewAmount);
                    RemainAmountRef1.current.value = RemainAmountRef2.current.value = numberFormat(RemainAmount);
                }
            }}
            onInitNewRow={(eMethod) => {
                eMethod.data.InventoryID = "";
                eMethod.data.InventoryName = "";
                eMethod.data.UnitID = null;
                eMethod.data.Quantity = null;
                eMethod.data.OAmount = null;
                eMethod.data.UnitPrice = null;
                eMethod.data.Discount = null;
                eMethod.data.Notes = "";
                rowInitOpenDD();
            }}
            onRowInserted={(e) => {
                const { Quantity = null, UnitPrice = null, Discount = null } = _.get(e, "data", {});
                const { ExchangeRate = null } = dataMaster;
                const oAmountVal = (Quantity * UnitPrice) - Discount;
                if (UnitPrice === null) e.data["UnitPrice"] = 0; // default
                e.data["OrderNum"] = countOrderNum.current++;
                e.data["OAmount"] = numberFormatLimitDitgit(oAmountVal, 4);
                e.data["CAmount"] = Math.round(oAmountVal * ExchangeRate);
                refreshGrid(2);
            }}
            dataSource={dataGrid02}>
            <Column
                fixed={true}
                allowEditing={false}
                alignment={"right"}
                fixedPosition={"right"}
                caption={Config.lang("Hanh_dong")}
                visible={Config.isMobile && !disabled}
                cellRender={renderActionGrid}
            />
            <Column
                width={60}
                allowEditing={false}
                allowFiltering={false}
                alignment={"center"}
                dataField={"OrderNum"}
                caption={Config.lang("STT")}
            />
            <Column
                width={320}
                allowEditing={false}
                caption={Config.lang("Ma_hang")}
                dataField={"InventoryID"}
                cellRender={ddPopupAction}
                headerCellRender={() =>
                (<> {Config.lang("Ma_hang")}
                    <span style={{ color: 'red' }}>&nbsp;*</span>
                </>)}
            />
            <Column
                width={260}
                allowEditing={false}
                caption={Config.lang("Ten_hang")}
                dataField={"InventoryName"}
            />
            <Column
                width={140}
                allowEditing={false}
                dataField={"UnitID"}
                cellRender={ddPopupAction}
                headerCellRender={() =>
                (<> {Config.lang("Don_vi_tinh")}
                    <span style={{ color: 'red' }}>&nbsp;*</span>
                </>)}
            />
            <Column
                width={90}
                alignment={"right"}
                dataType={"number"}
                dataField={"Quantity"}
                cellRender={ddPopupAction}
                headerCellRender={() =>
                (<> {Config.lang("So_luong")}
                    <span style={{ color: 'red' }}>&nbsp;*</span>
                </>)}

                editCellRender={(e) => (
                    <NumberInput
                        nonStyle
                        disabledNegative
                        value={e.value}
                        className={classes.moneyInputColumn}
                        onChange={(c) => e.setValue(c.value)}
                    />
                )} />
            <Column
                width={160}
                alignment={"right"}
                dataField={"UnitPrice"}
                cellRender={renderNumberFormat}
                headerCellRender={() =>
                (<> {Config.lang("Don_gia")}
                    <span style={{ color: 'red' }}>&nbsp;*</span>
                </>)}
                editCellRender={(e) => (
                    <NumberInput
                        nonStyle
                        value={e.value}
                        decimalDigit={4}
                        thousandSeparator={","}
                        className={classes.moneyInputColumn}
                        onChange={(c) =>
                            e.setValue(c.value)}
                    />
                )} />
            <Column
                width={160}
                alignment={"right"}
                dataField={"Discount"}
                cellRender={renderNumberFormat}
                caption={Config.lang("Giam_gia")}
                editCellRender={(e) => (
                    <NumberInput
                        nonStyle
                        value={e.value}
                        decimalDigit={4}
                        thousandSeparator={","}
                        className={classes.moneyInputColumn}
                        onChange={(c) =>
                            e.setValue(c.value)}
                    />
                )} />
            <Column
                width={180}
                allowEditing={false}
                alignment={"right"}
                dataField={"OAmount"}
                caption={Config.lang("Thanh_tien")}
                calculateCellValue={(e) => {
                    const { Quantity = null, UnitPrice = null, Discount = null } = e || {};
                    const { ExchangeRate = null } = dataMaster;
                    const oAmountValue = (Quantity * UnitPrice) - Discount;
                    e.CAmount = Math.round(oAmountValue * ExchangeRate);
                    return e.OAmount = oAmountValue;
                }}
                cellRender={renderNumberFormat}
            />
            <Column
                visible={false}
                allowEditing={false}
                alignment={"right"}
                dataField={"CAmount"}
                caption={Config.lang("Thanh_tien_quy_doi")}
                cellRender={renderNumberFormat}
            />
            <Column
                dataField={"Notes"}
                caption={Config.lang("Ghi_chu")}
                cellRender={(e) => renderMaxWidthColum(e, 260)}
            />
            <Column
                width={40}
                fixed={true}
                allowEditing={false}
                alignment={"right"}
                fixedPosition={"right"}
                visible={!Config.isMobile && !disabled}
                cellRender={renderActionGrid}
            />
            <Summary>
                <TotalItem
                    column={"InventoryName"}
                    customizeText={customText}
                />
                <TotalItem
                    column={"OAmount"}
                    displayFormat={"{0}"}
                    valueFormat={numberFormat}
                    summaryType={"sum"}
                />
            </Summary>
        </GridContainer>;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid02, disabled, dataMaster.ExchangeRate]);

    const renderTotalBoeard = (num) => {
        return (
            <div style={{ marginTop: spacing(10) }}>
                <Row>
                    <Col xs={12} sm={6} md={4} lg={3}>
                        <NumberInput
                            readOnly
                            decimalDigit={4}
                            thousandSeparator={","}
                            inputRef={num === 1 ? SumTotalOAmountRef1 : SumTotalOAmountRef2}
                            value={dataMaster?.SumTotalOAmount}
                            label={`${Config.lang("Tong_tien_thuc_thu")} (1)`}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3}>
                        <NumberInput
                            readOnly
                            decimalDigit={4}
                            thousandSeparator={","}
                            inputRef={num === 1 ? SumNewAmountRef1 : SumNewAmountRef2}
                            value={dataMaster?.SumNewAmount}
                            label={`${Config.lang("Tong_phat_sinh_sau_tiec")} (2)`}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3}>
                        <NumberInput
                            readOnly
                            decimalDigit={4}
                            thousandSeparator={","}
                            inputRef={num === 1 ? DepositAmountRef1 : DepositAmountRef2}
                            value={dataMaster?.DepositAmount}
                            label={`${Config.lang("Da_dat_coc")} (3)`}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3}>
                        <NumberInput
                            readOnly
                            decimalDigit={4}
                            thousandSeparator={","}
                            inputRef={num === 1 ? RemainAmountRef1 : RemainAmountRef2}
                            value={dataMaster?.RemainAmount}
                            label={`${Config.lang("Con_lai")} (1)+(2)-(3)`}
                        />
                    </Col>
                </Row>
            </div>
        )
    };

    const onOpenModal = (modalName, status = false, data = {}) => {
        if (!_.isBoolean(status) || _.isEmpty(modalName)) return;
        switch (modalName) {
            case "EventID":
                if (!_.isEmpty(dataMaster.EventID) && status === true) {
                    Config.popup.show("YES_NO", Config.lang("Thong_tin_luoi_chi_tiet_se_bi_xoa_ban_co_muon_tiep_tuc"), () => {
                        setOpenEventModal(status); // Bấm Yes
                    });
                } else {
                    setOpenEventModal(status);
                }
                break;
            default:
                break;
        }
        if (!_.isEmpty(data)) {
            if (modalName === "EventID") {
                setDataMaster({
                    ...dataMaster,
                    ...data,
                    Description: data?.BookingNo || ""
                });
                if (data && error[modalName]) setError(_.omit(error, modalName));
            }
        }
    };

    const getCurrentDataSource = () => {
        let dataSource = [];
        switch (tabIndex) {
            case 1:
                if (dataGrid01Ref.current) dataSource = dataGrid01Ref.current.instance.option("dataSource");
                break;
            case 2:
                if (dataGrid02Ref.current) dataSource = dataGrid02Ref.current.instance.option("dataSource");
                break;
            default:
                break;
        }
        return dataSource;
    };

    const onOpenComboModal = async (status, data = {}) => {
        if (!_.isBoolean(status)) return;
        const rowIdx = selectedRowIndx.current;
        if (!_.isEmpty(data)) {
            if (data.isAddNew) await saveEditDataGrid(2); // Thêm mới đợi Insert data vào rồi mới thực hiện phép tính dưới
            setTimeout(() => {
                if (data.hasOwnProperty("InventoryID")) {
                    dataGrid02Ref.current.instance.cellValue(rowIdx, "InventoryID", data.InventoryID);
                    dataGrid02Ref.current.instance.cellValue(rowIdx, "InventoryName", data.InventoryName);
                    dataGrid02Ref.current.instance.cellValue(rowIdx, "UnitID", data?.UnitID || "");
                } if (data.hasOwnProperty("UnitID")) {
                    dataGrid02Ref.current.instance.cellValue(rowIdx, "UnitID", data.UnitID);
                }
            }, 300);
            dataGrid02Ref.current.instance.saveEditData();
        }
        if (status === false && _.isEmpty(data?.InventoryID)) {
            if (!_.isEmpty(getCurrentDataSource())) {
                setTimeout(() => dataGrid02Ref.current.instance.deleteRow(getCurrentDataSource().length - 1), 300);
            }
        } else {
            setTimeout(() => forcusColumn(rowIdx), 600);
        }
        if (cbColumnPopRef.current?.hasOwnProperty("show")) cbColumnPopRef.current.show(status);
    };

    const renderNumberFormatGrid3 = (e) => {
        if (!e) return;
        const { column: { dataField = "" }, row: { data = {} } } = e;
        const decimalValue = data[dataField];
        if (_.isUndefined(decimalValue)) return;
        const { column, row } = e;
        const cellElement = e.component.getCellElement(row.rowIndex, column.dataField);
        if (cellElement && Config.isEmpty(decimalValue, true)) { //asd qwr wr wr wr wr wr
            if (_.isEmpty(_.get(dataGrid03[e.rowIndex], dataField, ""))) {
                cellElement.style.backgroundColor = `${Config.hexToRGBA(colors.danger, 0.3)}`;
            }
        }
        return <NumberInput
            nonStyle
            readOnly
            decimalDigit={4}
            thousandSeparator={","}
            value={decimalValue}
            className={classes.moneyInputColumn}
        />
    };
    const renderActionGrid3 = (e) => {
        return (
            <GridActionBar >
                <ButtonIcon
                    circular
                    name={"Delete"}
                    size={"medium"}
                    viewType={"text"}
                    onClick={() => onDeleteGrid03(e)} />
            </GridActionBar>
        );
    };

    const cellRenderPaymentMethod = (e) => {
        if (!e) return null;
        const { column: { dataField = "" }, displayValue } = e;
        const { column, row } = e;
        const cellElement = e.component.getCellElement(row.rowIndex, column.dataField);
        if (cellElement) {
            if (_.isEmpty(_.get(dataGrid03[e.rowIndex], dataField, ""))) {
                cellElement.style.backgroundColor = `${Config.hexToRGBA(colors.danger, 0.3)}`;
            }
        }
        return <Typography>{displayValue}</Typography>
    };

    const renderGrid03 = useMemo(() => {
        return <GridContainer
            reference={ref => dataGrid03Ref.current = ref}
            height={510}
            itemPerPage={10}
            typePaging={"normal"}
            pagerFullScreen={false}
            style={{ border: 'none' }}
            elementAttr={{
                class: classes.hiddenNewRow
            }}
            editing={{
                mode: "cell",
                refreshMode: "reshape",
                allowUpdating: !disabled,
                texts: {
                    confirmDeleteMessage: "",
                },
                startEditAction: "click",
            }}
            onInitNewRow={(evt) => {
                evt.data.PaymentMethodID = "";
                evt.data.OAmount = null;
                evt.data.CAmount = null;
                evt.data.BankID = "";
                setTimeout(() => {
                    evt.component.saveEditData();
                }, 200)
            }}
            onRowInserted={(e) => {
                const { OAmount = null } = _.get(e, "data", {});
                const { ExchangeRate = null } = dataMaster;
                e.data["OrderNum"] = tempOrderNum.current++;
                e.data["CAmount"] = Math.round(OAmount * ExchangeRate);
                refreshGrid(3);
            }}
            onRowUpdating={(e) => {
                const { newData, oldData } = e;
                const { ExchangeRate = null } = dataMaster;
                const columnName = (Object.keys(newData))[0];
                const actionColumn = ["OAmount"];
                if (actionColumn.includes(columnName)) {
                    const OAmount = columnName === "OAmount" ? newData[columnName] : oldData.OAmount;
                    oldData.CAmount = Math.round(OAmount * ExchangeRate);
                }
            }}
            dataSource={dataGrid03} >
            <Column
                fixed={true}
                allowEditing={false}
                alignment={"right"}
                fixedPosition={"right"}
                caption={Config.lang("Hanh_dong")}
                visible={Config.isMobile && !disabled}
                cellRender={renderActionGrid3}
            />
            <Column
                width={60}
                allowEditing={false}
                allowFiltering={false}
                alignment={"center"}
                dataField={"OrderNum"}
                caption={Config.lang("STT")}
            />
            <Column
                visible={false}
                dataField={"CAmount"}
            />
            <Column
                width={400}
                alignment={"center"}
                dataField={"PaymentMethodID"}
                headerCellRender={() =>
                (<> {Config.lang("Phuong_thuc_thanh_toan")}
                    <span style={{ color: 'red' }}>&nbsp;*</span>
                </>)}
                lookup={{
                    dataSource: dataCboPaymentMethod,
                    displayExpr: "PaymentMethodName",
                    valueExpr: "PaymentMethodID",
                }}
                cellRender={cellRenderPaymentMethod}
            />

            <Column
                width={400}
                headerCellRender={() =>
                (<> {Config.lang("So_tien_coc")}
                    <span style={{ color: 'red' }}>&nbsp;*</span>
                </>)}
                dataType={"number"}
                dataField={"OAmount"}
                cellRender={renderNumberFormatGrid3}
                editCellRender={(e) => (
                    <NumberInput
                        nonStyle
                        min={0}
                        value={e.value}
                        delayOnChange={0}
                        decimalDigit={4}
                        thousandSeparator={","}
                        className={classes.moneyInputColumn}
                        onChange={(c) => {
                            e.setValue(c.value)
                        }}
                    />
                )}
            />
            <Column
                width={400}
                alignment={"center"}
                dataField={"BankID"}
                caption={Config.lang("Ngan_hang")}
                lookup={{
                    dataSource: dataCboBank,
                    displayExpr: "BankName",
                    valueExpr: "BankID",
                }}
            />
            <Column
                width={40}
                fixed={true}
                allowEditing={false}
                alignment={"right"}
                fixedPosition={"right"}
                visible={!Config.isMobile && !disabled}
                cellRender={renderActionGrid3}
            />
            <Summary>
                <TotalItem
                    column={"PaymentMethodID"}
                    customizeText={customText}
                />
                <TotalItem
                    column={"OAmount"}
                    displayFormat={"{0}"}
                    valueFormat={numberFormat}
                    summaryType={"sum"}
                />
            </Summary>
        </GridContainer >;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid03, dataCboPaymentMethod, dataCboBank, disabled, dataMaster.ExchangeRate]);

    const displayEventID = useMemo(() => {
        return _.isArray(dataMaster?.EventID) ? dataMaster?.EventID.join(', ') : dataMaster.EventID;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMaster.EventID]);

    const displayContractNo = useMemo(() => {
        return _.isArray(dataMaster?.ContractNo) ? dataMaster?.ContractNo.join(', ') : dataMaster.ContractNo;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMaster.ContractNo]);

    if (!dataInfo.current.permission) return null;
    return (
        <React.Fragment>
            <LoadPanel
                shading
                showPane
                showIndicator
                position={{ my: 'center', of: '#root' }}
                visible={formLoading}
            />
            <CBMenuModal
                ref={ref => cbColumnPopRef.current = ref}
                onOpenComboModal={(status, data) => {
                    onOpenComboModal(status, data);
                }}
            />
            {openEventModal &&
                <EventModal
                    FormID={FormID}
                    dataChosenProps={dataMaster.EventID}
                    openModal={openEventModal}
                    dataInfo={dataMaster}
                    onOpenModalAction={(status, data) => onOpenModal("EventID", status, data)}
                />
            }
            <ExportReportModal
                ref={ExportReportModalRef}
                IsDefault={0}
                ModuleID={"A3"}
                FormID={FormID}
                label={Config.lang("Xuat_du_lieu")}
                disabled={formLoading}
                dataInfo={dataInfo.current}
                ReportTypeID={"WA3F2040"}
                StoreParams={StoreParams}
                setFormMasterLoading={(status) => setLoading({ formLoading: status })}
            />
            <ActionToolbar title={Config.lang("Lap_phieu_tinh_tien_tiec")} onBack={onBack}>
                <div className={"wrap"}>
                    <Button
                        disabled={formLoading || Mode === "view"}
                        style={{ marginRight: spacing(2) }}
                        size={"medium"}
                        color={"info"}
                        startIcon={"Save"}
                        viewType={"filled"}
                        label={Config.lang("Luu")}
                        onClick={onCheckSave}
                    />
                    <Button
                        disabled={formLoading || Mode !== "view"}
                        size={"medium"}
                        color={"primary"}
                        viewType={"outlined"}
                        startIcon={"Printer"}
                        label={Config.lang("In1")}
                        onClick={onPrint}
                    />
                </div>
            </ActionToolbar>
            <TabContainer tabIndex={tabIndex}>
                <TabHeader>
                    <TabItem
                        index={0}
                        label={Config.lang("Thong_tin_chung")}
                        onClick={() => handleChangeTab(0)}
                    />
                    <TabItem
                        index={1}
                        label={Config.lang("Chi_tiet")}
                        onClick={() => handleChangeTab(1)}
                    />
                    <TabItem
                        index={2}
                        label={Config.lang("Phat_sinh")}
                        onClick={() => handleChangeTab(2)}
                    />
                    <TabItem
                        index={3}
                        label={Config.lang("Thong_tin_thanh_toan")}
                        onClick={() => handleChangeTab(3)}
                    />
                </TabHeader>
                <TabPanel index={0}>
                    <AccordionGroup>
                        <Accordion expand={true}>
                            <AccordionSummary>
                                {Config.lang("Thong_tin_phieu")}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Dropdown
                                            clearAble
                                            required
                                            readOnly={disabled}
                                            valueExpr={"ContractTypeID"}
                                            displayExpr={"{ContractTypeID} - {ContractTypeName}"}
                                            label={Config.lang("Loai_phieu")}
                                            error={error && error["VoucherTypeID"]}
                                            onChange={e => onChange("VoucherTypeID", e)}
                                            dataSource={dataCboVoucherType}
                                            loading={cboVoucherTypeLoading}
                                            value={dataMaster?.VoucherTypeID}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            required
                                            inputRef={voucherNoRef}
                                            readOnly={disabled || voucherNoStatus || cboVoucherTypeLoading}
                                            label={Config.lang("So_phieu")}
                                            error={error && error["VoucherNo"]}
                                            onChange={e => onChange("VoucherNo", e)}
                                            value={dataMaster?.VoucherNo}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Dropdown
                                            clearAble
                                            required
                                            allowSearch
                                            searchDelayTime={700}
                                            readOnly={disabled}
                                            dataSource={_.get(dataCboCreateUser, "rows", [])}
                                            skip={cboCreateUser.current.skip}
                                            limit={cboCreateUser.current.limit}
                                            error={error && error["EmployeeID"]}
                                            total={_.get(dataCboCreateUser, "total", 0)}
                                            value={dataMaster?.EmployeeID}
                                            loading={cboCreateUserLoading}
                                            valueExpr={"EmployeeID"}
                                            displayExpr={"EmployeeName"}
                                            label={Config.lang("Nguoi_lap")}
                                            onChange={(e) => onChange("EmployeeID", e)}
                                            {...(dataMaster?.EmployeeID && dataMaster?.EmployeeName ? {
                                                valueObjectDefault: {
                                                    EmployeeID: dataMaster.EmployeeID,
                                                    EmployeeName: dataMaster.EmployeeName
                                                }
                                            } : {})}
                                            onLoadMore={(e) => {
                                                cboCreateUser.current.skip = e.skip;
                                                cboCreateUser.current.limit = e.limit;
                                                loadCboCreateUser();
                                            }}
                                            onInput={(e) => {
                                                const value = e?.target?.value || "";
                                                cboCreateUser.current.search = value;
                                                cboCreateUser.current.skip = 0;
                                                loadCboCreateUser();
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <DatePicker
                                            clearAble
                                            required
                                            readOnly={disabled}
                                            placeholder={"dd/mm/yyyy"}
                                            displayFormat={"DD/MM/YYYY"}
                                            label={Config.lang("Ngay_phieu")}
                                            value={dataMaster?.VoucherDate}
                                            error={error && error["VoucherDate"]}
                                            onChange={e => onChange("VoucherDate", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Dropdown
                                            clearAble
                                            required
                                            allowSearch
                                            searchDelayTime={700}
                                            readOnly={disabled}
                                            dataSource={_.get(dataCboCustomer, "rows", [])}
                                            skip={cboCustomer.current.skip}
                                            limit={cboCustomer.current.limit}
                                            total={_.get(dataCboCustomer, "total", 0)}
                                            value={dataMaster?.ObjectID}
                                            loading={cboCustomerLoading}
                                            valueExpr={"ObjectID"}
                                            displayExpr={"ObjectCustomName"}
                                            label={Config.lang("Ma_khach_hang")}
                                            onChange={(e) => onChange("ObjectID", e)}
                                            error={error && error["ObjectID"]}
                                            {...(dataMaster?.ObjectID && dataMaster?.ObjectName ? {
                                                valueObjectDefault: {
                                                    ObjectID: dataMaster.ObjectID,
                                                    ObjectName: dataMaster.ObjectName,
                                                    ObjectCustomName: `${dataMaster.ObjectID} - ${dataMaster.ObjectName}`
                                                }
                                            } : {})}
                                            onLoadMore={(e) => {
                                                cboCustomer.current.skip = e.skip;
                                                cboCustomer.current.limit = e.limit;
                                                loadCboCustomer();
                                            }}
                                            onInput={(e) => {
                                                const value = e?.target?.value || "";
                                                cboCustomer.current.search = value;
                                                cboCustomer.current.skip = 0;
                                                loadCboCustomer();
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly
                                            loading={cboCustomerLoading}
                                            label={Config.lang("Ten_khach_hang")}
                                            value={dataMaster?.ObjectName}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <Dropdown
                                                    clearAble
                                                    readOnly={disabled}
                                                    valueExpr={"CurrencyID"}
                                                    displayExpr={"CurrencyID"}
                                                    style={{ minWidth: 'auto' }}
                                                    label={Config.lang("Loai_tien")}
                                                    onChange={e => onChange("CurrencyID", e)}
                                                    dataSource={dataCboCurrency}
                                                    loading={cboCurrencyLoading}
                                                    value={dataMaster?.CurrencyID}
                                                />
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <NumberInput
                                                    readOnly
                                                    decimalDigit={4}
                                                    thousandSeparator={","}
                                                    value={dataMaster?.ExchangeRate}
                                                    label={Config.lang("Ty_gia")}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Dropdown
                                            readOnly
                                            valueExpr={"StatusName"}
                                            displayExpr={"StatusName"}
                                            label={Config.lang("Trang_thai")}
                                            dataSource={dataCboStatus}
                                            value={dataMaster?.StatusName}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly={disabled}
                                            label={Config.lang("Dien_giai_phieu")}
                                            onChange={(e) => onChange("Description", e)}
                                            value={dataMaster?.Description}
                                        />
                                    </Col>
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                    </AccordionGroup>
                    <AccordionGroup>
                        <Accordion expand={true}>
                            <AccordionSummary>
                                {Config.lang("Thong_tin_su_kien")}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            required
                                            readOnly
                                            endIcon={"ArrowDown"}
                                            error={error && error["EventID"]}
                                            label={Config.lang("Ma_su_kien")}
                                            placeholder={Config.lang("Chon")}
                                            value={displayEventID}
                                            onClick={() => { if (disabled === false) onOpenModal("EventID", true) }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("So_hop_Dong")}
                                            value={displayContractNo}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <DatePicker
                                            readOnly
                                            width={"100%"}
                                            margin={"normal"}
                                            placeholder={"dd/mm/yyyy"}
                                            displayFormat={"DD/MM/YYYY"}
                                            label={Config.lang("Ngay_tiec")}
                                            value={dataMaster?.EventDate}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={6} sm={6} md={6} lg={6}>
                                                <TextInput
                                                    readOnly
                                                    endIcon={"Clock"}
                                                    placeholder={"hh:mm"}
                                                    label={Config.lang("Gio_tiec")}
                                                    value={dataMaster?.HourFrom}
                                                />
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={6}>
                                                <TextInput
                                                    readOnly
                                                    label={" "}
                                                    endIcon={"Clock"}
                                                    placeholder={"hh:mm"}
                                                    value={dataMaster?.HourTo}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Sanh")}
                                            value={dataMaster?.BanquetName}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Dia_diem_to_chuc_vi_tri")}
                                            value={dataMaster?.Location}
                                            onChange={e => onChange("Location", e)}
                                        />
                                    </Col>
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                    </AccordionGroup>
                    <Row>
                        <Col style={{ marginTop: spacing(4) }} xs={12} sm={12} md={12} lg={12}>
                            <Attachment
                                ref={attRef}
                                isStripDomain
                                accept={attachmentType}
                                style={{ minHeight: 120 }}
                                readOnly={disabled}
                                data={dataOldAttachments}
                                domain={Config.getCDNPath()}
                                onChange={onChangeAttachments}
                                uploadErrorInfo={{
                                    existingFile: Config.lang("File_da_duoc_dinh_kem"),
                                    maxFile: Config.lang("File_vuot_qua_so_luong_cho_phep"),
                                    maxSize: Config.lang("File_vuot_qua_dung_luong_cho_phep"),
                                    fileType: `${Config.lang("Chi_ho_tro_dinh_kem_cac_file_co_dinh_dang")}: ${attachmentType.join(', ')}`,
                                }}
                            />
                        </Col>
                    </Row>
                </TabPanel>
                {/* ==================== TAB CHI TIẾT ================= */}
                <TabPanel index={1}>
                    <div style={{ marginTop: spacing(2) }}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {renderGrid01}
                            </Col>
                        </Row>
                    </div>
                    {renderTotalBoeard(1)}
                </TabPanel>
                <TabPanel index={2}>
                    <div style={{ marginTop: spacing(2) }}>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Button
                                    style={{ marginBottom: spacing(2) }}
                                    disabled={disabled}
                                    size={"medium"}
                                    color={"primary"}
                                    viewType={"filled"}
                                    startIcon={"AddFilled"}
                                    label={Config.lang("Them")}
                                    onClick={onInsertRow}
                                />
                                {renderGrid02}
                            </Col>
                        </Row>
                    </div>
                    {renderTotalBoeard(2)}
                </TabPanel>
                <TabPanel index={3}>
                    <div style={{ marginTop: spacing(2) }}>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Button
                                    style={{ marginBottom: spacing(2) }}
                                    disabled={disabled}
                                    size={"medium"}
                                    color={"primary"}
                                    viewType={"filled"}
                                    startIcon={"AddFilled"}
                                    label={Config.lang("Them")}
                                    onClick={onInsertRowGrid3}
                                />
                                {renderGrid03}
                            </Col>
                        </Row>
                    </div>
                    {renderTotalBoeard(3)}
                </TabPanel>
            </TabContainer>
        </React.Fragment>
    );
};

const CBMenuModal = memo(forwardRef((props, ref) => {
    const { onOpenComboModal } = props;
    const [openComboModal, setOpenComboModal] = useState(false);
    const dataRowKey = useRef({});
    useImperativeHandle(ref, () => ({
        show: (status, dataRow) => {
            if (!_.isUndefined(dataRow)) dataRowKey.current = dataRow;
            if (_.isBoolean(status)) setOpenComboModal(status);
        }
    }));
    return (
        <>
            {openComboModal &&
                <CboPop
                    FormID={FormID}
                    mode={"single"}
                    openModal={openComboModal}
                    dataKeyChose={_.get(dataRowKey.current, "valueID", "")}
                    keyExpr={_.get(dataRowKey.current, "dataField", "")}
                    keyExprName={_.get(dataRowKey.current, "dataFieldName", "")}
                    onOpenModalAction={(status, data) => {
                        onOpenComboModal(status, { ...data, isAddNew: _.get(dataRowKey.current, "isAddNew", false) });
                    }}
                />
            }
        </>
    )
}));

WA3F2041.propTypes = {
    dataInfo: PropTypes.object,
};

export default WA3F2041;
