/**
 * @copyright 2022 @ DigiNet
 * @author ANHTAI
 * @create 25/04/2022
 * @Example
 */
import { Column } from "devextreme-react/data-grid";
import { Modal, ModalBody, ModalHeader, Row, Col, TextInput } from 'diginet-core-ui/components';
import _ from "lodash";
import moment from "moment";
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from "react";
import * as WA3F2022Actions from "../../../../redux/modules/WA3/WA3F2022/WA3F2022_actions";
import { useDispatch } from "react-redux";
import Config from '../../../../config/index';
import GridContainer from "../../../common/grid-container/grid-container";
import 'moment-lunar';

const itemPerPage = 10;
const EventModal = (props) => {
    const dispatch = useDispatch();
    const { openModal, onOpenModalAction, FormID, dataInfo, currentSelectID, keyExpr } = props;
    const [dataGridLoading, setDataGridLoading] = useState(false);
    const [dataCboEvent, setDataCboEvent] = useState({
        rows: [],
        total: 0
    });

    const timerSearch = useRef(null);
    const dataGridPages = useRef({
        search: "",
        skip: 0,
        limit: 10,
    });

    useEffect(() => {
        loadCboEventCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadCboEventCode = () => {
        const { ObjectID = "" } = dataInfo;
        const { skip, limit, search } = dataGridPages.current;
        const param = {
            FormID,
            ObjectID,
            skip,
            limit,
            search
        };
        setDataGridLoading(true);
        dispatch(WA3F2022Actions.loadCboEventCode(param, (error, data) => {
            setDataGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                setDataCboEvent({
                    rows,
                    total
                });
            }
        }));
    };

    const formatValidTime = (date, isLuna = false) => {
        let result = date;
        if (!_.isNull(date) && Config.isValidDateTime(date)) {
            result = isLuna === true ? moment(date).lunar().format("YYYY-MM-DD") : moment(date).format("YYYY-MM-DD");
        }
        return result;
    };

    const onChangePage = (page) => {
        dataGridPages.current.skip = page * dataGridPages.current.limit;
        loadCboEventCode();
    };

    const onChangePerPage = (perPage) => {
        dataGridPages.current.skip = 0;
        dataGridPages.current.limit = perPage;
        loadCboEventCode();
    };

    return (
        <Modal
            zIndex={1020}
            width={"960"}
            open={openModal}
            alignment={"center"}
            onClose={() => { if (onOpenModalAction) onOpenModalAction(false) }}>
            <ModalHeader>{Config.lang("Chon_ma_su_kien")}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <TextInput
                            viewType={"outlined"}
                            endIcon={"Search"}
                            placeholder={Config.lang("Tim_kiem")}
                            onChange={(e) => {
                                const value = e?.target?.value || "";
                                if (timerSearch.current) clearTimeout(timerSearch.current);
                                timerSearch.current = setTimeout(() => {
                                    dataGridPages.current.search = value;
                                    dataGridPages.current.skip = 0;
                                    loadCboEventCode();
                                }, 700);
                            }}
                            value={dataGridPages.current.search}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <GridContainer
                            keyExpr={keyExpr}
                            pagerFullScreen={false}
                            typePaging={"remote"}
                            selection={{ mode: "single" }}
                            style={{ border: 'none' }}
                            itemPerPage={itemPerPage}
                            loading={dataGridLoading}
                            elementAttr={{ style: 'min-height: 320px' }}
                            totalItems={_.get(dataCboEvent, "total", 0)}
                            dataSource={_.get(dataCboEvent, "rows", [])}
                            onChangePage={onChangePage}
                            onChangePerPage={onChangePerPage}
                            filterRow={{
                                visible: true,
                                showOperationChooser: false,
                            }}
                            onContentReady={(e) => {
                                if (currentSelectID && !_.isEmpty(dataCboEvent?.rows)) {
                                    const grid = e.component;
                                    const index = dataCboEvent.rows.findIndex(item => item[keyExpr] === currentSelectID);
                                    grid.selectRowsByIndexes([_.toNumber(index)]); // Default focus dòng đầu tiên
                                }
                            }}
                            onRowDblClick={(e) => {
                                if (onOpenModalAction) {
                                    const data = {
                                        ..._.get(e, "data", {}),
                                        EventDate: formatValidTime(e?.data?.EventDate),
                                        EventLunaDate: formatValidTime(e?.data?.EventDate, true)
                                    };
                                    onOpenModalAction(false, data); //Chọn và đóng modal
                                }
                            }}>
                            <Column
                                width={160}
                                dataField={"EventID"}
                                caption={Config.lang("Ma_su_kien")}
                            />
                            <Column
                                width={160}
                                dataField={"ContractNo"}
                                caption={Config.lang("So_hop_dong")}
                            />
                            <Column
                                width={120}
                                caption={Config.lang("Ngay_su_kien")}
                                dataField={"EventDate"}
                                alignment={"center"}
                                dataType={"date"}
                                format={"dd/MM/yyyy"}
                            />
                            <Column
                                width={80}
                                alignment={"center"}
                                dataField={"HourFrom"}
                                caption={Config.lang("Tu_gio")}
                            />
                            <Column
                                width={80}
                                alignment={"center"}
                                dataField={"HourTo"}
                                caption={Config.lang("Den_gio")}
                            />
                            <Column
                                minWidth={120}
                                caption={Config.lang("Hinh_thuc_tiec")}
                                dataField={"EventTypeName"}
                            />
                            <Column
                                minWidth={120}
                                caption={Config.lang("Loai_ban_tiec")}
                                dataField={"SetupName"}
                            />
                            <Column
                                minWidth={140}
                                caption={Config.lang("Ten_su_kien")}
                                dataField={"EventName"}
                            />
                        </GridContainer>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

EventModal.defaultProps = {
    openModal: false,
    FormID: "",
    currentSelectID: "",
    dataInfo: {},
};

EventModal.propTypes = {
    openModal: PropTypes.bool,
    FormID: PropTypes.string,
    currentSelectID: PropTypes.string,
    dataInfo: PropTypes.object,
    onOpenModalAction: PropTypes.func,
};

export default EventModal;