/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 18/11/2021
 * @Example
 */
import { LoadPanel } from 'devextreme-react/load-panel';
import {
    Button, ButtonIcon, Chip, DatePicker, Modal, ModalBody, ModalHeader, PagingInfo as Paging, Popover, TextInput,
    Typography, Col, Row
} from 'diginet-core-ui/components';
import { makeStyles } from "diginet-core-ui/theme";
import _ from "lodash";
import moment from "moment";
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Config from '../../../../config/index';
import * as generalActions from "../../../../redux/general/general_actions";
import * as WA3F2105Actions from "../../../../redux/modules/WA3/WA3F2105/WA3F2105_actions";
import History from "../../../common/libs/history/history";
import { useTheme } from "diginet-core-ui/theme";
import WA3F2106 from "../WA3F2106/WA3F2106";
import 'moment/locale/vi';

const useStyles = makeStyles((theme) => ({
    modal: {
        overFlow: 'hidden'
    },
    board: {
        marginBottom: theme?.spacing(6),
        padding: theme?.spacing(4),
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
        }
    },
    actionBoard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme?.spacing(4)
    },
    titleBoard: {
        display: 'flex',
        fontSize: '16px',
        fontWeight: 700,
        justifyContent: 'space-between',
        marginBottom: theme?.spacing(4)
    },
    contentBoard: {
        display: 'flex',
        width: '100%'
    },
    btnActionItem: {
        display: 'block',
        width: '100%',
        textAlign: 'left',
        '&:hover': {
            backgroundColor: `${_.get(theme, "colors.hover", "")} !important`
        }
    },
    stickyPage: {
        bottom: 0,
        position: 'sticky',
        width: 'auto',
        zIndex: 9999,
    }
}));

const initialDetailState = {
    total: 0,
    rows: []
};
const FormID = "WA3F2105";
const WA3F2105 = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { colors, spacing } = useTheme();
    const { openModal, onOpenModalAction, dataInfo: dataInfoProp = {}, onOpenWA3F2110 } = props;
    const Mode = _.get(dataInfoProp, "Mode", "view");
    //==========STATE==========
    const [formLoading, setFormLoading] = useState(false);
    const [openBtnMore, setOpenBtnMore] = useState(false);
    const [btnBookingStatus, setBtnBookingStatus] = useState(false);
    const [permission, setPermission] = useState(_.get(dataInfoProp, "Permission", null));
    const [dataDetail, setDataDetail] = useState(initialDetailState);
    const [openWA3F2106, setOpenWA3F2106] = useState(false);

    //================REF========== ========
    const saveSuccess = useRef(false);
    const btnMoreRef = useRef(null);
    const btnMorePosition = useRef(null);
    const btnMoreAnchor = useRef(null);
    const currentClickBoard = useRef({});
    const filterPage = useRef({
        skip: 0,
        limit: 6
    });
    const dataInfo = useRef({
        BanquetCode: _.get(dataInfoProp, "BanquetCode", ""),
        BanquetName: _.get(dataInfoProp, "BanquetName", ""),
        BookingID: _.get(dataInfoProp, "BookingID", ""),
        BookingItemID: _.get(dataInfoProp, "BookingItemID", ""),
        EventDate: _.get(dataInfoProp, "EventDate", null),
        EventLunaDate: _.get(dataInfoProp, "EventLunaDate", null),
    });
    //=======Variable==========
    const { BanquetCode = "", BanquetName = "", EventDate = null, EventLunaDate = null } = dataInfo.current;

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (permission) {
            const eventDate = _.toNumber(moment(EventDate).format("DD"));
            const nowDate = _.toNumber(moment().format("DD"));
            if (eventDate < nowDate) setBtnBookingStatus(true); // Ngày dương nhỏ hơn ngày hiện tại
            if (Mode !== "add") loadForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permission]);

    const loadPermission = () => {
        dispatch(generalActions.getPermission("WA3F2100", (isPer) => {
            setPermission(isPer);
        }));
    };

    const loadForm = () => {
        const { skip, limit } = filterPage.current;
        const { BanquetCode, BookingID, BookingItemID, EventDate } = dataInfo.current;
        const param = {
            skip,
            limit,
            BanquetCode,
            BookingID,
            BookingItemID,
            EventDate,
        };
        setFormLoading(true);
        dispatch(WA3F2105Actions.viewDetail(param, (error, data) => {
            setFormLoading(false);
            if (error) {

                setDataDetail(initialDetailState);
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const total = _.get(data, "total", 0);
                const rows = _.get(data, "rows", []);
                setDataDetail({
                    total,
                    rows
                });
            }
        }));
    };

    const checkAndReturnLine = (id, name) => {
        if (_.isNull(id)) id = "";
        if (_.isNull(name)) name = "";
        const line = Config.isEmpty(id, name) || Config.isEmpty(name, true) ? "" : "-";
        return `${id} ${line} ${name}`.trim();
    };

    const onSaveHistory = async (action = 0, data = {}, dataCompare = {}) => {
        if (_.isEmpty(data) || Config.isEmpty(action, true)) return;
        let TransID = "";
        const captions = { // default Master
            BookingNo: "Ma_booking",
            StatusEvent: "Trang_thai_su_kien",
            ObjectID: "Khach_hang",
            EventDate: "Ngay_duong_lich",
            HourFrom: "Gio_tiec",
        };
        if (action === 3) TransID = _.get(data, "BookingItemID", "");
        const options = {
            data,
            dataCompare,
            captions,
            action,
            ModuleID: "DA3",
            TransactionID: FormID,
            TransID,
            TransactionName: Config.lang("Chi_tiet_bang_muc_tieu"),
            itemRender: (e, type) => {
                const { data, key } = e;
                if (type === "Content") {
                    if (key === "ObjectID") {
                        return checkAndReturnLine(_.get(data, "ObjectID", ""), _.get(data, "ObjectName", ""));
                    } else if (key === "HourFrom") {
                        return checkAndReturnLine(_.get(data, "HourFrom", ""), _.get(data, "HourTo", ""));
                    } else if (key === "EventDate") {
                        const EventDate = _.get(data, "EventDate", "");
                        return Config.isValidDateTime(EventDate) ? moment(EventDate).format("YYYY-MM-DD") : "";
                    }
                }
            }
        };
        const history = new History(options);
        // console.log("===========History==========>", history.get());
        return history.get()?.length > 0 ? await history.save() : true;
    };

    const onView = () => {
        if (btnMoreRef.current) btnMoreRef.current.close();
        const { BanquetCode = "", BookingID = "", BookingItemID = "" } = currentClickBoard.current;
        if (onOpenWA3F2110) onOpenWA3F2110(true, { BanquetCode, BookingID, BookingItemID }, "view");
    };

    const onEdit = () => {
        if (btnMoreRef.current) btnMoreRef.current.close();
        const { BanquetCode = "", BookingID = "", BookingItemID = "", EventDate = null } = currentClickBoard.current;
        const param = {
            BanquetCode,
            BookingID,
            BookingItemID,
            EventDate,
        };
        setFormLoading(true);
        dispatch(WA3F2105Actions.checkEdit(param, (error, data) => {
            setFormLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                if (_.get(data, "Status", 1) === 0) {
                    if (onOpenWA3F2110) onOpenWA3F2110(true, { BanquetCode, BookingID, BookingItemID }, "edit");
                    return true;
                } else {
                    if (_.get(data, "MsgAsk", "") === 0) {
                        Config.popup.show("INFO", _.get(data, "Message", ""));
                    }
                    else if (_.get(data, "MsgAsk", "") === 1) {
                        Config.popup.show('YES_NO', _.get(data, "Message", ""), () => {
                            if (onOpenWA3F2110) onOpenWA3F2110(true, { BanquetCode, BookingID, BookingItemID }, "edit");
                        });
                    }
                }
            }
        }));
    };

    const onDelete = async () => {
        if (btnMoreRef.current) btnMoreRef.current.close();
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), async () => {
            const { BanquetCode = "", BookingID = "", BookingItemID = "", EventDate = null } = currentClickBoard.current;
            const params = {
                BanquetCode,
                EventDate,
                BookingID,
                BookingItemID
            };
            setFormLoading(true);
            dispatch(WA3F2105Actions.onDelete(params, async (error, data) => {
                setFormLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else if (data) {
                    const status = _.get(data, "Status", null);
                    if (status === 0) {
                        saveSuccess.current = true;
                        await onSaveHistory(3, currentClickBoard.current);
                        loadForm(); // load lại form
                        Config.notify.show("success", _.get(data, "Message", "") || Config.lang("Xoa_thanh_cong"), 2000);
                    } else {
                        Config.popup.show("INFO", _.get(data, "Message", "") || Config.lang("Loi_chua_xac_dinh"));
                        return false;
                    }
                }
            }));
        })
    };

    const onChangeBookingInfo = (status = false) => {
        if (!_.isBoolean(status)) return;
        setOpenWA3F2106(status);
        if (btnMoreRef.current) btnMoreRef.current.close();
    };

    const checkChangeBookingInfo = () => {
        const { BanquetCode = "", BookingID = "", BookingItemID = "", EventDate = null } = currentClickBoard.current;
        const params = {
            BanquetCode,
            BookingID,
            BookingItemID,
            EventDate,
        };
        dispatch(WA3F2105Actions.checkChangeBookingInfo(params, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                if (data?.Status === 0) {
                    onChangeBookingInfo(true);
                } else {
                    Config.popup.show("INFO", _.get(data, "Message", "") || Config.lang("Loi_chua_xac_dinh"));
                    return false;
                }
            }
        }));
    };

    const onCancel = () => {
        if (btnMoreRef.current) btnMoreRef.current.close();
        const { BanquetCode = "", BookingID = "", BookingItemID = "", EventDate = null, BookingNo = "",
            StatusEvent = "", ObjectID = "", ObjectName = "", HourFrom = "", HourTo = ""} = currentClickBoard.current;
        const params = {
            BanquetCode: BanquetCode,
            EventDate: EventDate,
            BookingID: BookingID,
            BookingItemID: BookingItemID
        }
        dispatch(WA3F2105Actions.onCancel(params, async(error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            const paramHistory = {
                BookingItemID: BookingItemID,
                BookingNo: BookingNo,
                StatusEvent: StatusEvent,
                ObjectIDAndObjectName: `${ObjectID} - ${ObjectName}`,
                EventDate: Config.convertDate(EventDate, "", "YYYY-MM-DD") ,
                HourFromAndHourTo: `${HourFrom} - ${HourTo}`
            }
            if (data?.Status === 1) {
                Config.popup.show("INFO", _.get(data, "Message", "") || Config.lang("Loi_chua_xac_dinh"));
                return false;
            }
            Config.notify.show("success", _.get(data, "Message", "") || Config.lang("Huy_thanh_cong"), 2000);

            loadForm(); // load lại form
            await onSaveHistoryHandleCancel(4, paramHistory)
        }));
    };

    const onSaveHistoryHandleCancel = async (action = 0, params = {}) => {
        const captions = {
            BookingNo: "Ma_booking",
            StatusEvent: "Trang_thai_su_kien",
            ObjectIDAndObjectName: "Khach_hang",
            EventDate: "Ngay_duong_lich",
            HourFromAndHourTo: "Gio_tiec"
        };

        const {BookingItemID, ...restParams} = params;

        const options = {
            data: restParams,
            captions,
            action,
            ModuleID: "DA3",
            TransactionID: "WA3F2105",
            TransID: BookingItemID,
            TransactionName: Config.lang("Lich_chi_tiet"),
        };
        const history = new History(options);
        return history.get()?.length > 0 ? await history.save() : true;
    };

    const popupActionBar = () => {
        const StatusEventID = _.get(currentClickBoard.current, "StatusEventID", null);
        const StatusEvent = Config.replaceSymbol(_.get(currentClickBoard.current, "StatusEvent", ""));
        return (
            <>
                <Popover
                    ref={btnMoreRef}
                    open={openBtnMore}
                    anchor={btnMorePosition.current}
                    fullScreen={false}
                    width={"250px"}
                    anchorOrigin={{
                        horizontal: "right",
                        vertical: "bottom"
                    }}
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                    transformOrigin={{
                        horizontal: "right",
                        vertical: "top"
                    }}
                    onClose={() => setOpenBtnMore(false)}
                >
                    <div className={"wrap"}>
                        <Button
                            className={classes.btnActionItem}
                            disabled={formLoading || !(permission > 2)}
                            size={"medium"}
                            color={"primary"}
                            viewType={"text"}
                            label={Config.lang("Xem")}
                            onClick={onView}
                        />
                        <Button
                            className={classes.btnActionItem}
                            disabled={formLoading || !(permission >= 2)}
                            size={"medium"}
                            color={"primary"}
                            viewType={"text"}
                            label={Config.lang("Sua")}
                            onClick={onEdit}
                        />
                        <Button
                            className={classes.btnActionItem}
                            disabled={formLoading || !(permission >= 4) || _.toNumber(StatusEventID) !== 0} // 0 = Giữ Chỗ
                            size={"medium"}
                            color={"primary"}
                            viewType={"text"}
                            label={Config.lang("Xoa")}
                            onClick={onDelete}
                        />
                        <Button
                            className={classes.btnActionItem}
                            disabled={formLoading || permission < 2 || StatusEvent === "HUY"}
                            size={"medium"}
                            color={"primary"}
                            viewType={"text"}
                            label={Config.lang("Huy")}
                            onClick={onCancel}
                        />
                        <Button
                            className={classes.btnActionItem}
                            disabled={formLoading}
                            size={"medium"}
                            color={"primary"}
                            viewType={"text"}
                            label={Config.lang("Thay_doi_thong_tin_Booking")}
                            onClick={checkChangeBookingInfo}
                        />
                    </div>
                </Popover>
            </>
        );
    };

    const btnMoreAction = (index, data) => {
        if (Config.isEmpty(index, true) || _.isEmpty(data)) return;
        currentClickBoard.current = data;
        btnMorePosition.current = btnMoreAnchor.current[index];
        setOpenBtnMore(true);
    };

    const onBooking = () => {
        if (btnMoreRef.current) btnMoreRef.current.close();
        const { BanquetCode = "", BookingID = "", BookingItemID = "" } = currentClickBoard.current;
        if (onOpenWA3F2110) onOpenWA3F2110(true, { BanquetCode, BookingID, BookingItemID }, "add");
    };

    const onChangePage = (page) => {
        filterPage.current.skip = page * filterPage.current.limit;
        loadForm();
    };

    const onChangePerPage = (perPage) => {
        filterPage.current.skip = 0;
        filterPage.current.limit = perPage;
        loadForm();
    };

    if (!permission) return null;
    return (
        <Modal
            id={"Popup-WA3F2105"}
            zIndex={1020}
            width={"1440"}
            open={openModal}
            className={classes.modal}
            onClose={() => {
                if (onOpenModalAction) {
                    let reLoad = false;
                    if (saveSuccess.current) reLoad = true;
                    onOpenModalAction(false, reLoad);
                }
            }}>
            <ModalHeader>{Config.lang("Lich_chi_tiet")}</ModalHeader>
            <ModalBody>
                {openWA3F2106 &&
                    <WA3F2106
                        openModal={openWA3F2106}
                        dataInfo={{ ...currentClickBoard.current, mode: "edit" }}
                        onOpenModalAction={(status = false, reLoadForm = false) => {
                            onChangeBookingInfo(status);
                            if (reLoadForm) {
                                saveSuccess.current = true; // Load luôn form cha WA3F2103
                                loadForm();
                            }
                        }}
                    />
                }
                <div style={{ minHeight: 380 }}>
                    <LoadPanel
                        position={{ my: 'center', of: '#Popup-WA3F2105' }}
                        visible={formLoading}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                    />
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={4} >
                                    <DatePicker
                                        readOnly={true}
                                        placeholder={"dd/mm/yyyy"}
                                        displayFormat={"DD/MM/YYYY"}
                                        label={Config.lang("Ngay_duong_lich")}
                                        value={EventDate}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} >
                                    <DatePicker
                                        readOnly={true}
                                        placeholder={"dd/mm/yyyy"}
                                        displayFormat={"DD/MM/YYYY"}
                                        label={Config.lang("Ngay_am_lich")}
                                        value={EventLunaDate}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} >
                                    <TextInput
                                        readOnly={true}
                                        value={BanquetCode}
                                        endIcon={"ArrowDown"}
                                        label={Config.lang("Ma_sanh_tiec")}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Row style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                <Col xs={12} sm={12} md={8} lg={9} >
                                    <TextInput
                                        readOnly={true}
                                        label={Config.lang("Ten_sanh_tiec")}
                                        value={BanquetName}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={4} lg={3} >
                                    <Button
                                        disabled={formLoading || btnBookingStatus}
                                        className={classes.btnAction}
                                        size={"medium"}
                                        color={"primary"}
                                        startIcon={"Touch"}
                                        viewType={"filled"}
                                        label={Config.lang("Booking")}
                                        onClick={onBooking}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: spacing(2) }}>
                        {popupActionBar()}
                        {!_.isEmpty(_.get(dataDetail, "rows", [])) && dataDetail.rows.map((obj, i) => {
                            const { StatusEventID = null, StatusEvent = "", HourFrom = "", HourTo = "", BookingNo = "", ObjectID = "", ObjectName = "", EventName = "",
                                EventTypeName = "", SetupName = "", OQTYTable = "", VegesTableQTY = "", EmployeeName = "", BookingDate = "", ContactTelNo = "",
                                NotesChange = "",} = obj;
                            let bookingDate = BookingDate || "";
                            if (Config.isValidDateTime(BookingDate)) bookingDate = _.upperFirst(moment(bookingDate).locale(Config.getLocalStorage("langBEM")).format("dddd, DD MMMM YYYY"));
                            const hour = checkAndReturnLine(HourFrom, HourTo);
                            const objectIDName = checkAndReturnLine(ObjectID, ObjectName);
                            let startIcon = "";
                            let color = "";
                            switch (StatusEventID) {
                                case 0:
                                    startIcon = "Bookmark";
                                    color = "warning";
                                    break;
                                case 1:
                                    startIcon = "Money";
                                    color = "success";
                                    break;
                                default:
                                    break;
                            }
                            return (
                                <Col key={i} xs={12} sm={6} md={4} lg={4} >
                                    <div className={classes.board}>
                                        <div className={classes.actionBoard} ref={e => btnMoreAnchor.current = { ...btnMoreAnchor.current, [i]: e }}>
                                            <Chip label={StatusEvent} viewType={"filled"} color={color} startIcon={startIcon} />
                                            <ButtonIcon
                                                circular
                                                size={"small"}
                                                viewType={"text"}
                                                name={"More"}
                                                onClick={() => btnMoreAction(i, obj)}
                                            />
                                        </div>
                                        <div className={classes.titleBoard}>
                                            <span>{`${Config.lang("Gio_tiec")}: ${hour}`}</span>
                                            <span>{`${BookingNo}`}</span>
                                        </div>
                                        <div className={classes.contentBoard}>
                                            <div style={{ width: "100%", wordBreak: "break-word" }}>
                                                <Row>
                                                    <Col xs={12} sm={5} md={5} lg={5}>
                                                        <Typography color={colors?.text?.normal_label}>
                                                            {`${Config.lang("Nguoi_lap")}:`}</Typography >
                                                    </Col>
                                                    <Col xs={12} sm={7} md={7} lg={7}>{EmployeeName}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={5} md={5} lg={5}>
                                                        <Typography color={colors?.text?.normal_label}>
                                                            {`${Config.lang("Ngay_lap")}:`}</Typography >
                                                    </Col>
                                                    <Col xs={12} sm={7} md={7} lg={7}>{bookingDate}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={5} md={5} lg={5}>
                                                        <Typography color={colors?.text?.normal_label}>
                                                            {`${Config.lang("Khach_hang")}:`}</Typography >
                                                    </Col>
                                                    <Col xs={12} sm={7} md={7} lg={7}>{objectIDName}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={5} md={5} lg={5}>
                                                        <Typography color={colors?.text?.normal_label}>
                                                            {`${Config.lang("So_dien_thoai")}:`}</Typography >
                                                    </Col>
                                                    <Col xs={12} sm={7} md={7} lg={7}>{ContactTelNo}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={5} md={5} lg={5}>
                                                        <Typography color={colors?.text?.normal_label}>
                                                            {`${Config.lang("Ten_su_kien")}:`}</Typography>
                                                    </Col>
                                                    <Col xs={12} sm={7} md={7} lg={7}>{EventName}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={5} md={5} lg={5}>
                                                        <Typography color={colors?.text?.normal_label}>
                                                            {`${Config.lang("Hinh_thuc_tiec")}:`}</Typography>
                                                    </Col>
                                                    <Col xs={12} sm={7} md={7} lg={7}>{EventTypeName}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={5} md={5} lg={5}>
                                                        <Typography color={colors?.text?.normal_label}>
                                                            {`${Config.lang("Loai_ban_tiec")}:`}</Typography>
                                                    </Col>
                                                    <Col xs={12} sm={7} md={7} lg={7}>{SetupName}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={5} md={5} lg={5}>
                                                        <Typography color={colors?.text?.normal_label}>
                                                            {`${Config.lang("So_ban_chinh_thuc")}:`}</Typography>
                                                    </Col>
                                                    <Col xs={12} sm={7} md={7} lg={7}>{OQTYTable}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={5} md={5} lg={5}>
                                                        <Typography color={colors?.text?.normal_label}>
                                                            {`${Config.lang("So_ban_chay")}:`}</Typography>
                                                    </Col>
                                                    <Col xs={12} sm={7} md={7} lg={7}>{VegesTableQTY}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={5} md={5} lg={5}>
                                                        <Typography color={colors?.text?.normal_label}>
                                                            {`${Config.lang("Ghi_chu")}:`}</Typography>
                                                    </Col>
                                                    <Col xs={12} sm={7} md={7} lg={7}>
                                                        <Typography color={"danger"} lineClamp={3} hoverTooltip>
                                                            {NotesChange}
                                                        </Typography>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
                <Paging
                    itemsPerPage={6}
                    listPerPage={[6, 12, 24]}
                    totalItems={_.get(dataDetail, "total", 0)}
                    className={classes.stickyPage}
                    onChangePage={onChangePage}
                    onChangePerPage={onChangePerPage}
                />
            </ModalBody>
        </Modal>
    );
};

WA3F2105.propTypes = {
    openModal: PropTypes.bool,
    dataInfo: PropTypes.object,
    onOpenModalAction: PropTypes.func,
    onOpenWA3F2110: PropTypes.func,
};

export default WA3F2105;
