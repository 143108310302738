/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 01/11/2021
 * @Example
 */
import { Column, Lookup } from "devextreme-react/data-grid";
import {
    Button, ButtonIcon, Checkbox, DatePicker, Dropdown, Label, Status, TabContainer,
    TabHeader, TabItem, TextInput, Col, Row
} from 'diginet-core-ui/components';
import { makeStyles } from "diginet-core-ui/theme";
import _ from "lodash";
import moment from "moment";
import React, { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Config from '../../../../config/index';
import * as generalActions from "../../../../redux/general/general_actions";
import * as WA3F2030Actions from "../../../../redux/modules/WA3/WA3F2030/WA3F2030_actions";
import GridActionBar from "../../../common/grid-container/grid-actionbar";
import GridContainer from "../../../common/grid-container/grid-container";
import PopoverAction from "../../../common/grid-container/popover-action";
import ActionToolbar from "../../../common/layouts/toolbar/action-toolbar";
import History from "../../../common/libs/history/history";
import Filter from "../../../filter/filter";
import WA3F2031 from "../WA3F2031/WA3F2031";

const useStyles = makeStyles((theme) => ({
    btnActionItem: {
        textTransform: 'initial',
        display: 'block',
        width: '100%',
        textAlign: 'left',
        '&:hover': {
            backgroundColor: `${_.get(theme, "colors.hover", "")} !important`
        }
    },
    ellipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    }
}));

const initialDataGrid = {
    total: 0,
    rows: [],
};
const FormID = "WA3F2030";
const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const WA3F2030 = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const Language = Config.language || "84";
    const DivisionID = _.get(Config.profile, "DivisionID", Config.getDivisionID()) || Config.getLocalStorage("DIVISIONBEM");
    const [gridLoading, setGridLoading] = useState(false);
    const [cboCreateUserLoading, setCboCreateUserLoading] = useState(false);
    const [openModalWA3F2031, setOpenModalWA3F2031] = useState(false);
    const [showChoseDate, setShowChoseDate] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [permission, setPermission] = useState(0);
    const [dataGrid, setDataGrid] = useState(initialDataGrid);
    const [dataTabHeaderTitle, setDataTabHeaderTitle] = useState([]);

    //==========REF=============
    const dataDayByCombo = useMemo(() => {
        return [
            {
                id: 0,
                fieldName: Config.lang("Hom_nay"),
                dateFrom: moment().format("YYYY-MM-DD"),
                dateTo: moment().format("YYYY-MM-DD")
            },
            {
                id: 1,
                fieldName: Config.lang("Hom_qua"),
                dateFrom: moment().subtract(1, "day").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, "day").format("YYYY-MM-DD")
            },
            {
                id: 2,
                fieldName: Config.lang("Tuan_nay"),
                dateFrom: moment().startOf("week").format("YYYY-MM-DD"),
                dateTo: moment().endOf("week").format("YYYY-MM-DD")
            },
            {
                id: 3,
                fieldName: Config.lang("Tuan_truoc"),
                dateFrom: moment().subtract(1, "week").startOf("week").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, "week").endOf("week").format("YYYY-MM-DD")
            },
            {
                id: 4,
                fieldName: Config.lang("Thang_nay"),
                dateFrom: moment().startOf("month").format("YYYY-MM-DD"),
                dateTo: moment().endOf("month").format("YYYY-MM-DD")
            },
            {
                id: 5,
                fieldName: Config.lang("Thang_truoc"),
                dateFrom: moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
            },
            {
                id: 6,
                fieldName: Config.lang("Nam_nay"),
                dateFrom: moment().startOf("year").format("YYYY-MM-DD"),
                dateTo: moment().endOf("year").format("YYYY-MM-DD")
            },
            {
                id: 7,
                fieldName: Config.lang("Nam_truoc"),
                dateFrom: moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, "years").endOf("year").format("YYYY-MM-DD")
            },
            {
                id: 8,
                fieldName: Config.lang("Chon_thoi_gian"),
                dateFrom: moment().format("YYYY-MM-DD"),
                dateTo: moment().format("YYYY-MM-DD")
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const popoverGrid = useRef(null);
    const currentClickActionRow = useRef({});
    const [dataCboCreateUser, setDataCboCreateUser] = useState({
        rows: [],
        total: 0
    });
    const defaultDate = 4; // default Tháng này
    const [filterSearch, setFilterSearch] = useState({
        strSearch: "",
        BEOID: "",
        BEOCode: "",
        ContractID: "",
        BatchID: "",
        StatusBEO: null,
        EmployeeID: "", // Người lập
        ContractNo: "",
        EventID: "",
        EventDate: null,
        selectedDate: defaultDate,
        DateFrom: _.get(dataDayByCombo[defaultDate], "dateFrom", null),
        DateTo: _.get(dataDayByCombo[defaultDate], "dateTo", null),
    });
    const statusBEOOld = usePrevious(filterSearch.StatusBEO) ?? null;

    const inititalPages = {
        strSearch: "",
        skip: 0,
        limit: 10,
    };
    const dataGridPages = useRef(inititalPages);
    const filterCboCreateUsers = useRef({
        ObjectID: "",
        BookingID: "",
        ObjectTypeID: "",
        skip: 0,
        limit: 20,
        search: ""
    });
    const dataInfo = useRef({
        Permission: permission,
        screen: FormID,
        Mode: "view",
        BEOID: "",
        BEOCode: "",
    });

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (permission) {
            dataInfo.current.Permission = permission;
            loadGridTab();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permission]);

    useEffect(() => {
        if (statusBEOOld !== filterSearch.StatusBEO || dataTabHeaderTitle) { // Load Tab xong load Grid
            if (_.isEmpty(dataTabHeaderTitle)) {
                setDataGrid(initialDataGrid);
            } else {
                loadGrid();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSearch.StatusBEO, dataTabHeaderTitle]);

    const loadPermission = () => {
        dispatch(generalActions.getPermission(FormID, isPer => {
            setPermission(isPer);
        }));
    };

    const loadGridTab = () => {
        const { EmployeeID, BEOCode, EventDate, StatusBEO, DateFrom, DateTo } = filterSearch;
        const { skip, limit, strSearch } = dataGridPages.current;
        const params = {
            DataType: "LoadTab",
            DivisionID,
            BEOCode,
            EventDate,
            StatusBEO,
            EmployeeID,
            DateFrom,
            DateTo,
            skip,
            limit,
            strSearch
        };
        setGridLoading(true);
        dispatch(WA3F2030Actions.loadGridTab(params, (error, data) => {
            setGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                if (data) setFilterSearch({ ...filterSearch, StatusBEO: data[0]?.StatusBEO ?? null });
                if (tabIndex !== 0) setTabIndex(0);
                setDataTabHeaderTitle(data);
            }
        }));
    };

    const loadGrid = () => {
        const { EmployeeID, ContractID, BEOID, BEOCode, BatchID, EventDate, StatusBEO, ContractNo, EventID, DateFrom, DateTo } = filterSearch;
        const { skip, limit, strSearch } = dataGridPages.current;
        const params = {
            DataType: "LoadMaster",
            DivisionID,
            FormID,
            BEOID,
            BEOCode,
            ContractID,
            ContractNo,
            EventID,
            BatchID,
            EventDate,
            StatusBEO,
            EmployeeID,
            DateFrom,
            DateTo,
            strSearch,
            skip,
            limit,
        };
        setGridLoading(true);
        if (!_.isEmpty(dataGrid?.rows)) setDataGrid(initialDataGrid); // fix bug cột thay đổi
        dispatch(WA3F2030Actions.loadGrid(params, (error, data) => {
            setGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                setDataGrid(initialDataGrid);
                return false;
            } else if (data) {
                const total = _.get(data, "total", []);
                const dataGrid = _.get(data, "rows", []);
                setDataGrid({
                    total,
                    rows: dataGrid
                });
            }
        }));
    };

    const loadCboCreateUser = (isReset) => { // CBO người lập
        const { ObjectID, ObjectTypeID, BookingID, skip, limit, search } = filterCboCreateUsers.current;
        const param = {
            FormID,
            ObjectID,
            BookingID,
            ObjectTypeID,
            skip,
            limit,
            search
        };
        setCboCreateUserLoading(true);
        dispatch(generalActions.getCboCreateUser(param, (error, data) => {
            setCboCreateUserLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                let rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                if (!_.isEmpty(rows)) {
                    rows = rows.map(item => {
                        const line = _.isEmpty(item.EmployeeID) || _.isEmpty(item.EmployeeName) ? "" : "-";
                        return ({ ...item, EmployeeCustomName: `${item.EmployeeID} ${line} ${item.EmployeeName}` })
                    });
                }
                setDataCboCreateUser({
                    rows: isReset ? rows : _.get(dataCboCreateUser, "rows", []).concat(rows),
                    total
                });
            }
        }));
    };

    const openModalPage = (Mode, screen, currentRowClick = {}) => {  // Modal nằm Màn hình khác
        if (!Mode || _.isEmpty(screen)) return;
        let BEOID = "";
        let BEOCode = "";
        let currentRowSelected = currentRowClick;
        if (_.isEmpty(currentRowClick)) currentRowSelected = currentClickActionRow.current;
        if (Mode !== "add") {
            BEOID = _.get(currentRowSelected, "BEOID", "");
            BEOCode = _.get(currentRowSelected, "BEOCode", "");
        }
        dataInfo.current = {
            ...dataInfo.current,
            Mode,
            BEOID,
            BEOCode,
        };
        switch (screen) {
            case "WA3F2031":
                setOpenModalWA3F2031(true);
                break;
            default:
                break;
        }
        if (popoverGrid.current) popoverGrid.current.hide(); // Đóng Action nếu có mở
    };

    const onDelete = async () => {
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), async () => {
            const { BEOID, BEOCode } = currentClickActionRow.current;
            const params = {
                BEOID,
                BEOCode
            };
            setGridLoading(true);
            dispatch(WA3F2030Actions.deleteBEO(params, async (error, data) => {
                setGridLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else if (data) {
                    if (_.get(data, "Status", null) === 0) {
                        Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 2000);
                        if (_.get(dataGrid, "rows", []).length === 1) setTabIndex(0); // Nếu tab hiện tại hết data cho về Tab 1
                        loadGridTab();
                    } else {
                        const Message = data.Message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("ERROR", Message);
                    }
                }
            }));
        });
    };

    const onSaveHistory = async (action = 1, data = {}, dataCompare = {}) => {
        if (_.isEmpty(data) || Config.isEmpty(action, true)) return;
        const TransactionName = Config.lang("Danh_sach_phieu_BEO");
        const TransID = _.get(data, "BEOCode", "");
        const captions = {
            BEOCode: "Ma_phieu_BEO",
            StatusName: "Trang_thai",
            ContractNo: "So_hop_dong",
            EventID: "Ma_su_kien",
        };
        const options = {
            data,
            dataCompare, // Old Data
            captions,
            action,
            ModuleID: "DA3",
            TransactionID: FormID,
            TransID,
            TransactionName
        };
        const history = new History(options);
        // console.log("===========HIS=========>", history.get());
        return history.get()?.length > 0 ? await history.save() : true;
    };

    const onLoadDataFilter = () => {
        loadCboCreateUser();
    };

    const onSearch = (e) => {
        if (dataGridPages.current) {
            dataGridPages.current.strSearch = _.get(e, "target.value", "");
            loadGridTab();
        }
    };

    const onChangeBEOPage = (page) => {
        dataGridPages.current.skip = page * dataGridPages.current.limit;
        loadGrid();
    };

    const onChangeBEOPerPage = (perPage) => {
        dataGridPages.current.skip = 0;
        dataGridPages.current.limit = perPage;
        loadGrid();
    };

    const onOpenMenu = (e, currentRowSelected) => {
        const target = _.get(e, "currentTarget", {});
        if (popoverGrid.current) {
            currentClickActionRow.current = currentRowSelected;
            popoverGrid.current.show(target, currentRowSelected);
        }
    };

    const renderStatusColumn = (e = {}) => {
        const data = _.get(e, "row.data", {});
        if (!e || _.isEmpty(data)) return;
        let color = "";
        let icon = "";
        switch (Number(data["StatusBEO"])) {
            case 0:
                color = "info";
                icon = "HourGlass";
                break;
            case 1:
                color = "success";
                icon = "Approval";
                break;
            case 9:
                color = "warning";
                icon = "Delete";
                break;
            case 100:
                color = "danger";
                icon = "Close";
                break;
            default:
                break;
        }
        return <Status icon={icon} color={color} size={"large"} text={data?.StatusName} />
    };

    const onOpenModalAction = (status, reloadPage = false) => {
        if (_.isBoolean(status)) {
            setOpenModalWA3F2031(status);
            if (reloadPage) loadGridTab();
        }
    };

    const renderActionGrid = (e) => {
        const currentRowSelected = _.get(e, "data", {});
        return (
            <GridActionBar >
                <ButtonIcon
                    circular
                    name={"More"}
                    viewType={"text"}
                    onClick={(e) => onOpenMenu(e, currentRowSelected)} />
            </GridActionBar>
        );
    };

    const onConfirmBEO = async () => {
        Config.popup.show("YES_NO", Config.lang("Xac_nhan_phieu_BEO_va_tinh_yeu_cau_vat_tu_cho_tiec_ban_co_muon_tiep_tuc_khong?"), async () => {
            const { BEOID, BEOCode, ContractNo, ContractID, EventID, BatchID } = currentClickActionRow.current;
            const params = {
                DivisionID,
                Language,
                FormID,
                BEOID,
                BEOCode,
                ContractNo,
                ContractID,
                EventID,
                BatchID,
                EmployeeID: "",
                strSearch: "",
                EventDate: null,
                StatusBEO: null,
                BEODate: null
            };
            setGridLoading(true);
            dispatch(WA3F2030Actions.createBEO(params, async (error, data) => {
                setGridLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else if (data) {
                    if (_.get(data, "Status", null) === 0) {
                        await onSaveHistory(1, { ...currentClickActionRow.current, StatusName: _.get(data, "StatusName", "Đã xác nhận") }, currentClickActionRow.current);
                        const Message = data.Message || Config.lang("Cap_nhat_thanh_cong");
                        Config.notify.show("success", Message, 2000);
                        loadGridTab(); // Load lại tab + grid
                    } else {
                        const Message = data.Message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("INFO", Message);
                    }
                }
            }));
        });
    };

    const cancelBEO = () => {
        const { BEOID, BEOCode } = currentClickActionRow.current;
        const params = {
            BEOID,
            BEOCode,
        };
        setGridLoading(true);
        dispatch(WA3F2030Actions.cancelBEO(params, async (error, data) => {
            setGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                if (_.get(data, "Status", null) === 0) {
                    await onSaveHistory(1, { ...currentClickActionRow.current, StatusName: _.get(data, "StatusName", "Hủy") },
                        currentClickActionRow.current);
                    const Message = data.Message || Config.lang("Cap_nhat_thanh_cong");
                    Config.notify.show("success", Message, 2000);
                    loadGridTab(); // Load lại tab + lưới
                } else {
                    const Message = data.Message || Config.lang("Cap_nhat_thanh_cong");
                    Config.popup.show("ERROR", Message);
                }
            }
        }));
    };

    const filterChange = (filterName = "", e) => {
        if (!e || _.isEmpty(filterName)) return;
        const value = _.isObject(e) ? _.get(e, "data.value", _.get(e, "value", _.get(e, "target.value", ""))) : e;
        switch (filterName) {
            //DD Set Stage liền
            case "EventDate":
            case "StatusBEO":
            case "EmployeeID":
                setFilterSearch({ ...filterSearch, [filterName]: value });
                break;
            // Input Chưa cần Set Stage liền
            case "BEOCode":
                filterSearch[filterName] = value;
                break;
            // Chọn ngày
            case "ChoseDate":
                setFilterSearch({
                    ...filterSearch,
                    selectedDate: value,
                    DateFrom: _.get(dataDayByCombo[value], "dateFrom", null),
                    DateTo: _.get(dataDayByCombo[value], "dateTo", null),
                });
                if (value === 8) {
                    if (showChoseDate === false) setShowChoseDate(true);
                } else {
                    if (showChoseDate === true) setShowChoseDate(false);
                }
                break;
            default:
                break;
        }
    };

    const renderCheckColumn = (e) => {
        if (!e) return;
        return (
            <span style={{ pointerEvents: 'none' }}>
                <Checkbox style={{ margin: 'auto' }} checked={e.value === 1} />
            </span>
        );
    };

    const handleChangeTab = (index, StatusBEO = null) => {
        if (!_.isNumber(index)) return;
        if (index !== tabIndex) {
            dataGridPages.current = inititalPages;
            setTabIndex(index);
            setFilterSearch({ ...filterSearch, StatusBEO });
        }
    };

    const btnSearchAction = () => {
        dataGridPages.current.skip = inititalPages.skip;
        dataGridPages.current.limit = inititalPages.limit;
        loadGridTab();
    };

    const renderFilterData = () => {
        return (
            <Filter
                isUseDDCore={true}
                placeholder={Config.lang("Noi_dung_can_tim")}
                onTextChanged={onSearch}
                onOpenLoaded={onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Dropdown
                                    viewType={"outlined"}
                                    valueExpr={"id"}
                                    keyExpr={"fieldName"}
                                    displayExpr={"fieldName"}
                                    placeholder={Config.lang("Chon")}
                                    label={Config.lang("Chon_thoi_gian")}
                                    onChange={e => filterChange("ChoseDate", e)}
                                    dataSource={dataDayByCombo}
                                    value={filterSearch.selectedDate}
                                />
                            </Col>
                            {showChoseDate &&
                                <Col xs={12} sm={6} md={6} lg={6} >
                                    <div className={"display_row align-center "}>
                                        <Label style={{ minWidth: 22 }} className={"mgr5"}>{Config.lang("Tu")}</Label>
                                        <DatePicker
                                            viewType={"outlined"}
                                            placeholder={"dd/mm/yyyy"}
                                            displayFormat={"DD/MM/YYYY"}
                                            max={filterSearch.DateTo}
                                            value={filterSearch.DateFrom}
                                            onChange={e => filterChange("DateFrom", e)}
                                        />
                                    </div>
                                </Col>
                            }
                            {showChoseDate && <Col xs={12} sm={6} md={6} lg={6}>
                                <div className={"display_row align-center "}>
                                    <Label style={{ minWidth: 22 }} className={"mgr5"}>{Config.lang("Den")}</Label>
                                    <DatePicker
                                        viewType={"outlined"}
                                        placeholder={"dd/mm/yyyy"}
                                        displayFormat={"DD/MM/YYYY"}
                                        min={filterSearch.DateFrom}
                                        value={filterSearch.DateTo}
                                        onChange={e => filterChange("DateTo", e)}
                                    />
                                </div>
                            </Col>}
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    viewType={"outlined"}
                                    label={Config.lang("Ma_phieu_BEO")}
                                    placeholder={Config.lang("Nhap")}
                                    onChange={(e) => filterChange("BEOCode", e)}
                                    value={filterSearch.BEOCode}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <DatePicker
                                    clearAble
                                    viewType={"outlined"}
                                    placeholder={"dd/mm/yyyy"}
                                    displayFormat={"DD/MM/YYYY"}
                                    label={Config.lang("Ngay_su_kien")}
                                    value={filterSearch.EventDate}
                                    onChange={e => filterChange("EventDate", e)}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Dropdown
                                    clearAble
                                    allowSearch
                                    dataSource={_.get(dataCboCreateUser, "rows", [])}
                                    total={_.get(dataCboCreateUser, "total", 0)}
                                    skip={filterCboCreateUsers.current.skip}
                                    limit={filterCboCreateUsers.current.limit}
                                    value={filterSearch.EmployeeID}
                                    loading={cboCreateUserLoading}
                                    viewType={"outlined"}
                                    valueExpr={"EmployeeID"}
                                    keyExpr={"EmployeeName"}
                                    label={Config.lang("Nguoi_lap")}
                                    placeholder={Config.lang("Chon")}
                                    displayExpr={"EmployeeCustomName"}
                                    onChange={(e) => filterChange("EmployeeID", e)}
                                    onLoadMore={(e) => {
                                        filterCboCreateUsers.current.skip = e.skip;
                                        filterCboCreateUsers.current.limit = e.limit;
                                        loadCboCreateUser();
                                    }}
                                    searchDelayTime={700}
                                    onInput={(e) => {
                                        filterCboCreateUsers.current.search = e?.target?.value || "";
                                        filterCboCreateUsers.current.skip = 0;
                                        loadCboCreateUser(true);
                                    }}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} style={{ display: "flex", justifyContent: "center", }}>
                                <div style={{ display: "flex", justifyContent: "center", }}>
                                    <Button
                                        size={"medium"}
                                        color={"primary"}
                                        viewType={"outlined"}
                                        label={Config.lang("Tim_kiem")}
                                        startIcon={"Search"}
                                        onClick={btnSearchAction}
                                    />
                                </div>
                            </Col>
                        </Row>
                    );
                }}
            />
        );
    };

    const renderMaxWidthColum = (e, maxWidth = 280) => {
        const value = _.get(e, "value", "");
        return <div title={value} className={classes.ellipsis}
            {...(_.isNumber(maxWidth) ? { style: { maxWidth } } : {})} >{value}</div>
    };

    const renderTab = useMemo(() => {
        if (!(dataTabHeaderTitle?.length > 0)) return;
        return (
            <TabContainer tabIndex={tabIndex} >
                <TabHeader>
                    {dataTabHeaderTitle.map((item, idx) => {
                        const { Total = null, StatusName = "", StatusBEO = "" } = item;
                        return (
                            <TabItem
                                key={idx}
                                index={idx}
                                label={`${StatusName} ${!Config.isEmpty(Total, true) ? `(${Total})` : ""}`}
                                onClick={() => handleChangeTab(idx, StatusBEO)}
                            />
                        )
                    })}
                </TabHeader>
            </TabContainer>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTabHeaderTitle, tabIndex]);

    const renderGrid = useMemo(() => {
        return (
            <GridContainer
                keyExpr={"BEOCode"}
                style={{ border: 'none' }}
                typePaging={"remote"}
                filterRow={{
                    visible: true,
                    showOperationChooser: false,
                }}
                loading={gridLoading}
                height={Config.getHeightGrid()}
                totalItems={_.get(dataGrid, "total", 0)}
                dataSource={_.get(dataGrid, "rows", [])}
                itemPerPage={dataGridPages.current.limit}
                skipPerPage={dataGridPages.current.skip}
                onRowDblClick={(e) => {
                    if (e?.data) openModalPage("view", "WA3F2031", e.data);
                }}
                onChangePage={onChangeBEOPage}
                onChangePerPage={onChangeBEOPerPage}
            >
                <Column
                    width={40}
                    fixed={true}
                    alignment={"right"}
                    fixedPosition={"right"}
                    caption={Config.lang("Hanh_dong")}
                    visible={Config.isMobile}
                    cellRender={renderActionGrid}
                />
                <Column
                    width={220}
                    dataField={"BEOCode"}
                    caption={Config.lang("Ma_phieu_BEO")}
                />
                <Column
                    width={200}
                    dataField={"StatusName"}
                    caption={Config.lang("Trang_thai")}
                    cellRender={renderStatusColumn}
                />
                <Column
                    width={220}
                    dataField={"EmployeeName"}
                    caption={Config.lang("Nguoi_lap")}
                />
                <Column
                    width={120}
                    dataType={"date"}
                    alignment={"center"}
                    format={"dd/MM/yyyy"}
                    dataField={"BEODate"}
                    caption={Config.lang("Ngay_lap")}
                />
                <Column
                    width={220}
                    dataField={"ContractNo"}
                    caption={Config.lang("So_hop_dong")}
                />
                <Column
                    width={220}
                    dataField={"EventID"}
                    caption={Config.lang("Ma_su_kien")}
                />
                <Column
                    width={120}
                    dataType={"date"}
                    alignment={"center"}
                    format={"dd/MM/yyyy"}
                    dataField={"EventDate"}
                    caption={Config.lang("Ngay_su_kien")}
                />
                <Column
                    dataType={"boolean"}
                    dataField={"IsCreateBEO"}
                    caption={Config.lang("Da_phat_hanh_BEO")}
                    cellRender={renderCheckColumn}>
                    <Lookup dataSource={[
                        {
                            ID: 1,
                            Name: Config.lang("Co")
                        },
                        {
                            ID: 0,
                            Name: Config.lang("Khong")
                        }
                    ]}
                        valueExpr={"ID"}
                        displayExpr={"Name"}
                    />
                </Column>
                <Column
                    minWidth={90}
                    dataField={"Notes"}
                    caption={Config.lang("Ghi_chu")}
                    cellRender={(e) => renderMaxWidthColum(e, 280)}
                />
                <Column
                    width={40}
                    fixed={true}
                    alignment={"right"}
                    fixedPosition={"right"}
                    visible={!Config.isMobile}
                    cellRender={renderActionGrid}
                />
            </GridContainer>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, gridLoading, dataGridPages]);

    if (!permission) return null;
    return (
        <React.Fragment>
            {openModalWA3F2031 &&
                <WA3F2031
                    openModal={openModalWA3F2031}
                    dataInfo={dataInfo.current}
                    onOpenModalAction={(status, reloadPage) => {
                        onOpenModalAction(status, reloadPage)
                    }}
                />
            }
            <ActionToolbar title={Config.lang("Danh_sach_phieu_BEO")}>
            </ActionToolbar>
            {renderTab}
            <div className={"hidden"}>{renderFilterData()}</div>
            {!_.isEmpty(_.get(dataGrid, "rows", [])) &&
                <PopupActionBar
                    ref={ref => popoverGrid.current = ref}
                    permission={permission}
                    onConfirmBEO={onConfirmBEO}
                    cancelBEO={cancelBEO}
                    onDelete={onDelete}
                    {...props} />}

            {renderGrid}
        </React.Fragment >
    );
};

const PopupActionBar = memo(forwardRef((props, ref) => {
    const classes = useStyles();
    const { onConfirmBEO, cancelBEO, onDelete, permission } = props;
    const popoverGrid = useRef(null);
    const [rowData, setRowData] = useState(null);
    useImperativeHandle(ref, () => ({
        show: (target, data) => {
            if (target && popoverGrid.current) {
                popoverGrid.current.instance.show(target);
                setRowData(data);
            }
        },
        hide: () => {
            popoverGrid.current.instance.hide();
        }
    }));
    const unConfimred = _.get(rowData, "StatusBEO", null) === 0; // Chưa xác nhận
    const confimred = _.get(rowData, "StatusBEO", null) === 1; // Đã xác nhận
    const cancel = _.get(rowData, "StatusBEO", null) === 9; // Hủy
    return (
        <PopoverAction
            reference={ref => popoverGrid.current = ref}
            position={"right"}
            maxWidth={300}
            deferRendering={false}
        >
            <Button
                className={classes.btnActionItem}
                disabled={!(permission >= 4) || !unConfimred}
                size={"medium"}
                color={"primary"}
                viewType={"text"}
                label={Config.lang("Xac_nhan_BEO_va_YC_vat_tu")}
                onClick={onConfirmBEO}
            />
            <Button
                className={classes.btnActionItem}
                disabled={!(permission >= 4) || !confimred}
                size={"medium"}
                color={"primary"}
                viewType={"text"}
                label={Config.lang("Huy_BEO")}
                onClick={cancelBEO}
            />
            <Button
                className={classes.btnActionItem}
                disabled={!(permission >= 4) || !(unConfimred || cancel)}
                size={"medium"}
                color={"primary"}
                viewType={"text"}
                label={Config.lang("Xoa")}
                onClick={onDelete}
            />
        </PopoverAction>
    )
}));

export default WA3F2030;
