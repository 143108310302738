/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 04/11/2021
 * @Example
 */
import { Column } from "devextreme-react/data-grid";
import { LoadPanel } from 'devextreme-react/load-panel';
import {
    Attachment, Button, ButtonIcon, Checkbox, DatePicker, Dropdown, Modal, ModalBody, ModalFooter, ModalHeader, TextInput, Col, Row
} from 'diginet-core-ui/components';
import _ from "lodash";
import moment from "moment";
import PropTypes from 'prop-types';
import { makeStyles } from "diginet-core-ui/theme";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Config from '../../../../config/index';
import * as generalActions from "../../../../redux/general/general_actions";
import * as WA3F2031Actions from "../../../../redux/modules/WA3/WA3F2031/WA3F2031_actions";
import GridActionBar from "../../../common/grid-container/grid-actionbar";
import GridContainer from "../../../common/grid-container/grid-container";
import History from "../../../common/libs/history/history";
import CDN from "../../../common/utils/CDN";
import ChoseCBModal from "../../../custom/ChoseCBModal";

const useStyles = makeStyles(() => ({
    ellipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    }
}));

const FormID = "WA3F2031";
const attachmentType = ["txt", "jpg", "png", "doc", "docx", "xls", "xlsx", "jpeg", "pdf", "gif", "ppt", "pptx"];

const WA3F2031 = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { openModal, onOpenModalAction, dataInfo: dataInfoProp = {} } = props;
    const isModeAdd = _.get(dataInfoProp, "Mode", "add") === "add";
    const DivisionID = _.get(Config.profile, "DivisionID", Config.getDivisionID()) || Config.getLocalStorage("DIVISIONBEM");
    //<==========================STATE==========================>

    const [loading, _setLoading] = useState({
        formLoading: false,
        cboCreateUserLoading: false,
        cboStatusBEOLoading: false,
    });
    const { formLoading, cboCreateUserLoading, cboStatusBEOLoading } = loading;
    const [btnSaveStatus, setBtnSaveStatus] = useState(false);
    const [openComboModal, setOpenComboModal] = useState(false);
    const [Mode, setMode] = useState(_.get(dataInfoProp, "Mode", "add"));
    const fromScreen = _.get(dataInfoProp, "screen", "");
    const initialDataMaster = {
        ContractNo: fromScreen === "WA3F2010" ? _.get(dataInfoProp, "ContractNo", "") : "",
        ContractID: fromScreen === "WA3F2010" ? _.get(dataInfoProp, "ContractID", "") : "",
        BatchID: fromScreen === "WA3F2010" ? _.get(dataInfoProp, "BatchID", "") : "",
        EventID: fromScreen === "WA3F2010" ? _.get(dataInfoProp, "EventID", "") : "",
        EventName: fromScreen === "WA3F2010" ? _.get(dataInfoProp, "EventName", "") : "",
        EventDate: fromScreen === "WA3F2010" ? _.get(dataInfoProp, "EventDate", null) : null,
        Version: fromScreen === "WA3F2010" ? _.get(dataInfoProp, "Version", null) : null,
        BEOCode: "",
        BeoID: "",
        StatusName: "",
        EmployeeID: "",
        EmployeeName: "",
        Notes: "",
        IsCreateBEO: 0,  // Default chưa check
        StatusBEO: 0, // Default = 0 (chưa xác nhận)
        BEODate: isModeAdd ? moment().format("YYYY-MM-DD") : null,
    };
    const initialDataDetail = [];
    const [dataDetail, setDataDetail] = useState(initialDataDetail);
    const [dataMaster, setDataMaster] = useState(initialDataMaster);
    const [error, setError] = useState({});
    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [dataCboCreateUser, setDataCboCreateUser] = useState({
        rows: [],
        total: 0
    });
    const [dataCboStatusBEO, setDataCboStatusBEO] = useState([]);

    //<=============================REF============================>
    const saveSuccess = useRef(false);
    const countOrderNum = useRef(1);
    const uniqueKey = useRef(0);
    const dataGridRef = useRef(null);
    const selectedRowIndx = useRef(null);
    const refLoading = useRef({});
    const attachments = useRef([]);
    const deletedFile = useRef([]);
    const oldDataMaster = useRef({});
    const oldDataDetail = useRef([]);
    const oldDataAttachments = useRef([]);
    const objValue = useRef({});
    const currentChoseCboInfo = useRef({
        keyExpr: "",
        valueName: "",
        valueID: "",
    });
    const filterCboCreateUser = useRef({
        skip: 0,
        limit: 20,
        search: ""
    });
    //========================Variable======================
    const disabled = useMemo(() => {
        return formLoading || Mode === "view";
    }, [formLoading, Mode]);
    const dataInfo = useRef({
        iPermission: _.get(dataInfoProp, "Permission", 0),
        BEOID: _.get(dataInfoProp, "BEOID", ""),
        BEOCode: _.get(dataInfoProp, "BEOCode", ""),
        screen: _.get(dataInfoProp, "screen", ""),
        ContractNo: _.get(dataInfoProp, "ContractNo", ""),
        ContractID: _.get(dataInfoProp, "ContractID", ""),
        EventID: _.get(dataInfoProp, "EventID", ""),
        BatchID: _.get(dataInfoProp, "BatchID", ""),
        Version: _.get(dataInfoProp, "Version", null),
        EventDate: _.get(dataInfoProp, "EventDate", null),
        EventName: _.get(dataInfoProp, "EventName", ""),
    });

    useEffect(() => {
        if (Mode !== "view") {
            loadCboCreateUser(); // Người lập
            loadCboStatusBEO(); // Người lập
        }
        if (Mode === "add") getBEOCode();
        if (Mode !== "add") loadForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setLoading = (obj) => {
        refLoading.current = { ...refLoading.current, ...obj };
        _setLoading(refLoading.current);
    };

    const setDefaultValueGeneral = () => {
        if (!_.isEmpty(objValue.current)) {
            setDataMaster({ ...dataMaster, ...objValue.current });
        }
    };

    const getBEOCode = () => {
        const { ContractID, EventID, BatchID } = dataInfo.current;
        const param = {
            DivisionID,
            ContractID,
            EventID,
            BatchID
        };
        dispatch(WA3F2031Actions.getBEOCode(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                objValue.current = { ...objValue.current, BEOCode: _.get(data, "ID", "") };
                setDefaultValueGeneral();
            }
        }));
    };

    const loadForm = () => {
        const { BEOID, BEOCode } = dataInfo.current;
        const param = {
            DataType: "ViewDetail",
            DivisionID,
            FormID,
            BEOID,
            BEOCode,
        };
        setLoading({ formLoading: true });
        dispatch(WA3F2031Actions.loadForm(param, (error, data) => {
            setLoading({ formLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const masterData = _.omit(data, ["detail", "attachments"]);
                oldDataMaster.current = { ...masterData };
                setDataMaster(masterData);
                if (data.detail) {
                    oldDataDetail.current = data.detail.map(item => ({ ...item }));
                    const dataDetail = data.detail.map(item => ({
                        ...item,
                        OrderNum: Config.isEmpty(_.get(item, "OrderNum", null)) ? countOrderNum.current++ : item.OrderNum,
                        uniqueKey: uniqueKey.current++
                    }));
                    countOrderNum.current = _.toNumber(_.get(_.maxBy(dataDetail, "OrderNum"), "OrderNum", 0)) + 1 || 1;
                    setDataDetail(dataDetail);
                }
                if (data.attachments) {
                    oldDataAttachments.current = [...data.attachments];
                    setDataOldAttachments(data.attachments);
                }
            }
        }));
    };

    const loadCboCreateUser = (isReset) => {
        const { skip, limit, search } = filterCboCreateUser.current;
        const param = {
            FormID,
            ObjectID: "",
            ObjectTypeID: "",
            BookingID: "",
            skip,
            limit,
            search
        };
        setLoading({ cboCreateUserLoading: true });
        dispatch(generalActions.getCboCreateUser(param, (error, data) => {
            setLoading({ cboCreateUserLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            else if (data) {
                const rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                const employeeObj = rows.find(item => item.UserID === _.get(Config.profile, "UserID", ""));
                if (!_.isEmpty(employeeObj)) {
                    objValue.current = {
                        ...objValue.current,
                        EmployeeID: _.get(employeeObj, "EmployeeID", ""),
                        EmployeeName: _.get(employeeObj, "EmployeeName", "")
                    };
                    setDefaultValueGeneral();
                }
                setDataCboCreateUser({
                    rows: isReset ? rows : dataCboCreateUser.rows.concat(rows),
                    total
                });
            }
        }));
    };

    const loadCboStatusBEO = () => {
        const param = {
            FormID,
            ObjectID: "",
            ObjectTypeID: "",
            BookingID: "",
        };
        setLoading({ cboStatusBEOLoading: true });
        dispatch(generalActions.getCboStatusBEO(param, (error, data) => {
            setLoading({ cboStatusBEOLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            else if (data) {
                const statusBEOObj = data.find(item => item.StatusBEO === 0); // Default = 0
                if (!_.isEmpty(statusBEOObj)) {
                    objValue.current = {
                        ...objValue.current,
                        StatusBEO: _.get(statusBEOObj, "StatusBEO", ""),
                        StatusName: _.get(statusBEOObj, "StatusName", "")
                    };
                    setDefaultValueGeneral();
                }
                setDataCboStatusBEO(data);
            }
        }));
    };

    const onChange = async (fieldName, e) => {
        if (!e || _.isEmpty(fieldName)) return;
        let statusSetState = false;
        let value = _.isObject(e) ? _.get(e, "data.value", _.get(e, "value", _.get(e, "target.value", ""))) : e;
        let objectValuePlus = {};
        switch (fieldName) {
            //DatePicker
            case "BEODate":
                statusSetState = true;
                break;
            //DropDown
            case "StatusBEO":
            case "EmployeeID":
                const positionID = fieldName.indexOf("ID");
                const standardDDName = positionID > 0 ? fieldName.slice(0, positionID) : fieldName;
                const DDName = standardDDName + "Name";
                const valueDDName = _.get(e.data, [DDName], _.get(e.data, "ObjectName", ""));
                objectValuePlus = { [DDName]: valueDDName };
                statusSetState = true;
                break;
            //Checkbox
            case "IsCreateBEO":
                value = value ? 1 : 0;
                break;
            default:
                break;
        }
        objectValuePlus = { ...objectValuePlus, [fieldName]: value };
        if (dataMaster[fieldName] !== value && !_.isEmpty(objectValuePlus)) { // Không set state tránh giật
            Object.keys(objectValuePlus).forEach(key => {
                dataMaster[key] = objectValuePlus[key];
            });
        }
        if (statusSetState) { // Set State gây giật nhưng sẽ show value ngay lập tức
            setDataMaster({
                ...dataMaster,
                ...objectValuePlus
            });
        }
        if (btnSaveStatus === true) setBtnSaveStatus(false);
        if (error.hasOwnProperty(fieldName) && !Config.isEmpty(value, true)) setError(_.omit(error, fieldName));
    };

    const onChangeAttachments = (e) => {
        attachments.current = e.allNewAttached ? e.allNewAttached : [];
        if (e.removedAttached && e.removedAttached.length > 0) {
            deletedFile.current = [...e.removedAttached];
            const _arrRemove = deletedFile.current.map(d => d.AttachmentID);
            setDataOldAttachments(dataOldAttachments.filter(att => _arrRemove.indexOf(att.AttachmentID) < 0));
        }
    };

    const refreshGrid = () => {
        if (dataGridRef.current) dataGridRef.current.instance.refresh();
    };

    const sortByOrder = () => {
        dataDetail.forEach((item, idx) => item.OrderNum = idx + 1);
        countOrderNum.current = dataDetail.length + 1;
    };

    const onDelete = (e) => {
        if (!e) return;
        saveEditDataGrid();
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            e.component.deleteRow(e.rowIndex);
            refreshGrid();// Refesh lại grid để lấy đúng data
            sortByOrder();// Sắp xếp lại STT
        });
    };

    const renderActionGrid = (e) => {
        return (
            <GridActionBar >
                <ButtonIcon
                    circular
                    name={"Delete"}
                    viewType={"text"}
                    disabled={e?.row?.isNewRow}
                    onClick={() => onDelete(e)}
                    style={{ cursor: 'pointer' }} />
            </GridActionBar>
        );
    };

    const onSaveHistory = async (dataAttachmentCDN) => {
        let action = 0; // add hoac copy
        if (Mode === "edit") action = 1;
        const captions = { // default Master
            BEOCode: "Ma_phieu_BEO",
            StatusName: "Trang_thai_BEO",
            ContractNo: "So_hop_dong",
            EventID: "Ma_su_kien",
        };
        const newData = { ...dataMaster };
        const oldData = { ...oldDataMaster.current };
        const options = {
            data: newData,
            dataCompare: oldData,
            captions,
            action,
            ModuleID: "DA3",
            TransactionID: FormID,
            TransID: dataMaster.BEOCode,
            TransactionName: Config.lang("Chi_tiet_bang_muc_tieu"),
        };
        const captionAttachment = {
            URL: "URL",
            FileName: "Ten_dinh_kem",
        };
        const captionDetail = {
            Content: "Noi_dung",
            DepartmentName: "Bo_phan_tiep_nhan",
            ObjectName: "Nguoi_tiep_nhan",
        };
        const attachment = [...dataAttachmentCDN, ...dataOldAttachments]; // Đính kèm mới
        const dataAttachmentOld = oldDataAttachments.current; // Đính kèm cũ
        const dataDetailNew = getCurrentDataSource();
        const dataDetailOld = oldDataDetail.current;
        const history = new History(options);
        history.createDetailHistory("Danh_sach_chi_tiet_thuc_don", dataDetailNew, dataDetailOld, captionDetail, "uniqueKey", null, options);
        history.createDetailHistory("Dinh_kem", attachment, dataAttachmentOld, captionAttachment, "URL", null, options);
        // console.log("===========HIS=========>", history.get());
        return history.get()?.length > 0 ? await history.save() : true;
    };

    const _uploadFile = useCallback((files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _getAttachments = (file) => {
        const dataFile = _.get(file, "data.paths", []);
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileName ? att.fileName.split(".").pop() : "",
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }
        return arrAttachment;
    };

    const onOpenComboModal = (status, data = {}) => {
        if (!_.isBoolean(status)) return;
        if (!_.isEmpty(data)) {
            const { ObjectID = "", ObjectName = "", EmailTo = "", EmailCC = "" } = data;
            const rowIdx = selectedRowIndx.current;
            if (_.get(currentChoseCboInfo.current, "keyExpr", "") === "DepartmentID") { // Bộ phận tiếp nhận
                dataGridRef.current.instance.cellValue(rowIdx, "DepartmentID", ObjectID);
                dataGridRef.current.instance.cellValue(rowIdx, "DepartmentName", ObjectName);
                dataGridRef.current.instance.cellValue(rowIdx, "EmailTo", EmailTo);
                dataGridRef.current.instance.cellValue(rowIdx, "EmailCC", EmailCC);
                dataGridRef.current.instance.cellValue(rowIdx, "ObjectID", ""); // Clear người tiếp nhận
                dataGridRef.current.instance.cellValue(rowIdx, "ObjectName", ""); // Clear người tiếp nhận
            } else if (_.get(currentChoseCboInfo.current, "keyExpr", "") === "ObjectID") { // Người tiếp nhận
                dataGridRef.current.instance.cellValue(rowIdx, "ObjectID", ObjectID);
                dataGridRef.current.instance.cellValue(rowIdx, "ObjectName", ObjectName);
                dataGridRef.current.instance.cellValue(rowIdx, "EmailTo", EmailTo);
            }
            dataGridRef.current.instance.saveEditData();
        }
        setOpenComboModal(status);
    };

    const getCellData = (cellData) => {
        if (_.isEmpty(cellData)) return;
        let valueID = "";
        let valueName = "";
        const dataField = _.get(cellData, "column.dataField", "");
        switch (dataField) {
            case "DepartmentID":
                valueID = _.get(cellData, "data.DepartmentID", "") || "";
                valueName = _.get(cellData, "data.DepartmentName", "") || "";
                break;
            case "ObjectID":
                valueID = _.get(cellData, "data.ObjectID", "") || "";
                valueName = _.get(cellData, "data.ObjectName", "") || "";
                break;
            case "Content":
                valueID = valueName = _.get(cellData, "data.Content", "") || "";
                break;
            default:
                break;
        }
        return { valueID, valueName, dataField };
    };

    const saveEditDataGrid = async () => {
        if (dataGridRef.current) await dataGridRef.current.instance.saveEditData();
    };

    const getCurrentDataSource = () => {
        let dataSource = [];
        if (dataGridRef.current) dataSource = dataGridRef.current.instance.option("dataSource");
        return dataSource;
    };

    const customCellRender = (cellData, isTextColumn = false) => {
        const { column, row } = cellData;
        const { valueID, valueName, dataField } = getCellData(cellData);
        const cellElement = cellData.component.getCellElement(row.rowIndex, column.dataField);
        if (cellElement && _.isEmpty(valueName)) {
            if (!(row?.isNewRow === true)) {
                if (dataField === "DepartmentID" && _.isEmpty(_.get(dataDetail[cellData.rowIndex], "DepartmentID", ""))) {
                    cellElement.style.backgroundColor = '#f4433652';
                } else if (dataField === "Content" && _.isEmpty(_.get(dataDetail[cellData.rowIndex], "Content", ""))) {
                    cellElement.style.backgroundColor = '#f4433652';
                }
            }
        }
        return (
            isTextColumn ?
                <div style={{ display: 'flex', alignItems: 'left' }}>
                    <div className={`${classes.ellipsis} pull-left textCellHidden`}
                        title={valueName}>
                        {valueName}
                    </div>
                </div>
                : <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={"pull-left mgr5"}>
                            <ButtonIcon
                                circular
                                name={"Menu"}
                                viewType={"text"}
                                disabled={disabled}
                                onClick={() => {
                                    if (isTextColumn === false) saveEditDataGrid();
                                    const dataSource = getCurrentDataSource();
                                    const indexRow = dataSource.findIndex(obj => _.get(obj, "OrderNum", null) === _.get(cellData, "data.OrderNum", null));
                                    selectedRowIndx.current = indexRow;
                                    currentChoseCboInfo.current = {
                                        keyExpr: dataField,
                                        valueName,
                                        valueID
                                    };
                                    setOpenComboModal(true);
                                }}
                                style={{ cursor: 'pointer' }} />
                        </div>
                        <div className={`${classes.ellipsis} pull-left textCellHidden`}
                            title={valueName}>
                            {valueName}
                        </div>
                    </div>
                </>
        );
    };

    const onConfirmAdd = (param, dataAttachmentCDN) => {
        if (_.isEmpty(param)) return;
        setLoading({ formLoading: true });
        dispatch(WA3F2031Actions.confirmBEO(param, async (error, data) => {
            setLoading({ formLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                if (_.get(data, "Status", null) === 0) {
                    saveSuccess.current = true;
                    onSaveHistory(dataAttachmentCDN); // Lưu Lịch sử
                    Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                    setMode("view"); // Lưu thành công chuyển về View
                } else {
                    const message = error.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                }
            }
        }));
    };

    const onAdd = async () => {
        await saveEditDataGrid(); // Save Lưới
        const detail = getCurrentDataSource();
        const dataResUploadFile = !_.isEmpty(attachments.current) ? await _uploadFile(attachments.current, true) : {};
        const attachment = JSON.stringify(_getAttachments(dataResUploadFile));
        const checkDetailRequire = !_.isEmpty(detail) ? detail.some(item => _.isEmpty(item?.Content) || _.isEmpty(item?.DepartmentID)) : false;
        if (_.isEmpty(detail)) {
            Config.popup.show("INFO", Config.lang("Ban_phai_nhap_du_lieu_luoi"));
            return false;
        } else if (checkDetailRequire) {
            Config.popup.show("INFO", `${Config.lang("Vui_long_nhap_day_du_thong_tin_tren_luoi")}`);
            return false;
        } else {
            const param = {
                FormID,
                DivisionID,
                ...dataMaster,
                detail,
                attachment
            };
            setLoading({ formLoading: true });
            dispatch(WA3F2031Actions.addBEO(param, (error, data) => {
                setLoading({ formLoading: false });
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else if (data) {
                    const Status = _.get(data, "Status", null);
                    let dataAttachmentCDN = _.get(dataResUploadFile, "data.paths", []);
                    if (!_.isEmpty(dataAttachmentCDN)) {
                        dataAttachmentCDN = dataAttachmentCDN.map(item => ({
                            ...item,
                            URL: _.get(item, "url", ""),
                            FileName: _.get(item, "fileName", "")
                        }));
                    }
                    if (Status === 0) {
                        saveSuccess.current = true;
                        onSaveHistory(dataAttachmentCDN);
                        Config.notify.show("success", _.get(data, "Message", "") || Config.lang("Luu_thanh_cong"), 2000);
                        setMode("view"); // Lưu thành công chuyển về View
                    } else if (Status === 1) {
                        if (_.get(data, "MsgAsk", null) === 1) {
                            Config.popup.show("YES_NO", _.get(data, "Message", "") || Config.lang("Chua_co_mau_thong_tin_tiec_dinh_kem_ban_co_muon_luu_khong?"), () => { //YES
                                const code = _.get(data, "code", "");
                                if (code) onConfirmAdd({ ...param, code }, dataAttachmentCDN);
                            }, () => { // No
                                return false;
                            });
                        } else {
                            Config.popup.show("INFO", _.get(data, "Message", "") || Config.lang("Su_kien_nay_da_lap_phieu_BEO_vui_long_kiem_tra_lai"));
                        }
                    } else {
                        Config.popup.show("INFO", _.get(data, "Message", "") || Config.lang("Loi_chua_xac_dinh?"));
                        return false;
                    }
                }
            }));
        }
    };

    const onAddNewRow = () => {
        if (dataGridRef.current) setTimeout(() => dataGridRef.current.instance.addRow(), 0);
    };

    if (!dataInfo.current.iPermission) return null;
    return (
        <Modal
            id={"Popup-WA3F2031"}
            zIndex={1020}
            width={"1440"}
            open={openModal}
            onClose={() => {
                if (onOpenModalAction) {
                    let reLoad = false;
                    if (saveSuccess.current === true) reLoad = true;
                    onOpenModalAction(false, reLoad);
                }
            }}>
            <ModalHeader>{Config.lang("Duyet_phieu_BEO")}</ModalHeader>
            <ModalBody>
                <React.Fragment>
                    {openComboModal &&
                        <ChoseCBModal
                            FormID={"WA3F2031"}
                            openModal={openComboModal}
                            dataInfo={dataInfo.current}
                            keyExpr={currentChoseCboInfo.current.keyExpr}
                            dataKeyChose={currentChoseCboInfo.current.valueID}
                            onOpenModalAction={(status, data) => {
                                onOpenComboModal(status, data);
                            }}
                        />
                    }
                    <LoadPanel
                        position={{ my: 'center', of: '#Popup-WA3F2031' }}
                        visible={formLoading}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                    />
                    <Row className={"mgt15"}>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <TextInput
                                readOnly={true}
                                label={Config.lang("So_hop_Dong")}
                                value={dataMaster.ContractNo}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <TextInput
                                readOnly={true}
                                label={Config.lang("Ma_su_kien")}
                                value={dataMaster.EventID}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <TextInput
                                readOnly={true}
                                type={"number"}
                                label={Config.lang("Version_su_kien")}
                                value={dataMaster.Version}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <DatePicker
                                readOnly={true}
                                width={"100%"}
                                placeholder={"dd/mm/yyyy"}
                                displayFormat={"DD/MM/YYYY"}
                                label={Config.lang("Ngay_su_kien")}
                                value={dataMaster.EventDate}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Row style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                <Col xs={12} sm={6} md={9} lg={9}>
                                    <TextInput
                                        readOnly={true}
                                        label={Config.lang("Ten_su_kien")}
                                        value={dataMaster.EventName}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={3} lg={3}>
                                    <Checkbox
                                        readOnly={true}
                                        color={"primary"}
                                        label={Config.lang("Da_phat_hanh_BEO")}
                                        checked={dataMaster.IsCreateBEO === 1}
                                        onChange={e => onChange("IsCreateBEO", e)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <TextInput
                                readOnly={true}
                                label={Config.lang("Ma_phieu_BEO")}
                                value={dataMaster.BEOCode}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <Dropdown
                                readOnly={true}
                                dataSource={dataCboStatusBEO}
                                value={dataMaster.StatusBEO}
                                loading={cboStatusBEOLoading}
                                valueExpr={"StatusBEO"}
                                keyExpr={"StatusName"}
                                displayExpr={"StatusName"}
                                {...(dataMaster.StatusBEO ? {
                                    valueObjectDefault: {
                                        StatusBEO: dataMaster.StatusBEO,
                                        StatusName: dataMaster.StatusName
                                    }
                                } : {})}
                                label={Config.lang("Trang_thai_BEO")}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <Dropdown
                                clearAble
                                allowSearch
                                readOnly={Mode === "edit" || Mode === "view"}
                                dataSource={_.get(dataCboCreateUser, "rows", [])}
                                total={_.get(dataCboCreateUser, "total", 0)}
                                skip={filterCboCreateUser.current.skip}
                                limit={filterCboCreateUser.current.limit}
                                value={dataMaster.EmployeeID}
                                loading={cboCreateUserLoading}
                                valueExpr={"EmployeeID"}
                                keyExpr={"EmployeeName"}
                                displayExpr={"EmployeeName"}
                                {...(dataMaster.EmployeeID ? {
                                    valueObjectDefault: {
                                        EmployeeID: dataMaster.EmployeeID,
                                        EmployeeName: dataMaster.EmployeeName
                                    }
                                } : {})}
                                label={Config.lang("Nguoi_lap")}
                                placeholder={Config.lang("Chon")}
                                onChange={(e) => onChange("EmployeeID", e)}
                                onLoadMore={(e) => {
                                    filterCboCreateUser.current.skip = e.skip;
                                    filterCboCreateUser.current.limit = e.limit;
                                    loadCboCreateUser();
                                }}
                                searchDelayTime={700}
                                onInput={(e) => {
                                    filterCboCreateUser.current.search = e?.target?.value || "";
                                    filterCboCreateUser.current.skip = 0;
                                    loadCboCreateUser(true);
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <DatePicker
                                clearAble
                                width={"100%"}
                                placeholder={"dd/mm/yyyy"}
                                displayFormat={"DD/MM/YYYY"}
                                label={Config.lang("Ngay_lap")}
                                value={dataMaster.BEODate}
                                readOnly={Mode === "edit" || Mode === "view"}
                                onChange={e => onChange("BEODate", e)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                readOnly={disabled}
                                label={Config.lang("Ghi_chu")}
                                value={dataMaster.Notes}
                                onChange={e => onChange("Notes", e)}
                            />
                        </Col>
                        <Col style={{ margin: '24px 0' }} xs={12} sm={12} md={12} lg={12}>
                            <Attachment
                                accept={attachmentType}
                                isStripDomain={true}
                                style={{ minHeight: 120 }}
                                readOnly={disabled}
                                data={dataOldAttachments}
                                domain={Config.getCDNPath()}
                                onChange={onChangeAttachments}
                                uploadErrorInfo={{
                                    existingFile: Config.lang("File_da_duoc_dinh_kem"),
                                    maxFile: Config.lang("File_vuot_qua_so_luong_cho_phep"),
                                    maxSize: Config.lang("File_vuot_qua_dung_luong_cho_phep"),
                                    fileType: `${Config.lang("Chi_ho_tro_dinh_kem_cac_file_co_dinh_dang")}: ${attachmentType.join(', ')}`,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 16, display: 'flex', flexWrap: 'wrap' }}>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <span>
                                <Button
                                    disabled={disabled}
                                    size={"medium"}
                                    color={"primary"}
                                    viewType={"filled"}
                                    startIcon={"AddCircle"}
                                    label={Config.lang("Them")}
                                    style={{ textTransform: 'uppercase' }}
                                    onClick={() => onAddNewRow()}
                                />
                            </span>
                        </Col>
                    </Row>
                    <GridContainer
                        reference={ref => dataGridRef.current = ref}
                        height={336}
                        pagerFullScreen={false}
                        columnAutoWidth={true}
                        hoverStateEnabled={true}
                        typePaging={"normal"}
                        keyExpr={"OrderNum"}
                        noDataText={Config.lang("No_data")}
                        style={{ border: 'none' }}
                        loading={formLoading}
                        filterRow={{
                            visible: !isModeAdd,
                            showOperationChooser: false,
                        }}
                        editing={{
                            mode: "cell",
                            refreshMode: "reshape",
                            allowUpdating: !disabled,
                            texts: {
                                confirmDeleteMessage: "",
                            },
                            startEditAction: "click",
                        }}
                        onInitNewRow={(eMethod) => {
                            eMethod.data.OrderNum = countOrderNum.current++;
                            eMethod.data.Content = "";
                            eMethod.data.DepartmentID = "";
                            eMethod.data.DepartmentName = "";
                            eMethod.data.ObjectTypeID = "";
                            eMethod.data.ObjectID = "";
                            eMethod.data.ObjectName = "";
                            eMethod.data.EmailTo = "";
                            eMethod.data.EmailCC = "";
                        }}
                        dataSource={dataDetail} >
                        <Column
                            fixed={true}
                            allowEditing={false}
                            alignment={"right"}
                            fixedPosition={"right"}
                            caption={Config.lang("Hanh_dong")}
                            visible={Config.isMobile && !disabled}
                            cellRender={renderActionGrid}
                        />
                        <Column
                            width={60}
                            allowEditing={false}
                            allowFiltering={false}
                            alignment={"center"}
                            dataField={"OrderNum"}
                            caption={Config.lang("STT")}
                        />
                        <Column
                            width={260}
                            caption={Config.lang("Noi_dung")}
                            headerCellRender={() => (<> {Config.lang("Noi_dung")}
                                <span style={{ color: 'red' }}>&nbsp;*</span> </>)}
                            dataField={"Content"}
                            cellRender={(e) => customCellRender(e, true)}
                        />
                        <Column
                            width={260}
                            allowEditing={false}
                            caption={Config.lang("Bo_phan_tiep_nhan")}
                            dataField={"DepartmentID"}
                            cellRender={(e) => customCellRender(e)}
                            headerCellRender={() =>
                            (<> {Config.lang("Bo_phan_tiep_nhan")}
                                <span style={{ color: 'red' }}>&nbsp;*</span>
                            </>)}
                        />
                        <Column
                            visible={false}
                            allowEditing={false}
                            dataField={"DepartmentName"}
                        />
                        <Column
                            width={260}
                            allowEditing={false}
                            caption={Config.lang("Nguoi_tiep_nhan")}
                            dataField={"ObjectID"}
                            cellRender={(e) => customCellRender(e)}
                        />
                        <Column
                            visible={false}
                            allowEditing={false}
                            dataField={"ObjectName"}
                        />
                        <Column
                            width={240}
                            caption={"To Mail"}
                            dataField={"EmailTo"}
                        />
                        <Column
                            width={240}
                            caption={"CC Mail"}
                            dataField={"EmailCC"}
                        />
                        <Column
                            fixed={true}
                            allowEditing={false}
                            alignment={"right"}
                            fixedPosition={"right"}
                            visible={!Config.isMobile && !disabled}
                            cellRender={renderActionGrid}
                        />
                    </GridContainer>
                </React.Fragment>
            </ModalBody>
            <ModalFooter>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Button
                        disabled={disabled || btnSaveStatus}
                        size={"medium"}
                        color={"info"}
                        startIcon={"Save"}
                        viewType={"filled"}
                        label={Config.lang("Luu")}
                        onClick={onAdd}
                    />
                </div>
            </ModalFooter>
        </Modal >
    );
};

WA3F2031.defaultProps = {
    dataInfo: {},
    openModal: false,
};

WA3F2031.propTypes = {
    openModal: PropTypes.bool,
    dataInfo: PropTypes.object,
    onOpenModalAction: PropTypes.func,
};

export default WA3F2031;
