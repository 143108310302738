/**
 * @copyright 2022 @ DigiNet
 * @author ANHTAI
 * @create 05/05/2022
 * @Example
 */
import { Column } from "devextreme-react/data-grid";
import {
    Button, ButtonIcon, Checkbox, DatePicker, Dropdown, Label, Status, TextInput, Tooltip, Col, Row
} from 'diginet-core-ui/components';
import { makeStyles, useTheme } from "diginet-core-ui/theme";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Config from '../../../../config/index';
import * as generalActions from "../../../../redux/general/general_actions";
import * as WA3F2040Actions from "../../../../redux/modules/WA3/WA3F2040/WA3F2040_actions";
import GridActionBar from "../../../common/grid-container/grid-actionbar";
import GridContainer from "../../../common/grid-container/grid-container";
import ActionToolbar from "../../../common/layouts/toolbar/action-toolbar";
import History from "../../../common/libs/history/history";
import Filter from "../../../filter/filter";
import { browserHistory } from "react-router";

const useStyles = makeStyles({
    ellipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    }
});

const FormID = "WA3F2040";
const defaultDate = 4; // default Tháng này 

const WA3F2040 = () => {
    const DivisionID = Config.getLocalStorage("DIVISIONBEM") || _.get(Config.profile, "DivisionID", "");
    const dispatch = useDispatch();
    const { spacing } = useTheme();
    const classes = useStyles();
    const dataDayByCombo = useMemo(() => {
        return [
            {
                id: 0,
                fieldName: Config.lang("Hom_nay"),
                dateFrom: moment().format("YYYY-MM-DD"),
                dateTo: moment().format("YYYY-MM-DD")
            },
            {
                id: 1,
                fieldName: Config.lang("Hom_qua"),
                dateFrom: moment().subtract(1, "day").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, "day").format("YYYY-MM-DD")
            },
            {
                id: 2,
                fieldName: Config.lang("Tuan_nay"),
                dateFrom: moment().startOf("week").format("YYYY-MM-DD"),
                dateTo: moment().endOf("week").format("YYYY-MM-DD")
            },
            {
                id: 3,
                fieldName: Config.lang("Tuan_truoc"),
                dateFrom: moment().subtract(1, "week").startOf("week").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, "week").endOf("week").format("YYYY-MM-DD")
            },
            {
                id: 4,
                fieldName: Config.lang("Thang_nay"),
                dateFrom: moment().startOf("month").format("YYYY-MM-DD"),
                dateTo: moment().endOf("month").format("YYYY-MM-DD")
            },
            {
                id: 5,
                fieldName: Config.lang("Thang_truoc"),
                dateFrom: moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
            },
            {
                id: 6,
                fieldName: Config.lang("Nam_nay"),
                dateFrom: moment().startOf("year").format("YYYY-MM-DD"),
                dateTo: moment().endOf("year").format("YYYY-MM-DD")
            },
            {
                id: 7,
                fieldName: Config.lang("Nam_truoc"),
                dateFrom: moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, "years").endOf("year").format("YYYY-MM-DD")
            },
            {
                id: 8,
                fieldName: Config.lang("Chon_thoi_gian"),
                dateFrom: moment().format("YYYY-MM-DD"),
                dateTo: moment().format("YYYY-MM-DD")
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [gridLoading, setGridLoading] = useState(false);
    const [cboCreateUserLoading, setCboCreateUserLoading] = useState(false);
    const [cboObjectLoading, setCboObjectLoading] = useState(false);
    const [showChoseDate, setShowChoseDate] = useState(false);
    const [permission, setPermission] = useState(null);
    const [dataCboCustomer, setDataCboCustomer] = useState({
        rows: [],
        total: 0
    });
    const [dataGrid, setDataGrid] = useState({
        total: 0,
        rows: [],
    });
    const [dataCboCreateUser, setDataCboCreateUser] = useState({
        rows: [],
        total: 0
    });
    const [selectedDate, setSelectedDate] = useState(defaultDate);
    const [filterSearch, setFilterSearch] = useState({
        IsStatusID: 0,
        strSearch: "",
        VoucherNo: "",
        VoucherID: "",
        EmployeeID: "",
        ObjectID: "",
        BookingID: "",
        ObjectTypeID: "",
        DateFrom: _.get(dataDayByCombo[defaultDate], "dateFrom", null),
        DateTo: _.get(dataDayByCombo[defaultDate], "dateTo", null),
    });

    //===================================REF=====================================

    const dataGridPages = useRef({
        strSearch: "",
        skip: 0,
        limit: 20,
    });
    const filterCboCreateUsers = useRef({
        skip: 0,
        limit: 20,
        search: ""
    });
    const filterCboCustomer = useRef({
        skip: 0,
        limit: 20,
        search: ""
    });

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (permission) {
            loadGrid();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permission]);

    const loadPermission = () => {
        dispatch(
            generalActions.getPermission(FormID, isPer => {
                setPermission(isPer);
            })
        );
    };

    const loadGrid = () => {
        const { EmployeeID, VoucherNo, VoucherID, ObjectID, IsStatusID, DateFrom, DateTo } = filterSearch;
        const { skip, limit, strSearch } = dataGridPages.current;
        const params = {
            FormID,
            DataType: "LoadWA2040",
            VoucherNo,
            VoucherID,
            DateFrom,
            DateTo,
            ObjectID,
            EmployeeID,
            IsStatusID,
            strSearch,
            skip,
            limit,
        };
        setGridLoading(true);
        dispatch(WA3F2040Actions.loadGrid(params, (error, data) => {
            setGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                setDataGrid({
                    total: 0,
                    rows: [],
                });
                return false;
            } else if (data) {
                const total = _.get(data, "total", []);
                const rows = _.get(data, "rows", []);
                setDataGrid({
                    total,
                    rows
                });
            }
        }));
    };

    const loadCboCustomer = () => {
        const { ObjectID, BookingID } = filterSearch;
        const { skip, limit, search } = filterCboCustomer.current;
        const param = {
            FormID,
            ObjectID,
            BookingID,
            skip,
            limit,
            search
        };
        setCboObjectLoading(true);
        dispatch(generalActions.getCboCustomer(param, (error, data) => {
            setCboObjectLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                let rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                if (!_.isEmpty(rows)) {
                    rows = rows.map(item => {
                        const line = _.isEmpty(item.ObjectID) || _.isEmpty(item.ObjectName) ? "" : "-";
                        return ({ ...item, ObjectCustomName: `${item.ObjectID} ${line} ${item.ObjectName}` })
                    });
                }
                setDataCboCustomer({
                    rows: skip === 0 ? rows : dataCboCustomer?.rows.concat(rows),
                    total
                });
            }
        }));
    };

    const loadCboCreateUser = () => { // CBO người lập
        const { ObjectID, ObjectTypeID, BookingID } = filterSearch;
        const { skip, limit, search } = filterCboCreateUsers.current;
        const param = {
            FormID,
            ObjectID,
            BookingID,
            ObjectTypeID,
            skip,
            limit,
            search
        };
        setCboCreateUserLoading(true);
        dispatch(generalActions.getCboCreateUser(param, (error, data) => {
            setCboCreateUserLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                let rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                if (!_.isEmpty(rows)) {
                    rows = rows.map(item => {
                        const line = _.isEmpty(item.EmployeeID) || _.isEmpty(item.EmployeeName) ? "" : "-";
                        return ({ ...item, EmployeeCustomName: `${item.EmployeeID} ${line} ${item.EmployeeName}` })
                    });
                }
                setDataCboCreateUser({
                    rows: skip === 0 ? rows : dataCboCreateUser?.rows.concat(rows),
                    total
                });
            }
        }));
    };

    const redirectPage = (Mode, screen, currentRowClick = {}) => {
        if (!Mode || _.isEmpty(screen)) return;
        let objInfo = {};
        if (Mode !== "add") {
            objInfo = {
                BatchID: _.get(currentRowClick, "BatchID", ""),
                EventID: _.get(currentRowClick, "EventID", ""),
                VoucherNo: _.get(currentRowClick, "VoucherNo", ""),
                VoucherID: _.get(currentRowClick, "VoucherID", "")
            }
        }
        browserHistory.push({
            pathname: Config.getRootPath() + screen,
            state: {
                screen,
                Mode,
                permission,
                ...objInfo
            }
        });
    };

    const onSaveHistory = async (action = 1, data = {}, dataCompare = {}) => {
        if (_.isEmpty(data) || Config.isEmpty(action, true)) return;
        const TransactionName = Config.lang("Thu_tien_tiec_su_kien");
        const TransID = _.get(data, "VoucherID", "");
        const captions = {
            VoucherNo: "So_phieu",
            StatusName: "Trang_thai",
            ObjectName: "Khach_hang"
        };
        const options = {
            data,
            dataCompare, // Old Data
            captions,
            action,
            ModuleID: "DA3",
            TransactionID: FormID,
            TransID,
            TransactionName,
        };
        const history = new History(options);
        // console.log("===========HIS=========>", history.get());
        return history.get()?.length > 0 ? await history.save() : true;
    };

    const onLoadDataFilter = () => {
        loadCboCreateUser();
        loadCboCustomer();
    };

    const onSearch = (e) => {
        if (dataGridPages.current) {
            dataGridPages.current.strSearch = _.get(e, "target.value", "");
            loadGrid();
        }
    };

    const onChangePage = (page) => {
        dataGridPages.current.skip = page * dataGridPages.current.limit;
        loadGrid();
    };

    const onChangePerPage = (perPage) => {
        dataGridPages.current.skip = 0;
        dataGridPages.current.limit = perPage;
        loadGrid();
    };

    const renderStatusColumn = (e = {}) => {
        const data = _.get(e, "row.data", {});
        if (!e || _.isEmpty(data)) return;
        let color = "";
        let icon = "";
        switch (Number(data["StatusID"])) {
            case 1: // Xanh
                color = "success";
                icon = "Approval";
                break;
            case 100: // Cam
                color = "warning";
                icon = "Delete";
                break;
            default:
                break;
        }
        return <Status icon={icon} color={color} size={"large"} text={data?.StatusName} />
    };

    const onCancel = async (currentRow = {}) => {
        Config.popup.show("YES_NO", Config.lang("Ban_co_muon_huy_phieu_nay_khong"), () => {
            const { VoucherNo = "", VoucherID = "" } = currentRow;
            const params = {
                FormID,
                DivisionID,
                VoucherNo,
                VoucherID,
            };
            dispatch(WA3F2040Actions.onCancel(params, async (error, data) => {
                setGridLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else if (data) {
                    if (data?.Status === 0) {
                        await onSaveHistory(1, { ...currentRow, StatusName: _.get(data, "StatusName", Config.lang("Huy")) }, currentRow);
                        const Message = data.Message || Config.lang("Huy_phieu_thanh_cong");
                        Config.notify.show("success", Message, 2000);
                        loadGrid(); // Load lại lưới
                    } else {
                        Config.popup.show("INFO", _.get(data, "Message", "") || Config.lang("Loi_chua_xac_dinh"));
                    }
                }
            }));
        });
    };

    const renderActionGrid = (e) => {
        const currentRow = _.get(e, "data", {});
        return (
            <GridActionBar>
                <Tooltip title={Config.lang("Huy_phieu")} >
                    <ButtonIcon
                        circular
                        disabled={!(permission >= 2)}
                        name={"CancelFilled"}
                        viewType={"text"}
                        onClick={() => onCancel(currentRow)}
                    />
                </Tooltip>
            </GridActionBar>
        );
    };

    const filterChange = (filterName = "", e) => {
        if (!e || _.isEmpty(filterName)) return;
        const value = _.isObject(e) ? _.get(e, "data.value", _.get(e, "value", _.get(e, "target.value", ""))) : e;
        switch (filterName) {
            // CheckBox
            case "IsStatusID":
                setFilterSearch({ ...filterSearch, [filterName]: value === true ? 1 : 0 });
                break;
            // Chọn ngày
            case "ChoseDate":
                setFilterSearch({
                    ...filterSearch,
                    DateFrom: _.get(dataDayByCombo[value], "dateFrom", null),
                    DateTo: _.get(dataDayByCombo[value], "dateTo", null),
                });
                setSelectedDate(value);
                if (value === 8) {
                    if (showChoseDate === false) setShowChoseDate(true);
                } else {
                    if (showChoseDate === true) setShowChoseDate(false);
                }
                break;
            default: // DD DatePick
                setFilterSearch({ ...filterSearch, [filterName]: value });
                break;
        }
    };

    const renderFilterData = () => {
        return (
            <Filter
                isUseDDCore
                placeholder={Config.lang("Noi_dung_can_tim")}
                onTextChanged={onSearch}
                onOpenLoaded={onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        viewType={"outlined"}
                                        valueExpr={"id"}
                                        keyExpr={"fieldName"}
                                        displayExpr={"fieldName"}
                                        label={Config.lang("Chon_thoi_gian")}
                                        onChange={e => filterChange("ChoseDate", e)}
                                        dataSource={dataDayByCombo}
                                        value={selectedDate}
                                    />
                                </Col>
                                {showChoseDate && <div className={"display_row full_w"}>
                                    <Col xs={12} sm={6} md={6} lg={6} >
                                        <div className={"display_row align-center"}>
                                            <Label style={{ paddingBottom: 15, minWidth: 22 }} className={"mgr5"}>{Config.lang("Tu")}</Label>
                                            <DatePicker
                                                width={"100%"}
                                                viewType={"outlined"}
                                                placeholder={"dd/mm/yyyy"}
                                                displayFormat={"DD/MM/YYYY"}
                                                max={filterSearch?.DateTo}
                                                value={filterSearch?.DateFrom}
                                                onChange={e => filterChange("DateFrom", e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label style={{ paddingBottom: 15, minWidth: 22 }} className={"mgr5"}>{Config.lang("Den")}</Label>
                                            <DatePicker
                                                width={"100%"}
                                                viewType={"outlined"}
                                                placeholder={"dd/mm/yyyy"}
                                                displayFormat={"DD/MM/YYYY"}
                                                min={filterSearch?.DateFrom}
                                                value={filterSearch?.DateTo}
                                                onChange={e => filterChange("DateTo", e)}
                                            />
                                        </div>
                                    </Col>
                                </div>}
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <TextInput
                                        viewType={"outlined"}
                                        label={Config.lang("So_phieu")}
                                        onChange={(e) => filterChange("VoucherNo", e)}
                                        value={filterSearch?.VoucherNo}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        clearAble
                                        allowSearch
                                        searchDelayTime={700}
                                        dataSource={_.get(dataCboCustomer, "rows", [])}
                                        total={_.get(dataCboCustomer, "total", 0)}
                                        skip={filterCboCustomer.current.skip}
                                        limit={filterCboCustomer.current.limit}
                                        value={filterSearch?.ObjectID}
                                        loading={cboObjectLoading}
                                        viewType={"outlined"}
                                        valueExpr={"ObjectID"}
                                        label={Config.lang("Khach_hang")}
                                        displayExpr={"ObjectCustomName"}
                                        onChange={(e) => filterChange("ObjectID", e)}
                                        onLoadMore={(e) => {
                                            filterCboCustomer.current.skip = e.skip;
                                            filterCboCustomer.current.limit = e.limit;
                                            loadCboCustomer();
                                        }}
                                        onInput={(e) => {
                                            const value = e?.target?.value || "";
                                            filterCboCustomer.current.search = value;
                                            filterCboCustomer.current.skip = 0;
                                            loadCboCustomer();
                                        }}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        clearAble
                                        allowSearch
                                        searchDelayTime={700}
                                        dataSource={_.get(dataCboCreateUser, "rows", [])}
                                        total={_.get(dataCboCreateUser, "total", 0)}
                                        skip={filterCboCreateUsers.current.skip}
                                        limit={filterCboCreateUsers.current.limit}
                                        value={filterSearch?.EmployeeID}
                                        loading={cboCreateUserLoading}
                                        viewType={"outlined"}
                                        valueExpr={"EmployeeID"}
                                        label={Config.lang("Nguoi_lap")}
                                        displayExpr={"EmployeeCustomName"}
                                        onChange={(e) => filterChange("EmployeeID", e)}
                                        onLoadMore={(e) => {
                                            filterCboCreateUsers.current.skip = e.skip;
                                            filterCboCreateUsers.current.limit = e.limit;
                                            loadCboCreateUser();
                                        }}
                                        onInput={(e) => {
                                            const value = e?.target?.value || "";
                                            filterCboCreateUsers.current.search = value;
                                            filterCboCreateUsers.current.skip = 0;
                                            loadCboCreateUser();
                                        }}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Checkbox
                                        style={{ marginBottom: spacing(5) }}
                                        label={Config.lang("Hien_thi_phieu_da_huy")}
                                        onChange={(e) => filterChange("IsStatusID", e)}
                                        checked={filterSearch?.IsStatusID === 1} />
                                </Col>
                            </Row>
                            <div className={"display_row valign-middle"}>
                                <Button
                                    size={"medium"}
                                    color={"primary"}
                                    viewType={"outlined"}
                                    label={Config.lang("Tim_kiem")}
                                    startIcon={"Search"}
                                    onClick={loadGrid}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    const renderMaxWidthColum = (e, maxWidth = 260) => {
        const value = _.get(e, "value", "");
        return <div title={value} className={classes.ellipsis}
            {...(_.isNumber(maxWidth) ? { style: { maxWidth } } : {})} >{value}</div>
    };

    const renderDisabledColumn = (e) => {
        return <Checkbox
            readOnly
            style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }}
            checked={_.get(e, "data.Disabled", 0) === 1}
        />;
    };

    const renderGrid = useMemo(() => {
        return <GridContainer
            keyExpr={"OrderNum"}
            style={{ border: 'none' }}
            typePaging={"remote"}
            noDataText={Config.lang("No_data")}
            filterRow={{
                visible: true,
                showOperationChooser: false,
            }}
            loading={gridLoading}
            totalItems={_.get(dataGrid, "total", 0)}
            dataSource={_.get(dataGrid, "rows", [])}
            itemPerPage={dataGridPages.current.limit}
            skipPerPage={dataGridPages.current.skip}
            onRowDblClick={(e) => {
                if (e?.data) redirectPage("view", "WA3F2041", e.data);
            }}
            onChangePage={onChangePage}
            onChangePerPage={onChangePerPage}>
            <Column
                width={40}
                fixed={true}
                alignment={"right"}
                fixedPosition={"right"}
                caption={Config.lang("Hanh_dong")}
                visible={Config.isMobile}
                cellRender={renderActionGrid}
            />
            <Column
                width={60}
                allowFiltering={false}
                dataField={"OrderNum"}
                alignment={"center"}
                caption={Config.lang("STT")}
            />
            <Column
                width={180}
                dataField={"VoucherNo"}
                caption={Config.lang("So_phieu")}
            />
            <Column
                width={110}
                dataType={"date"}
                alignment={"right"}
                format={"dd/MM/yyyy"}
                dataField={"VoucherDate"}
                caption={Config.lang("Ngay_phieu")}
            />
            <Column
                dataField={"Description"}
                caption={Config.lang("Dien_giai")}
                cellRender={(e) => renderMaxWidthColum(e, 260)}
            />
            <Column
                width={180}
                dataField={"StatusName"}
                caption={Config.lang("Trang_thai")}
                cellRender={renderStatusColumn}
            />
            <Column
                width={160}
                dataField={"ObjectName"}
                caption={Config.lang("Khach_hang")}
            />
            <Column
                width={160}
                dataField={"EventID"}
                caption={Config.lang("Ma_su_kien")}
            />
            <Column
                width={160}
                dataField={"ContractNo"}
                caption={Config.lang("So_hop_dong")}
            />
            <Column
                width={110}
                dataType={"date"}
                alignment={"right"}
                format={"dd/MM/yyyy"}
                dataField={"EventDate"}
                caption={Config.lang("Ngay_su_kien")}
            />
            <Column
                width={160}
                dataField={"EmployeeName"}
                caption={Config.lang("Nguoi_lap")}
            />
            <Column
                width={100}
                dataType={"boolean"}
                dataField={"IsCollected"}
                caption={Config.lang("Da_thu")}
                trueText={Config.lang("Co")}
                falseText={Config.lang("Khong")}
                cellRender={renderDisabledColumn}
                calculateCellValue={rowData => !!rowData.IsCollected}
            />
            <Column
                width={40}
                fixed={true}
                alignment={"right"}
                fixedPosition={"right"}
                visible={!Config.isMobile}
                cellRender={renderActionGrid}
            />
        </GridContainer>;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, gridLoading, dataGridPages.current]);

    if (!permission) return null;
    return (
        <React.Fragment>
            <ActionToolbar title={Config.lang("Danh_sach_phieu_tinh_tien_tiec")}>
                <Button
                    disabled={!(permission >= 2)}
                    size={"medium"}
                    color={"primary"}
                    viewType={"filled"}
                    label={Config.lang("Them")}
                    startIcon={"AddCircle"}
                    onClick={() => redirectPage("add", "WA3F2041")}
                />
            </ActionToolbar>
            <div className={"hidden"}>{renderFilterData()}</div>
            {renderGrid}
        </React.Fragment>
    );
};

export default WA3F2040;